//import Visualization from "@utils/visualization";
import Visualization from "../../../../utils/visualization";
import * as PIXI from "pixi.js";
import {Viewport} from "pixi-viewport";
import * as d3 from "d3";
import _ from "lodash";
import {FilterType} from "@store/filter/FilterStatus";
import {FilterAction, FilterField} from "@store/filter/types";
import ClusterRing from "./ClusterRing";
import NodeArray from "./NodeArray";
import LinkArray from "./LinkArray";

// New ForceGraph Class based on PIXI.js
export default class ForceGraph extends Visualization {
  constructor(node, parentProps, data, options, callContextMenu) {
    super(node, parentProps, data, options);
    this.callContextMenu = callContextMenu;
  }

  // Returns default options
  getDefaultOptions() {
    return {
      padding: {top: 0, right: 0, bottom: 0, left: 0}
    };
  }

  // Initialize all necessary variables
  init() {
    this.theme = this.parentProps.theme;
    this.colors = {
      in: this.theme.barColors.incoming,
      out: this.theme.barColors.outgoing,
      intern: this.theme.barColors.internal
    };
    const {padding} = this.options;
    this.width = this.width - padding.left - padding.right;
    this.height = this.height - padding.bottom - padding.top;
    this.FORCE_LAYOUT_NODE_REPULSION_STRENGTH = 350;
    this.FORCE_LAYOUT_ITERATIONS = 100;
    this.linkSizeFactor = 1;

    this.clusterInternal = true;
    this.internalCount = -1;
    this.preventInstantUpdate = false;

    this.app = new PIXI.Application({
      width: this.width,
      height: this.height,
      resolution: window.devicePixelRatio || 1,
      transparent: true,
      //backgroundColor: 0xFFFFFF,
      resizeTo: this.node,
      antialias: true,
      autoStart: true //TODO ->false disable automatic rendering by ticker, render manually instead, only when needed
    });

    this.node.appendChild(this.app.view);

    this.viewport = new Viewport({
      screenWidth: this.width,
      screenHeight: this.height,
      worldWidth: this.width,
      worldHeight: this.height,
      interaction: this.app.renderer.plugins.interaction
    });

    this.app.renderer.plugins.interaction.autoPreventDefault = true;

    this.app.stage.addChild(this.viewport);

    this.viewport.drag().pinch().wheel().decelerate();

    this.viewport.on("frame-end", () => {
      if (this.viewport.dirty) {
        this.requestRender();
        this.viewport.dirty = false;
      }
    });

    // prevent body scrolling
    this.app.view.addEventListener("wheel", (event) => {
      event.preventDefault();
    });

    // prevent browser context menu on canvas
    this.app.view.addEventListener("contextmenu", (event) => {
      event.preventDefault();
    });

    // Add graph layers in correct order
    this.linkLayer = new PIXI.Container();
    this.viewport.addChild(this.linkLayer);
    this.clusterRing = new ClusterRing(this);
    this.viewport.addChild(this.clusterRing);
    this.nodeLayer = new PIXI.Container();
    this.viewport.addChild(this.nodeLayer);
    this.labelLayer = new PIXI.Container();
    this.viewport.addChild(this.labelLayer);
  }

  // Calculate Layout and Graph Positions
  forceLayout() {
    console.log("CALCULATING FORCE LAYOUT")
    //Keeps external nodes out of cluster
    const clusterConstraint = () => {
      this.clusterRing.update();

      if (this.clusterInternal && !this.nodes.filter((elem) => elem.internal).length <= 0)
        this.nodes.forEach((elem) => {
          if (!elem.internal) {
            const outDirection = {
              x: elem.x - this.clusterRing.center.x,
              y: elem.y - this.clusterRing.center.y
            };
            const magnitude = ({x, y}) => Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
            if (
              magnitude(outDirection) < this.clusterRing.radius * 1.05 &&
              magnitude(outDirection) !== 0
            ) {
              const normalizedOutdirection = {
                x: outDirection.x / magnitude(outDirection),
                y: outDirection.y / magnitude(outDirection)
              };
              const outPoint = {
                x:
                  this.clusterRing.center.x +
                  normalizedOutdirection.x * this.clusterRing.radius * 1.05,
                y:
                  this.clusterRing.center.y +
                  normalizedOutdirection.y * this.clusterRing.radius * 1.05
              };
              elem.setPosition(outPoint.x, outPoint.y);
              elem.vx = 0;
              elem.vy = 0;
            }
          }
        });
    };

    d3.forceSimulation(this.nodes)
      .force("charge", d3.forceManyBody().strength(-this.FORCE_LAYOUT_NODE_REPULSION_STRENGTH))
      .force(
        "x",
        d3
          .forceX()
          .x(this.width / 2)
          .strength(0.2)
      )
      .force("y", d3.forceY(this.height / 2).strength(0.2))
      .force(
        "link",
        d3
          .forceLink(this.links)
          .id((linkData) => linkData.value)
          .distance(20)
          .strength(0.2)
      )
      .force("clusterConstraint", clusterConstraint)
      .stop()
      .tick(this.FORCE_LAYOUT_ITERATIONS);

    clusterConstraint();

    this.nodes.updateLabelPositions();
  }

  // Sets DnsMap and updates labels on graph
  updateDnsMap(dnsMap) {
    this.dnsMap = dnsMap;
    if (this.nodes) this.nodes.forEach((node) => node.label.updateLabelText());
  }

  // Updates dimensions of the view
  updateDimensions() {
    this.width = this.node.clientWidth;
    this.height = this.node.clientHeight;
    this.app.resize();
    this.viewport.resize(this.width, this.height, this.width, this.height);
  }

  // Updates theme according to global theme, results in rerender
  updateTheme(theme) {
    this.theme = theme;
    if (this.nodes) this.nodes.forEach((node) => node.redraw());
  }

  // adds Filter for Source (called over context menu)
  filterSrc(type, mode, d) {
    let filter =
      type === FilterType.include ? this.filterState.includeFilter : this.filterState.excludeFilter;
    let inv =
      type === FilterType.exclude ? this.filterState.includeFilter : this.filterState.excludeFilter;
    if (mode === "ip") {
      this.setFilter(type, FilterField.srcIP, filter.srcIP, d.value, inv.srcIP);
    } else {
      this.setFilter(type, FilterField.srcPort, filter.srcPort, d.value, inv.srcPort);
    }
  }

  // Propagates filters to parent
  setFilter(type, field, lst, payload, invLst) {
    let invType = type === FilterType.include ? FilterType.exclude : FilterType.include;
    if (lst.includes(payload)) {
      this.parentProps.updateFilter(type, FilterAction.remove, field, payload);
    } else {
      this.parentProps.updateFilter(type, FilterAction.add, field, payload);
    }

    if (invLst.includes(payload)) {
      this.parentProps.updateFilter(invType, FilterAction.remove, field, payload);
    }
  }

  // adds Filter for Destination (called over context menu)
  filterDest(type, mode, d) {
    let filter =
      type === FilterType.include ? this.filterState.includeFilter : this.filterState.excludeFilter;
    let inv =
      type === FilterType.exclude ? this.filterState.includeFilter : this.filterState.excludeFilter;
    if (mode === "ip") {
      this.setFilter(type, FilterField.destIP, filter.destIP, d.value, inv.destIP);
    } else {
      this.setFilter(type, FilterField.destPort, filter.destPort, d.value, inv.destPort);
    }
  }

  // updates GraphData, FilterState, mode etc... and usually results in a rerender of the graph
  updateData(data, filterState, mode, savedPositions, progress, networkExpansionLevel) {
    if (!data) {
      return;
    }
    if (this.preventInstantUpdate) {
      this.preventInstantUpdate = false;
      return;
    }

    // 1. Check if graphdata is complete or still in uploading process
    const complete = progress === 100 || (progress === -1 && data.nodes.length !== 0);
    this.data = data;
    this.mode = mode;
    this.filterState = filterState;
    this.networkExpansionLevel = networkExpansionLevel;

    // 2. load saved Positions
    if (!savedPositions || savedPositions === "") {
      this.savedPositions = undefined;
    } else {
      this.savedPositions = JSON.parse(savedPositions);
    }

    // 3. Check for changed Internal IPs
    if (
      this.mode === "ip" &&
      this.internalCount !== -1 &&
      data.nodes &&
      this.internalCount !== data.nodes.filter((elem) => elem.internal).length
    ) {
      this.invalidatePositions();
    }
    if (this.mode === "ip") this.internalCount = data.nodes.filter((elem) => elem.internal).length;

    // 4. Calculate Extent
    [this.minConnectivity, this.maxConnectivity] = d3.extent(data.nodes.map((x) => x.connectivity));
    if (this.minConnectivity === this.maxConnectivity) {
      this.minConnectivity = 0;
    }
    [this.minVolume, this.maxVolume] = d3.extent(data.links.map((x) => x.volume));
    if (this.minVolume === this.maxVolume) {
      this.minVolume = 0;
    }

    // 5.
    if (this.nodes) this.nodes.clearAllNodes();
    this.nodes = new NodeArray(this);
    this.nodes.initNodesFromData(data.nodes);

    if (this.links) this.links.clearAllLinks();
    this.links = new LinkArray(this, data.links);
    this.links.initLinksFromData(data.links);

    // 6. Layout if necessary otherwise load from saved positions
    if (
      !complete ||
      !this.savedPositions ||
      !this.savedPositions[mode] ||
      this.savedPositions[mode].length < data.nodes.length
    ) {
      this.forceLayout();

      if (complete) {
        this.savePositions();
      }
    } else {
      this.loadPositions(); //Loads positions into nodes
    }

    this.updateSelection();
  }

  // Saves Graph Positions and Stores in backend (& Propagates to shared viewers)
  savePositions() {
    console.log("SAVING POSITIONS");
    if (!this.savedPositions) this.savedPositions = {};
    this.savedPositions[this.mode] = this.nodes.map((elem) => {
      return {
        value: elem.value,
        x: elem.x,
        y: elem.y
      };
    });
    if (this.parentProps.user.fileId !== 1) {
      // Don't send to backend if demo
      this.parentProps.editGraphPositions(
        this.parentProps.username,
        this.parentProps.fileId,
        JSON.stringify(this.savedPositions),
        this.parentProps.sessionId
      );
    }
    this.preventInstantUpdate = true;
    this.parentProps.setGraphPositionsLocal(JSON.stringify(this.savedPositions));
  }

  // Load graph positions from saved Positions
  loadPositions() {
    console.log("LOADING FROM POSITIONS");
    this.nodes.forEach((node) => {
      try {
        const foundPosition = this.savedPositions[this.mode].find((sp) => node.value === sp.value);
        node.setPosition(foundPosition.x, foundPosition.y);
      } catch (error) {
        console.error("Could not load saved Position for " + node.value);
      }
    });
  }

  // Invalidates the saved Positions
  invalidatePositions() {
    console.log("POSITIONS INVALIDATED");
    this.savedPositions = undefined;
  }

  // Updates Graph according to Node Reduction step
  updateNodeReduction() {
    const thresholds = [5, 15, 30, 60, 100];
    const getPercent = (array, percent) => {
      return array.slice(0, Math.ceil((array.length * percent) / 100));
    };
    const conn = [...this.nodes].sort((a, b) => b.connectivity - a.connectivity);
    const deg = [...this.nodes].sort((a, b) => b.getDegree() - a.getDegree());

    const subset = _.unionBy(
      getPercent(deg, thresholds[this.networkExpansionLevel - 1]),
      getPercent(conn, thresholds[this.networkExpansionLevel - 1]),
      "value"
    );

    this.nodes.forEach((node) => {
      node.visible =
        !!_.find(subset, {value: node.value}) || node.checkForSelection(this.filterState);
      node.label.visible =
        !!_.find(subset, {value: node.value}) || node.checkForSelection(this.filterState);
    });
  }

  // Update Graph based on selection
  updateSelection(filterState = this.filterState) {
    this.filterState = filterState;

    if (this.nodes && this.links && this.clusterRing) {
      this.nodes.forEach((node) => {
        node.checkForSelection();
      });

      this.updateNodeReduction();
      this.links.redrawLinks();
      this.clusterRing.update();
    }
  }

  // Rerenders graph
  requestRender() {
    this.app.render();
  }

  // Resets View of the graph and recenters
  resetView() {
    this.viewport.center = new PIXI.Point(this.width / 2, this.height / 2);
    this.viewport.setZoom(1, true);
  }
}
