import * as PIXI from "pixi.js";

// Visual link between two nodes
export default class Link extends PIXI.Graphics {
  constructor(graph, linkData) {
    super();

    this.graph = graph;

    this.inOut = linkData.inOut;
    this.source = graph.nodes.find((elem) => elem.value === linkData.source);
    this.target = graph.nodes.find((elem) => elem.value === linkData.target);
    this.volume = linkData.volume;
  }

  // Redraws the link
  draw() {
    this.clear();

    this.alpha =
      this.graph.nodes.getNode(this.source.value).alpha === 1 &&
      this.graph.nodes.getNode(this.target.value).alpha === 1
        ? 0.6
        : 0.1;
    this.lineStyle(this.calcWidth() * 2, this.getColor());
    this.moveTo(this.source.x, this.source.y);
    this.lineTo(this.target.x, this.target.y);
    this.endFill();
    this.visible =
      this.graph.nodes.getNode(this.source.value).visible &&
      this.graph.nodes.getNode(this.target.value).visible;
  }

  // Returns the correct link color based on whether the link is incoming/outgoing/internal traffic
  getColor() {
    switch (this.inOut) {
      case 0:
        return parseInt(this.graph.colors.out.slice(1), 16);
      case 1:
        return parseInt(this.graph.colors.in.slice(1), 16);
      case 2:
        return parseInt(this.graph.colors.intern.slice(1), 16);
      default:
        return 0x999999;
    }
  }

  // Returns the correct link width based on its volume
  calcWidth() {
    const possibleMax =
      1.5 +
      8.5 *
        ((this.volume - this.graph.minVolume) / (this.graph.maxVolume - this.graph.minVolume)) *
        this.graph.linkSizeFactor;

    const width = Math.min(
      this.graph.nodes.getNode(this.source.value).calcNodeRadius(),
      this.graph.nodes.getNode(this.target.value).calcNodeRadius(),
      possibleMax
    );

    if (width < possibleMax) {
      this.graph.linkSizeFactor = width / possibleMax;
    }

    //const maxNode = Math.min(this.graph.nodes.getNode(this.source.value))

    return width;
  }
}
