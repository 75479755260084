import React, { Component, Ref, RefObject } from "react";

import { connect } from "react-redux";
import { TimeLineChart } from "./TimeLineChart";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core";
import { ApplicationState } from "@store/index";
import { returnType } from "@utils/TypeUtils";
import * as filterActions from "@store/filter/actions";
import { FilterType } from "@store/filter/FilterStatus";
import { FilterAction, FilterField } from "@store/filter/types";

// Definition of injected styles
const styles = (theme: Theme) => createStyles({});

// Component-specific props.
interface ComponentProps {
  theme: Theme; // has to be defined here when using redux connect and forwardRef because https://github.com/mui-org/material-ui/issues/8958#issuecomment-410231896
}

// Redux: Map redux store state to component props
const mapStateToProps = (state: ApplicationState) => ({
  timelineContextData: state.websocket.timelineContextData,
  timelineFocusData: state.websocket.timelineFocusData,
  timelineContextBucketSize: state.websocket.timelineContextBucketSize,
  timelineFocusBucketSize: state.websocket.timelineFocusBucketSize,
  focusStartTime: state.filter.includeFilter.startTime,
  focusEndTime: state.filter.includeFilter.endTime,
  fileId: state.user.fileId,
  filterState: state.filter,
  user: state.user
});

// Redux: Map action dispatchers to props
const mapDispatchToProps = (dispatch: any) => ({
  updateFilter: (filterType: FilterType, act: FilterAction, field: FilterField, payload: string) =>
    dispatch(filterActions.updateFilter(filterType, act, field, payload))
});

// Props passed from mapStateToProps
const reduxProps = returnType(mapStateToProps);
type PropsFromReduxState = typeof reduxProps;

// Props passed from mapDispatchToProps
const reduxActions = returnType(mapDispatchToProps);
type PropsFromReduxDispatch = typeof reduxActions;

type CombinedProps = PropsFromReduxState &
  PropsFromReduxDispatch &
  ComponentProps &
  WithStyles<typeof styles>;

class TimeLineComponent extends Component<CombinedProps> {
  public _chart: any;
  public myRef: RefObject<HTMLDivElement>;

  constructor(props: CombinedProps) {
    super(props);
    this.myRef = React.createRef();
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  updateDimensions() {
    this._chart.updateDimensions(this.myRef.current);
  }

  componentWillUnmount(): void {
    window.removeEventListener("resize", this.updateDimensions);
  }

  componentDidMount() {
    this._chart = new TimeLineChart(this.myRef.current, this.props);
    if (this.props.timelineContextData.length > 0) {
      this._chart.updateContextData(
        this.props.timelineContextData,
        this.props.timelineContextBucketSize,
        this.props.filterState
      );
      this._chart.updateBrush(this.props.filterState);
    }
    if (this.props.timelineFocusData.length > 0) {
      this._chart.updateFocusData(
        this.props.timelineFocusData,
        this.props.timelineFocusBucketSize,
        this.props.filterState
      );
      this._chart.updateBrushInterior(
        this.props.timelineFocusData,
        this.props.timelineFocusBucketSize
      );
    }
    window.addEventListener("resize", this.updateDimensions);
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.timelineContextData != this.props.timelineContextData) {
      this._chart.updateContextData(
        this.props.timelineContextData,
        this.props.timelineContextBucketSize,
        this.props.filterState
      );
      this._chart.updateBrush(this.props.filterState);
    }
    if (this.props.filterState != prevProps.filterState) {
      this._chart.updateBrush(this.props.filterState);
    }
    if (prevProps.timelineFocusData != this.props.timelineFocusData) {
      this._chart.updateFocusData(
        this.props.timelineFocusData,
        this.props.timelineFocusBucketSize,
        this.props.filterState
      );
      this._chart.updateBrushInterior(
        this.props.timelineFocusData,
        this.props.timelineFocusBucketSize
      );
    }
    if (this.props.theme != prevProps.theme) {
      this._chart.updateTheme(this.props.theme);
    }
    if (prevProps.user.showVolumeTimelineVis !== this.props.user.showVolumeTimelineVis) {
      this._chart.updateAxis(this.props.user.showVolumeTimelineVis);
      this.updateDimensions();
    }

    this._chart.up;
  }

  resetZoom() {
    this._chart.resetZoom();
  }

  render() {
    return <div style={{ height: "100%" }} ref={this.myRef} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  withStyles(styles, { withTheme: true })(TimeLineComponent)
);
