import React from "react";
import { createStyles, Theme, WithStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { bytes2String } from "@utils/HelperFunctions";
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import SettingsEthernetIcon from "@material-ui/icons/SettingsEthernet";

import copy from "copy-to-clipboard";

interface Data {
  id: number;
  time: string;
  srcIP: string;
  srcPort: number;
  destIP: string;
  destPort: number;
  protocol: string;
  volume: number;
  inOut: string;
  info: string;
}

interface ListDataEthernet {
  destMacLength: number;
  destMacStart: number;
  destMacValue: string;
  headerLength: number;
  headerLengthStart: number;
  headerLengthValue: string;
  headerTypeLength: number;
  headerTypeStart: number;
  headerTypeValue: string;
  srcMacLength: number;
  srcMacStart: number;
  srcMacValue: string;
}

function desc<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

type Order = "asc" | "desc";

function getSorting<K extends keyof any>(
  order: Order,
  orderBy: K
): (a: { [key in K]: number | string }, b: { [key in K]: number | string }) => number {
  return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

interface HeadRow {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headRows: HeadRow[] = [
  { id: "time", numeric: true, disablePadding: false, label: "Time" },
  { id: "srcIP", numeric: false, disablePadding: false, label: "Source IP" },
  { id: "srcPort", numeric: true, disablePadding: false, label: "Source Port" },
  { id: "destIP", numeric: false, disablePadding: false, label: "Destination IP" },
  { id: "destPort", numeric: true, disablePadding: false, label: "Destination Port" },
  { id: "protocol", numeric: false, disablePadding: false, label: "Protocol" },
  { id: "volume", numeric: true, disablePadding: false, label: "Payload in Bytes" },
  { id: "inOut", numeric: false, disablePadding: false, label: "In / Out / Internal" },
  { id: "info", numeric: false, disablePadding: false, label: "Info" }
];

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row) => (
          <TableCell
            key={row.id}
            align={"left"}
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
              className={row.id}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
      // marginTop: theme.spacing(3),
    },
    paper: {
      width: "100%"
      // marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 200,
      columnFill: "auto"
    },
    tableWrapper: {
      overflowX: "auto"
    },
    subListItem: {
      width: "100%",
      cursor: "pointer"
    },
    subListItemText: {
      cursor: "text",
      marginLeft: "4.5%",
      fontSize: 14
    }
  });

const initialState = {
  mouseX: null,
  mouseY: null
};

interface TableProps {
  data: any;
  fetching: boolean;
  requestPacketDetails: any;
  packetDetails: Map<number, any>;
  parent: any;
}

type CombinedProps = TableProps & WithStyles<typeof styles>;

function EnhancedTable(props: CombinedProps) {
  const { classes, data, fetching, packetDetails, requestPacketDetails } = props;
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("time");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [openFrames, setOpenFrames] = React.useState(false);
  const [openEthernet, setOpenEthernet] = React.useState(false);
  const [openIpv, setOpenIpv] = React.useState(false);
  const [openUdp, setOpenUdp] = React.useState(false);
  const [openDns, setOpenDns] = React.useState(false);
  const [openTcp, setOpenTcp] = React.useState(false);
  const [openArp, setOpenArp] = React.useState(false);
  const [openNss, setOpenNss] = React.useState(false);
  const [openRtp, setOpenRtp] = React.useState(false);
  const [openSmb2, setOpenSmb2] = React.useState(false);
  const [openHttp, setOpenHttp] = React.useState(false);
  const [openTls, setOpenTls] = React.useState(false);
  const [openTlsClientHello, setOpenTlsClientHello] = React.useState(false);
  const [openTlsServerHello, setOpenTlsServerHello] = React.useState(false);
  const [openTlsCertificate, setOpenTlsCertificate] = React.useState(false);
  const [openTlsServerKeyExchange, setOpenTlsServerKeyExchange] = React.useState(false);
  const [openTlsServerHelloDone, setOpenTlsServerHelloDone] = React.useState(false);
  const [openTlsClientKeyExchange, setOpenTlsClientKeyExchange] = React.useState(false);
  const [openTlsChangeCipherSpec, setOpenTlsChangeCipherSpec] = React.useState(false);
  const [openTlsApplicationData, setOpenTlsApplicationData] = React.useState(false);
  const [openTlsIllegalData, setOpenTlsIllegalData] = React.useState(false);

  const [openBgp, setOpenBgp] = React.useState(false);
  const [openNlri, setOpenNlri] = React.useState(false);
  const [openPathAttributes, setOpenPathAttributes] = React.useState(false);
  const [openSyslog, setOpenSyslog] = React.useState(false);
  const [openFtp, setOpenFtp] = React.useState(false);
  //const [listItems, setListItems] = React.useState<any>([]);
  const [renderPayload, setRenderPayload] = React.useState(false);
  const [renderedId, setRenderedId] = React.useState(-1);
  const [hexRows, setHexRows] = React.useState([]);
  const [highlightStart, setHighlightStart] = React.useState(-1);
  const [highlightEnd, setHighlightEnd] = React.useState(-1);
  const [chosenPacket, setChosenPacket] = React.useState<any>([]);
  const [plainTextRows, setPlainTextRows] = React.useState([]);
  const [dontFetch, setDontFetch] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [followRow, setFollowRow] = React.useState(null);
  const [mouseCoordinates, setMouseoCordinates] = React.useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>(initialState);

  let indexForHexHighlight = 0;
  let indexForHexHighlight2 = 0;
  let indexForPlainTextHighlight = 0;
  let indexForPlainTextHighlight2 = 0;
  const hexLettersPerRow = 32;
  const plainTextLettersPerRow = hexLettersPerRow / 2;

  function createData(
    id: number,
    time: string,
    srcIP: string,
    srcPort: number,
    destIP: string,
    destPort: number,
    protocol: string,
    volume: number,
    inOut: string,
    info: string
  ): Data {
    return { id, time, srcIP, srcPort, destIP, destPort, protocol, volume, inOut, info };
  }

  const rows: any = [];
  data.forEach((e: any) => {
    rows.push(
      createData(
        e.packetId,
        new Date(e.time).toLocaleDateString() + " " + new Date(e.time).toLocaleTimeString(),
        e.srcIP,
        e.srcPort,
        e.destIP,
        e.destPort,
        e.protocol,
        e.volume,
        getInOut(e.inOut),
        e.info
      )
    );
  });

  function getInOut(n: number) {
    if (n === 0) {
      return "Outgoing";
    } else {
      return n === 1 ? "Incoming" : "Internal";
    }
  }

  function handleMenu(e: any, row: any) {
    e.preventDefault();
    setMouseoCordinates({
      mouseX: e.clientX - 2,
      mouseY: e.clientY - 4
    });
    setFollowRow(row);
  }

  function closeMenu() {
    setMouseoCordinates(initialState);
  }

  function handleRequestSort(event: React.MouseEvent<unknown>, property: keyof Data) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleChangePage(event: unknown, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(+event.target.value);
  }

  function handleListItemClickFrames() {
    setOpenFrames(!openFrames);
  }

  function handleListItemClickEthernet() {
    setOpenEthernet(!openEthernet);
  }

  function handleListItemClickIpv() {
    setOpenIpv(!openIpv);
  }

  function handleListItemClickUdp() {
    setOpenUdp(!openUdp);
  }

  function handleListItemClickDns() {
    setOpenDns(!openDns);
  }

  function handleListItemClickTcp() {
    setOpenTcp(!openTcp);
  }

  function handleListItemClickArp() {
    setOpenArp(!openArp);
  }

  function handleListItemClickRtp() {
    setOpenRtp(!openRtp);
  }
  function handleListItemClickNss() {
    setOpenNss(!openNss);
  }
  function handleListItemClickSmb2() {
    setOpenSmb2(!openSmb2);
  }
  function handleListItemClickHttp() {
    setOpenHttp(!openHttp);
  }
  function handleListItemClickTls() {
    setOpenTls(!openTls);
  }

  function handleListItemClickTlsClientHello() {
    setOpenTlsClientHello(!openTlsClientHello);
  }
  function handleListItemClickTlsServerHello() {
    setOpenTlsServerHello(!openTlsServerHello);
  }
  function handleListItemClickTlsCertificate() {
    setOpenTlsCertificate(!openTlsCertificate);
  }
  function handleListItemClickTlsServerKeyExchange() {
    setOpenTlsServerKeyExchange(!openTlsServerKeyExchange);
  }
  function handleListItemClickTlsServerHelloDone() {
    setOpenTlsServerHelloDone(!openTlsServerHelloDone);
  }
  function handleListItemClickTlsClientKeyExchange() {
    setOpenTlsClientKeyExchange(!openTlsClientKeyExchange);
  }

  function handleListItemClickTlsChangeCipherSpec() {
    setOpenTlsChangeCipherSpec(!openTlsChangeCipherSpec);
  }

  function handleListItemClickTlsApplicationData() {
    setOpenTlsApplicationData(!openTlsApplicationData);
  }

  function handleListItemClickTlsIllegalData() {
    setOpenTlsIllegalData(!openTlsIllegalData);
  }

  function handleListItemClickBgp(){
    setOpenBgp(!openBgp);
  }

  function handleListItemClickNlri(){
    setOpenNlri(!openNlri);
  }

  function handleListItemClickPathAttributes(){
    setOpenPathAttributes(!openPathAttributes);
  }

  function handleListItemClickSyslog(){
    setOpenSyslog(!openSyslog);
  }

  function handleListItemClickFtp() {
    setOpenFtp(!openFtp);
  }

  function setInnerHighlight(startPosition: number, endPosition: number, entryValue: string) {
    let i = 0;
    if (startPosition !== 0) {
      i = 1;
    }
    setHighlightStart(startPosition + i);
    setHighlightEnd(endPosition);
    copyToClip(entryValue);
  }

  function setHighlight(startPosition: number, endPosition: number) {
    let i = 0;
    if (startPosition !== 0) {
      i = 1;
    }
    setHighlightStart(startPosition + i);
    setHighlightEnd(endPosition);
  }

  function copyToClip(information: string) {
    copy(information.split(": ")[1]);
  }

  function createListItem(entryValue: string, startPosition: number, length: number) {
    return (
      <ListItem
        dense={true}
        className={classes.subListItem}
        onClick={() => setInnerHighlight(startPosition, startPosition + length, entryValue)}
      >
        <Typography className={classes.subListItemText}>{entryValue}</Typography>
      </ListItem>
    );
  }

  function createEthernetHeader(header: any, headerLength: string) {
    let srcMac = header.srcMac;
    let dstMac = header.destMac;
    let headerType = header.headerType;
    return (
      <div>
        <ListItem
          button
          dense={true}
          onClick={handleListItemClickEthernet}
          onFocus={() => setHighlight(headerType.start, headerType.start + headerType.length)}
        >
          <ListItemIcon>
            <SettingsEthernetIcon />
          </ListItemIcon>
          <ListItemText primary={"Ethernet Header, " + headerLength + " bytes"} />
          {openEthernet ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Divider variant="middle" />
        <Collapse in={openEthernet} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {createListItem("Source Mac:  " + srcMac.value, srcMac.start, srcMac.length)}
            <Divider variant="inset" light={true} />
            {createListItem("Destination Mac:  " + dstMac.value, dstMac.start, dstMac.length)}
            <Divider variant="inset" light={true} />
          </List>
        </Collapse>
      </div>
    );
  }

  function createIp4Header(header: any, headerLength: string) {
    let srcIp = header.srcIp;
    let destIp = header.destIp;
    let checksum = header.checksum;
    let protocol = header.protocol;
    let ttl = header.timeToLive;
    let totalLength = header.totalLength;
    let headerType = header.headerType;

    return (
      <div>
        <ListItem
          button
          dense={true}
          onClick={handleListItemClickIpv}
          onFocus={() => setHighlight(headerType.start, headerType.start + headerType.length)}
        >
          <ListItemIcon>
            <SettingsEthernetIcon />
          </ListItemIcon>
          <ListItemText primary={"IPv4 Header, " + headerLength + " bytes"} />
          {openIpv ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Divider variant="middle" />
        <Collapse in={openIpv} timeout="auto" unmountOnExit>
          <List>
            {createListItem("Source Ip:  " + srcIp.value, srcIp.start, srcIp.length)}
            <Divider variant="inset" light={true} />
            {createListItem("Destination Ip:  " + destIp.value, destIp.start, destIp.length)}
            <Divider variant="inset" light={true} />
            {createListItem("Checksum:  " + checksum.value, checksum.start, checksum.length)}
            <Divider variant="inset" light={true} />
            {createListItem(totalLength.value, totalLength.start, totalLength.length)}
            <Divider variant="inset" light={true} />
            {createListItem("Protocol:  " + protocol.value, protocol.start, protocol.length)}
            <Divider variant="inset" light={true} />
            {createListItem("Time to live:  " + ttl.value, ttl.start, ttl.length)}
            <Divider variant="inset" light={true} />
          </List>
        </Collapse>
      </div>
    );
  }

  function createIp6Header(header: any, headerLength: string) {
    let srcIp = header.srcIp;
    let destIp = header.destIp;
    let flowLabel = header.flowLabel;
    let hopLimit = header.hopLimit;
    let payloadLength = header.payloadLength;
    let headerType = header.headerType;

    return (
      <div>
        <ListItem
          button
          dense={true}
          onClick={handleListItemClickIpv}
          onFocus={() => setHighlight(headerType.start, headerType.start + headerType.length)}
        >
          <ListItemIcon>
            <SettingsEthernetIcon />
          </ListItemIcon>
          <ListItemText primary={"IPv6 Header, " + headerLength + " bytes"} />
          {openIpv ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Divider variant="middle" />
        <Collapse in={openIpv} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {createListItem("Source Ip:  " + srcIp.value, srcIp.start, srcIp.length)}
            <Divider variant="inset" light={true} />
            {createListItem("Destination Ip:  " + destIp.value, destIp.start, destIp.length)}
            <Divider variant="inset" light={true} />
            {createListItem("Flowlabel:  " + flowLabel.value, flowLabel.start, flowLabel.length)}
            <Divider variant="inset" light={true} />
            {createListItem("Hop Limit:  " + hopLimit.value, hopLimit.start, hopLimit.length)}
            <Divider variant="inset" light={true} />
            {createListItem(
              "Payload Length:  " + payloadLength.value,
              payloadLength.start,
              payloadLength.length
            )}
            <Divider variant="inset" light={true} />
          </List>
        </Collapse>
      </div>
    );
  }

  function createUdpHeader(header: any, headerLength: string) {
    let length = header.length;
    let srcPort = header.srcPort;
    let destPort = header.destPort;
    let checksum = header.checksum;
    let headerType = header.headerType;

    return (
      <div>
        <ListItem
          button
          dense={true}
          onClick={handleListItemClickUdp}
          onFocus={() => setHighlight(headerType.start, headerType.start + headerType.length)}
        >
          <ListItemIcon>
            <SettingsEthernetIcon />
          </ListItemIcon>
          <ListItemText
            primary={"User Datagram Protocol (UDP) Header, " + headerLength + " bytes"}
          />
          {openUdp ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Divider variant="middle" />
        <Collapse in={openUdp} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {createListItem("Source Port:  " + srcPort.value, srcPort.start, srcPort.length)}
            <Divider variant="inset" light={true} />
            {createListItem(
              "Destination Port:  " + destPort.value,
              destPort.start,
              destPort.length
            )}
            <Divider variant="inset" light={true} />
            {createListItem("Length:  " + length.value, length.start, length.length)}
            <Divider variant="inset" light={true} />
            {createListItem("Checksum:  " + checksum.value, checksum.start, checksum.length)}
            <Divider variant="inset" light={true} />
          </List>
        </Collapse>
      </div>
    );
  }

  function createTcpHeader(header: any, headerLength: string) {
    let srcPort = header.srcPort;
    let destPort = header.destPort;
    let checksum = header.checksum;
    let seqNumber = header.seqNumber;
    let ackNumber = header.ackNumber;
    let flags = header.flags;
    let windowSize = header.windowSize;
    let headerType = header.headerType;

    return (
      <div>
        <ListItem
          button
          dense={true}
          onClick={handleListItemClickTcp}
          onFocus={() => setHighlight(headerType.start, headerType.start + headerType.length)}
        >
          <ListItemIcon>
            <SettingsEthernetIcon />
          </ListItemIcon>
          <ListItemText
            primary={"Transmission Control Protocol (TCP) Header, " + headerLength + " bytes"}
          />
          {openTcp ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Divider variant="middle" />
        <Collapse in={openTcp} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {createListItem("Source Port:  " + srcPort.value, srcPort.start, srcPort.length)}
            <Divider variant="inset" light={true} />
            {createListItem(
              "Destination Port:  " + destPort.value,
              destPort.start,
              destPort.length
            )}
            <Divider variant="inset" light={true} />
            {createListItem("Checksum:  " + checksum.value, checksum.start, checksum.length)}
            <Divider variant="inset" light={true} />
            {createListItem(
              "Sequence Number:  " + seqNumber.value,
              seqNumber.start,
              seqNumber.length
            )}
            <Divider variant="inset" light={true} />
            {createListItem(
              "Acknowledgement Number:  " + ackNumber.value,
              ackNumber.start,
              ackNumber.length
            )}
            <Divider variant="inset" light={true} />
            {createListItem(
              "Flags (URG, ACK, PSH, RST, SYN ,FIN):  " + flags.value,
              flags.start,
              flags.length
            )}
            <Divider variant="inset" light={true} />
            {createListItem(
              "Window Size:  " + windowSize.value,
              windowSize.start,
              windowSize.length
            )}
            <Divider variant="inset" light={true} />
          </List>
        </Collapse>
      </div>
    );
  }

  function createDnsHeader(header: any, headerLength: string) {
    let id = header.id;
    let questionsCount = header.questionsCount;
    let answersCount = header.answersCount;
    let headerType = header.headerType;
    let responseOrQuery = header.responseOrQuery;

    return (
      <div>
        <ListItem
          button
          dense={true}
          onClick={handleListItemClickDns}
          onFocus={() => setHighlight(headerType.start, headerType.start + headerType.length)}
        >
          <ListItemIcon>
            <SettingsEthernetIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              "Domain Name System (DNS) " +
              responseOrQuery.value +
              " Header, " +
              headerLength +
              " bytes"
            }
          />
          {openDns ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Divider variant="middle" />
        <Collapse in={openDns} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {createListItem("Id:  " + id.value, id.start, id.length)}
            {createListItem(
              "Questions Count:  " + questionsCount.value,
              questionsCount.start,
              questionsCount.length
            )}
            <Divider variant="inset" light={true} />
            {createListItem(
              "Answers Count:  " + answersCount.value,
              answersCount.start,
              answersCount.length
            )}
            <Divider variant="inset" light={true} />
          </List>
        </Collapse>
      </div>
    );
  }

  function createArpHeader(header: any, headerLength: string) {
    let srcMac = header.srcMac;
    let destMac = header.destMac;
    let srcIp = header.srcIp;
    let destIp = header.destIp;
    let headerType = header.headerType;

    return (
      <div>
        <ListItem
          button
          dense={true}
          onClick={handleListItemClickArp}
          onFocus={() => setHighlight(headerType.start, headerType.start + headerType.length)}
        >
          <ListItemIcon>
            <SettingsEthernetIcon />
          </ListItemIcon>
          <ListItemText
            primary={"Address Resolution Protocol (ARP) Header, " + headerLength + " bytes"}
          />
          {openArp ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Divider variant="middle" />
        <Collapse in={openArp} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {createListItem("Source Mac: " + srcMac.value, srcMac.start, srcMac.length)}
            <Divider variant="inset" light={true} />
            {createListItem("Destination Mac: " + destMac.value, destMac.start, destMac.length)}
            <Divider variant="inset" light={true} />
            {createListItem("Source Ip: " + srcIp.value, srcIp.start, srcIp.length)}
            <Divider variant="inset" light={true} />
            {createListItem("Destination Ip: " + destIp.value, destIp.start, destIp.length)}
            <Divider variant="inset" light={true} />
          </List>
        </Collapse>
      </div>
    );
  }

  function createLlcHeader(header: any, headerLength: string) {
    let h = header.headerType;
    return createListItem(
      "Logical-Link Control (LLC) Header, " + headerLength + " bytes",
      h.start,
      h.length
    );
  }

  function createIcmpvHeader(header: any, headerLength: string) {
    let h = header.headerType;

    return createListItem("ICMPV Header, " + headerLength + " bytes", h.start, h.length);
  }

  function createSshHeader(header: any, headerLength: string) {
    let h = header.headerType;

    return createListItem(
      "Secure Shell (SSH) Header, " + headerLength + " bytes",
      h.start,
      h.length
    );
  }

  function createPppHeader(header: any, headerLength: string) {
    let h = header.headerType;

    return createListItem(
      "Point-to-Point Protocol (PPP) Header, " + headerLength + " bytes",
      h.start,
      h.length
    );
  }

  function createSctpHeader(header: any, headerLength: string) {
    let h = header.headerType;

    // TODO
  }
  function createRtpHeader(header: any, headerLength: string) {
    let version = header.version;
    let padding = header.padding;
    let extension = header.extension;
    let cc = header.cc;
    let marker = header.marker;
    let payloadType = header.payloadType;
    let sequenceNumber = header.sequenceNumber;
    let timeStamp = header.timeStamp;
    let ssrc = header.ssrc;
    let csrc = header.csrc;
    let headerType = header.headerType;

    return (
      <div>
        <ListItem
          button
          dense={true}
          onClick={handleListItemClickRtp}
          onFocus={() => setHighlight(headerType.start, headerType.start + headerType.length)}
        >
          <ListItemIcon>
            <SettingsEthernetIcon />
          </ListItemIcon>
          <ListItemText primary={"Real-Time Transport Protocol, " + headerLength + " bytes"} />
          {openRtp ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Divider variant="middle" />
        <Collapse in={openRtp} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {createListItem("Version:  " + version.value, version.start, version.length)}
            <Divider variant="inset" light={true} />
            {createListItem("Padding: " + padding.value, padding.start, padding.length)}
            <Divider variant="inset" light={true} />
            {createListItem("Extension: " + extension.value, extension.start, extension.length)}
            <Divider variant="inset" light={true} />
            {createListItem("Contributing source identifiers:  " + cc.value, cc.start, cc.length)}
            <Divider variant="inset" light={true} />
            {createListItem("Marker: " + marker.value, marker.start, marker.length)}
            <Divider variant="inset" light={true} />
            {createListItem(
              "Payload Type : " + payloadType.value,
              payloadType.start,
              payloadType.length
            )}
            <Divider variant="inset" light={true} />
            {createListItem(
              "Sequence Number : " + sequenceNumber.value,
              sequenceNumber.start,
              sequenceNumber.length
            )}
            <Divider variant="inset" light={true} />
            {createListItem("Timestamp :  " + timeStamp.value, timeStamp.start, timeStamp.length)}
            <Divider variant="inset" light={true} />
            {createListItem(
              "Synchronization Source identifier :  " + ssrc.value,
              ssrc.start,
              ssrc.length
            )}
            {csrc && <Divider variant="inset" light={true} />}
            {csrc > 0 &&
              createListItem(
                "Contributing source identifiers:  " + ssrc.value,
                ssrc.start,
                ssrc.length
              )}
          </List>
        </Collapse>
      </div>
    );
  }

  function createSmb2Header(header: any, headerLength: string) {
    let protocolId = header.protocolId;
    let structureSize = header.structureSize;
    let creditCharge = header.creditCharge;
    let status = header.status;
    let command = header.command;
    let credits = header.credits;
    let response = header.response;
    let async = header.async;
    let signed = header.signed;
    let priority = header.priority;
    let operation = header.operation;
    let replay = header.replay;
    let nextCommand = header.nextCommand;
    let messageId = header.messageId;
    let asyncId = header.asyncId;
    let reserved = header.reserved;
    let treeId = header.treeId;
    let sessionId = header.sessionId;
    let signature = header.signature;
    let headerType = header.headerType;

    return (
      <div>
        <ListItem
          button
          dense={true}
          onClick={handleListItemClickSmb2}
          onFocus={() => setHighlight(headerType.start, headerType.start + headerType.length)}
        >
          <ListItemIcon>
            <SettingsEthernetIcon />
          </ListItemIcon>
          <ListItemText primary={"Server Message Block 2, " + headerLength + " bytes"} />
          {openSmb2 ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Divider variant="middle" />
        <Collapse in={openSmb2} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {createListItem(
              "Protocol Id:  " + protocolId.value,
              protocolId.start,
              protocolId.length
            )}
            <Divider variant="inset" light={true} />
            {createListItem(
              "Stucture Size: " + structureSize.value,
              structureSize.start,
              structureSize.length
            )}
            <Divider variant="inset" light={true} />
            {createListItem(
              "Credit Charge: " + creditCharge.value,
              creditCharge.start,
              creditCharge.length
            )}
            <Divider variant="inset" light={true} />
            {createListItem("Status:  " + status.value, status.start, status.length)}
            <Divider variant="inset" light={true} />
            {createListItem("Command: " + command.value, command.start, command.length)}
            <Divider variant="inset" light={true} />
            {createListItem("Credits: " + credits.value, credits.start, credits.length)}
            <Divider variant="inset" light={true} />
            {createListItem("Response:  " + response.value, response.start, response.length)}
            <Divider variant="inset" light={true} />
            {createListItem("Async: " + async.value, async.start, async.length)}
            <Divider variant="inset" light={true} />
            {createListItem("Signed:  " + signed.value, signed.start, signed.length)}
            <Divider variant="inset" light={true} />
            {createListItem("Priority: " + priority.value, priority.start, priority.length)}
            <Divider variant="inset" light={true} />
            {createListItem("Operation: " + operation.value, operation.start, operation.length)}
            <Divider variant="inset" light={true} />
            {createListItem("Replay:  " + replay.value, replay.start, replay.length)}
            <Divider variant="inset" light={true} />
            {createListItem(
              "Next Commnand: " + nextCommand.value,
              nextCommand.start,
              nextCommand.length
            )}
            <Divider variant="inset" light={true} />
            {createListItem("Message Id: " + messageId.value, messageId.start, messageId.length)}
            <Divider variant="inset" light={true} />
            {asyncId && (
              <>
                {createListItem("Async Id: " + messageId.value, messageId.start, messageId.length)}
                <Divider variant="inset" light={true} />
              </>
            )}
            {reserved && (
              <>
                {createListItem("Reserved: " + reserved.value, reserved.start, reserved.length)}
                <Divider variant="inset" light={true} />
                {createListItem("Tree Id: " + treeId.value, treeId.start, treeId.length)}
                <Divider variant="inset" light={true} />
              </>
            )}
            {createListItem("Session Id: " + sessionId.value, sessionId.start, sessionId.length)}
            <Divider variant="inset" light={true} />
            {createListItem("Signature: " + signature.value, signature.start, signature.length)}
            <Divider variant="inset" light={true} />
          </List>
        </Collapse>
      </div>
    );
  }

  function createNssHeader(header: any, headerLength: string) {
    let type = header.type;
    let extended = header.extended;
    let length = header.length;
    let headerType = header.headerType;

    return (
      <div>
        <ListItem
          button
          dense={true}
          onClick={handleListItemClickNss}
          onFocus={() => setHighlight(headerType.start, headerType.start + headerType.length)}
        >
          <ListItemIcon>
            <SettingsEthernetIcon />
          </ListItemIcon>
          <ListItemText primary={"NetBios Session Service " + headerLength + " bytes"} />
          {openNss ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Divider variant="middle" />
        <Collapse in={openNss} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {createListItem("Type:  " + type.value, type.start, type.length)}
            <Divider variant="inset" light={true} />
            {createListItem("Extended: " + extended.value, extended.start, extended.length)}
            <Divider variant="inset" light={true} />
            {createListItem("Length: " + length.value, length.start, length.length)}
            <Divider variant="inset" light={true} />
          </List>
        </Collapse>
      </div>
    );
  }

  function createTlsHeader(header: any, headerLength: string) {
    let contentType = header.contentType;
    let version = header.version;
    let length = header.length;
    let packetLength = header.packetLength;
    let headerType = header.headerType;

    let handshakeType = header.handshakeType;
    let handshakeTypeString = header.handshakeTypeString;
    let handshakeLength = header.handshakeLength;

    let randomTime = header.randomTime;
    let randomBytes = header.randomBytes;
    let sessionIdLength = header.sessionIdLength;
    let cipherSuitesLength = header.cipherSuitesLength;
    let cipherSuites = header.cipherSuites;
    let cipherSuite = header.cipherSuite;
    let compressionMethodsLength = header.compressionMethodsLength;
    let compressionMethods = header.compressionMethods;
    let remainingBytes = header.remainingBytes;

    let certificatesLength = header.certificatesLength;
    let certificateLengths = header.certificateLengths;
    let certificates = header.certificates;

    let serverKeyExchangeParams = header.serverKeyExchangeParams;
    let clientKeyExchangeParams = header.clientKeyExchangeParams;

    let encryptedHandshake = header.encryptedHandshake;
    let encryptedApplicationData = header.encryptedApplicationData;
    let illegalData = header.illegalData;

    let tlsItems: any = [];
    let tlsCollapseState: any = [];
    tlsCollapseState.push(openTls);
    tlsCollapseState.push(handleListItemClickTls);

    if(illegalData != null){
      tlsCollapseState = [];
      tlsCollapseState.push(openTlsIllegalData);
      tlsCollapseState.push(handleListItemClickTlsIllegalData);
      return (
        <div>
          <ListItem
            button
            dense={true}
            onClick={tlsCollapseState[1]}
            onFocus={() => setHighlight(headerType.start, headerType.start + packetLength.length*2)}
          >
            <ListItemIcon>
              <SettingsEthernetIcon />
            </ListItemIcon>
            <ListItemText primary={"Transport Layer Security " + packetLength.value + " bytes"} />
            {tlsCollapseState[0] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Divider variant="middle" />
          <Collapse in={tlsCollapseState[0]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {createListItem("Illegal TLS Data: " + illegalData.value, illegalData.start, illegalData.length)}
              <Divider variant="inset" light={true} />
            </List>
          </Collapse>
        </div>
      );
    }

    switch(contentType.value){
      case "HANDSHAKE":
        tlsItems.push(createListItem("Handshake Type: " + handshakeTypeString.value, handshakeTypeString.start, handshakeTypeString.length));
        tlsItems.push(<Divider variant="inset" light={true} />);
        tlsItems.push(createListItem("Handshake Length: " + handshakeLength.value, handshakeLength.start, handshakeLength.length));
        tlsItems.push(<Divider variant="inset" light={true} />);
        if(encryptedHandshake != null){
          tlsItems = [];
          tlsItems.push(createListItem("Encrypted Handshake Message: " + encryptedHandshake.value, encryptedHandshake.start, encryptedHandshake.length));
          tlsItems.push(<Divider variant="inset" light={true} />);
          break;
        }
        console.log(handshakeType.value);
        switch(handshakeType.value){
          // case "0":
          //   break;
          case "1":
            tlsCollapseState = [];
            tlsCollapseState.push(openTlsClientHello);
            tlsCollapseState.push(handleListItemClickTlsClientHello);

            tlsItems.push(createListItem("Random Time: " + randomTime.value, randomTime.start, randomTime.length));
            tlsItems.push(<Divider variant="inset" light={true} />);
            tlsItems.push(createListItem("Random Bytes: " + randomBytes.value, randomBytes.start, randomBytes.length));
            tlsItems.push(<Divider variant="inset" light={true} />);
            tlsItems.push(createListItem("Session ID Length: " + sessionIdLength.value, sessionIdLength.start, sessionIdLength.length));
            tlsItems.push(<Divider variant="inset" light={true} />);
            if(sessionIdLength.value > 0){
              let sessionId = header.sessionId;
              tlsItems.push(createListItem("Session ID: " + sessionId.value, sessionId.start, sessionId.length));
              tlsItems.push(<Divider variant="inset" light={true} />);
            }
            tlsItems.push(createListItem("Cipher Suites Length: " + cipherSuitesLength.value, cipherSuitesLength.start, cipherSuitesLength.length));
            tlsItems.push(<Divider variant="inset" light={true} />);
            tlsItems.push(createListItem("Cipher Suites: " + cipherSuites.value, cipherSuites.start, cipherSuites.length));
            tlsItems.push(<Divider variant="inset" light={true} />);
            tlsItems.push(createListItem("Compression Methods Length: " + compressionMethodsLength.value, compressionMethodsLength.start, compressionMethodsLength.length));
            tlsItems.push(<Divider variant="inset" light={true} />);
            tlsItems.push(createListItem("Compression Methods: " + compressionMethods.value, compressionMethods.start, compressionMethods.length));
            tlsItems.push(<Divider variant="inset" light={true} />);
            tlsItems.push(createListItem("Remaining Bytes: " + remainingBytes.value, remainingBytes.start, remainingBytes.length));
            tlsItems.push(<Divider variant="inset" light={true} />);
            break;

          case "2":
            tlsCollapseState = [];
            tlsCollapseState.push(openTlsServerHello);
            tlsCollapseState.push(handleListItemClickTlsServerHello);

            tlsItems.push(createListItem("Random Time: " + randomTime.value, randomTime.start, randomTime.length));
            tlsItems.push(<Divider variant="inset" light={true} />);
            tlsItems.push(createListItem("Random Bytes: " + randomBytes.value, randomBytes.start, randomBytes.length));
            tlsItems.push(<Divider variant="inset" light={true} />);
            tlsItems.push(createListItem("Session ID Length: " + sessionIdLength.value, sessionIdLength.start, sessionIdLength.length));
            tlsItems.push(<Divider variant="inset" light={true} />);
            if(sessionIdLength.value > 0){
              let sessionId = header.sessionId;
              tlsItems.push(createListItem("Session ID: " + sessionId.value, sessionId.start, sessionId.length));
              tlsItems.push(<Divider variant="inset" light={true} />);
            }
            tlsItems.push(createListItem("Cipher Suite: " + cipherSuite.value, cipherSuite.start, cipherSuite.length));
            tlsItems.push(<Divider variant="inset" light={true} />);
            tlsItems.push(createListItem("Compression Method: " + compressionMethods.value, compressionMethods.start, compressionMethods.length));
            tlsItems.push(<Divider variant="inset" light={true} />);
            tlsItems.push(createListItem("Remaining Bytes: " + remainingBytes.value, remainingBytes.start, remainingBytes.length));
            tlsItems.push(<Divider variant="inset" light={true} />);
            break;

          case "11":
            tlsCollapseState = [];
            tlsCollapseState.push(openTlsCertificate);
            tlsCollapseState.push(handleListItemClickTlsCertificate);

            tlsItems.push(createListItem("Certificates Length: " + certificatesLength.value, certificatesLength.start, certificatesLength.length));
            tlsItems.push(<Divider variant="inset" light={true} />);
            let certificateLengthsJson = JSON.parse(certificateLengths.value);
            let certificatesJson = JSON.parse(certificates.value);
            for (let i = 0; i < certificateLengthsJson.length; i++) {
              tlsItems.push(createListItem("Certificate Length: " + certificateLengthsJson[i], certificatesLength.start, certificatesLength.length));
              tlsItems.push(<Divider variant="inset" light={true} />);
              tlsItems.push(createListItem("Certificate: " + certificatesJson[i], certificatesLength.start, certificatesLength.length));
              tlsItems.push(<Divider variant="inset" light={true} />);
            }
            break;
          case "12":
            tlsCollapseState = [];
            tlsCollapseState.push(openTlsServerKeyExchange);
            tlsCollapseState.push(handleListItemClickTlsServerKeyExchange);

            tlsItems.push(createListItem("EC Diffie-Hellman Server Params (bytes): " + serverKeyExchangeParams.value, serverKeyExchangeParams.start, serverKeyExchangeParams.length));
            tlsItems.push(<Divider variant="inset" light={true} />);
            break;
          case "13":
            break;
          case "14":
            tlsCollapseState = [];
            tlsCollapseState.push(openTlsServerHelloDone);
            tlsCollapseState.push(handleListItemClickTlsServerHelloDone);
            //"Server Hello Done" does not need any additional items
            break;
          case "15":
            break;
          case "16":
            tlsCollapseState = [];
            tlsCollapseState.push(openTlsClientKeyExchange);
            tlsCollapseState.push(handleListItemClickTlsClientKeyExchange);

            tlsItems.push(createListItem("EC Diffie-Hellman Client Params (bytes): " + clientKeyExchangeParams.value, clientKeyExchangeParams.start, clientKeyExchangeParams.length));
            tlsItems.push(<Divider variant="inset" light={true} />);
            break;
          case "20":
            break;
          default:
            tlsItems = [];
            tlsItems.push(createListItem("Encrypted Handshake Message: " + encryptedHandshake.value, encryptedHandshake.start, encryptedHandshake.length));
            tlsItems.push(<Divider variant="inset" light={true} />);
            break;
        }
        break;
      case "APPLICATION_DATA":
        tlsCollapseState = [];
        tlsCollapseState.push(openTlsApplicationData);
        tlsCollapseState.push(handleListItemClickTlsApplicationData);

        tlsItems.push(createListItem("Application Data: " + encryptedApplicationData.value, encryptedApplicationData.start, encryptedApplicationData.length));
        tlsItems.push(<Divider variant="inset" light={true} />);
        break;
      case "CHANGE_CIPHER_SPEC":
        tlsCollapseState = [];
        tlsCollapseState.push(openTlsChangeCipherSpec);
        tlsCollapseState.push(handleListItemClickTlsChangeCipherSpec);
        break;
    }

    return (
      <div>
        <ListItem
          button
          dense={true}
          onClick={tlsCollapseState[1]}
          onFocus={() => setHighlight(headerType.start, headerType.start + packetLength.length*2)}
        >
          <ListItemIcon>
            <SettingsEthernetIcon />
          </ListItemIcon>
          <ListItemText primary={"Transport Layer Security " + packetLength.value + " bytes"} />
          {tlsCollapseState[0] ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Divider variant="middle" />
        <Collapse in={tlsCollapseState[0]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {createListItem("Content Type:  " + contentType.value, contentType.start, contentType.length)}
            <Divider variant="inset" light={true} />
            {createListItem("Version: " + version.value, version.start, version.length)}
            <Divider variant="inset" light={true} />
            {createListItem("Length: " + length.value, length.start, length.length)}
            <Divider variant="inset" light={true} />
            {tlsItems}
          </List>
        </Collapse>
      </div>
    );
  }

  function createHttpHeader(header: any, headerLength: string) {
    let httpHeader = header.httpHeader.value.split("\r\n");
    let httpBody = header.httpBody;
    let httpContent: any = [];
    let httpOffset = header.httpHeader.start;

    for (let s of httpHeader) {
      httpContent.push(createListItem(s, httpOffset, (s.length + 2) * 2));
      httpContent.push(<Divider variant="inset" light={true} />);
      httpOffset += (s.length + 2) * 2;
    }
    let headerType = header.headerType;

    if (httpBody != null) {
      httpContent.push(createListItem("Body: " + httpBody.value, httpBody.start, httpBody.length));
      httpContent.push(<Divider variant="inset" light={true} />);
    }

    return (
      <div>
        <ListItem
          button
          dense={true}
          onClick={handleListItemClickHttp}
          onFocus={() => setHighlight(headerType.start, headerType.start + headerType.length)}
        >
          <ListItemIcon>
            <SettingsEthernetIcon />
          </ListItemIcon>
          <ListItemText primary={" HTTP " + headerLength + " bytes"} />
          {openHttp ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Divider variant="middle" />
        <Collapse in={openHttp} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {httpContent}
          </List>
        </Collapse>
      </div>
    );
  }

  function createBgpHeader(header: any, headerLength: string) {
    let marker = header.marker;
    let length = header.length;
    let type = header.type;
    let typeString = header.typeString;
    let headerType = header.headerType;
    let version = header.version;
    let myAs = header.myAs;
    let holdtime = header.holdtime;
    let bgpID = header.bgpid;
    let optparamlen = header.optparamlen;
    let withdrawnRoutesLen = header.withdrawnRoutesLen;
    let withdrawnRoutes = header.withdrawnRoutes;
    let totalPathAttributeLen = header.totalPathAttributeLen;
    let pathAttributes = header.pathAttributes;
    let networkLayerReachabilityInfo = header.networkLayerReachabilityInfo;
    let networkLayerReachabilityInfoText = header.networkLayerReachabilityInfoText;

    let typeListItems: any = [];
    switch(type.value){
      case "1":
        typeListItems.push(createListItem("Version: " + version.value, version.start, version.length));
        typeListItems.push(<Divider variant="inset" light={true} />);
        typeListItems.push(createListItem("My AS: " + myAs.value, myAs.start, myAs.length));
        typeListItems.push(<Divider variant="inset" light={true} />);
        typeListItems.push(createListItem("Hold Time: " + holdtime.value, holdtime.start, holdtime.length));
        typeListItems.push(<Divider variant="inset" light={true} />);
        typeListItems.push(createListItem("BGP Identifier: " + bgpID.value, bgpID.start, bgpID.length));
        typeListItems.push(<Divider variant="inset" light={true} />);
        typeListItems.push(createListItem("Optional Parameter Length: " + optparamlen.value, optparamlen.start, optparamlen.length));
        typeListItems.push(<Divider variant="inset" light={true} />);
        break;
      case "2":
        typeListItems.push(createListItem("Withdrawn Routes Length: " + withdrawnRoutesLen.value, withdrawnRoutesLen.start, withdrawnRoutesLen.length));
        typeListItems.push(<Divider variant="inset" light={true} />);
        if(withdrawnRoutesLen.value > 0) {
          typeListItems.push(createListItem("Withdrawn Routes: " + withdrawnRoutes.value, withdrawnRoutes.start, withdrawnRoutes.length));
          typeListItems.push(<Divider variant="inset" light={true}/>);
        }
        typeListItems.push(createListItem("Total Path Attribute Length: " + totalPathAttributeLen.value, totalPathAttributeLen.start, totalPathAttributeLen.length));
        typeListItems.push(<Divider variant="inset" light={true} />);

        if(totalPathAttributeLen.value > 0) {
          let pathAttributeItems:any = [];
          let pathAttributesParsed = JSON.parse(pathAttributes.value);
          for (let pathAttribute of pathAttributesParsed){
            pathAttributeItems.push(createListItem(pathAttribute.typecodestr + ", " + pathAttribute.flags, pathAttributes.start, pathAttributes.length));
            pathAttributeItems.push(<Divider variant="inset" light={true} />);
          }

          typeListItems.push(<ListItem button
                                       dense={true}
                                       className={classes.subListItem}
                                       onClick={handleListItemClickPathAttributes}
                                       onFocus={() => setHighlight(pathAttributes.start, pathAttributes.start + pathAttributes.length)}>
            <Typography className={classes.subListItemText}>{"Path Attributes"}</Typography>{openPathAttributes ? <ExpandLess /> : <ExpandMore />}</ListItem>);
          typeListItems.push(<Divider variant="inset" light={true} />)
          typeListItems.push(<Collapse in={openPathAttributes} timeout="auto" unmountOnExit><List component="div" disablePadding>{pathAttributeItems}</List></Collapse>);
        }

        if(typeof networkLayerReachabilityInfo !== "undefined"){
          let nlriItems:any = [];
          let infoText = JSON.parse(networkLayerReachabilityInfoText.value);
          for (let text of infoText) {
            nlriItems.push(createListItem(text, networkLayerReachabilityInfoText.start, networkLayerReachabilityInfoText.length));
            nlriItems.push(<Divider variant="inset" light={true} />);
          }

          typeListItems.push(<ListItem button
                                       dense={true}
                                       className={classes.subListItem}
                                       onClick={handleListItemClickNlri}
                                       onFocus={() => setHighlight(networkLayerReachabilityInfo.start, networkLayerReachabilityInfo.start + networkLayerReachabilityInfo.length)}>
            <Typography className={classes.subListItemText}>{"Network Layer Reachability Info (NLRI)"}</Typography>{openNlri ? <ExpandLess /> : <ExpandMore />}</ListItem>);
          typeListItems.push(<Divider variant="inset" light={true} />)
          typeListItems.push(<Collapse in={openNlri} timeout="auto" unmountOnExit><List component="div" disablePadding>{nlriItems}</List></Collapse>);
        }
        break;
    }

    return (
      <div>
        <ListItem
          button
          dense={true}
          onClick={handleListItemClickBgp}
          onFocus={() => setHighlight(headerType.start, headerType.start + headerType.length)}
        >
          <ListItemIcon>
            <SettingsEthernetIcon />
          </ListItemIcon>
          <ListItemText primary={" BGP " + headerLength + " bytes"} />
          {openBgp ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Divider variant="middle" />
        <Collapse in={openBgp} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/*{methods}*/}
            {createListItem("Marker: " + marker.value, marker.start, marker.length)}
            <Divider variant="inset" light={true} />
            {createListItem("Length: " + length.value, length.start, length.length)}
            <Divider variant="inset" light={true} />
            {createListItem("Type: " + typeString.value, typeString.start, typeString.length)}
            <Divider variant="inset" light={true} />
            {typeListItems}
          </List>
        </Collapse>
      </div>
    );
  }

  function createSyslogHeader(header: any, headerLength: string) {
    let headerType = header.headerType;
    let pri = header.pri;
    let facilityText = header.facilityText;
    let levelText = header.levelText;
    let messageText = header.messageText;

    return (
      <div>
        <ListItem
          button
          dense={true}
          onClick={handleListItemClickSyslog}
          onFocus={() => setHighlight(headerType.start, headerType.start + headerType.length)}
        >
          <ListItemIcon>
            <SettingsEthernetIcon />
          </ListItemIcon>
          <ListItemText primary={" Syslog " + headerLength + " bytes"} />
          {openSyslog ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Divider variant="middle" />
        <Collapse in={openSyslog} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {createListItem(facilityText.value, facilityText.start, facilityText.length)}
            <Divider variant="inset" light={true} />
            {createListItem(levelText.value, levelText.start, levelText.length)}
            <Divider variant="inset" light={true} />
            {createListItem("Message: " + messageText.value, messageText.start, messageText.length)}
            <Divider variant="inset" light={true} />
          </List>
        </Collapse>
      </div>
    );
  }

  function createFtpHeader(header: any, headerLength: string) {
    let requestCommand = header.requestCommand;
    let requestArgument = header.requestArgument;
    let replyCode = header.replyCode;
    let replyDescription = header.replyDescription;
    let replyArgument = header.replyArgument;
    let headerType = header.headerType;

    let ftpContent: any = [];

    if (requestCommand != null) {
      ftpContent.push(createListItem("Request Command: " + requestCommand.value, requestCommand.start, requestCommand.length));
      ftpContent.push(<Divider variant="inset" light={true} />);
      if (requestArgument.value != "" ) {
        ftpContent.push(createListItem("Request Argument: " + requestArgument.value, requestArgument.start, requestArgument.length));
        ftpContent.push(<Divider variant="inset" light={true} />);
      }
    }

    else if (replyCode != null) {
      ftpContent.push(createListItem("Reply Code: (" + replyCode.value + ") " + replyDescription.value, replyCode.start, replyCode.length));
      ftpContent.push(<Divider variant="inset" light={true} />);
      if (replyArgument.value != "") {
        ftpContent.push(createListItem("Reply Argument: " + replyArgument.value, replyArgument.start, replyArgument.length));
        ftpContent.push(<Divider variant="inset" light={true} />);
      }
    }

    return (
      <div>
        <ListItem
          button
          dense={true}
          onClick={handleListItemClickFtp}
          onFocus={() => setHighlight(headerType.start, headerType.start + headerType.length)}
        >
          <ListItemIcon>
            <SettingsEthernetIcon />
          </ListItemIcon>
          <ListItemText primary={" FTP " + headerLength + " bytes"} />
          {openFtp ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Divider variant="middle" />
        <Collapse in={openFtp} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/*{methods}*/}
            {ftpContent}
          </List>
        </Collapse>
      </div>
    );
  }

  function renderHeader(header: any) {
    let headerLength = header.headerLength.value;
    console.log(header);
    if (header.headerType.value === "Ethernet") {
      return createEthernetHeader(header, headerLength);
    }

    if (header.headerType.value === "TCP") {
      return createTcpHeader(header, headerLength);
    }

    if (header.headerType.value === "IPv4") {
      return createIp4Header(header, headerLength);
    }
    if (header.headerType.value === "IPv6") {
      return createIp6Header(header, headerLength);
    }

    if (header.headerType.value === "UDP") {
      return createUdpHeader(header, headerLength);
    }

    if (header.headerType.value === "ARP") {
      return createArpHeader(header, headerLength);
    }
    if (header.headerType.value === "DNS") {
      return createDnsHeader(header, headerLength);
    }
    if (header.headerType.value === "Logical-Link Control") {
      return createLlcHeader(header, headerLength);
    }
    if (header.headerType.value === "ICMPV") {
      return createIcmpvHeader(header, headerLength);
    }

    if (header.headerType.value === "SSH") {
      return createSshHeader(header, headerLength);
    }

    if (header.headerType.value === "PPP") {
      return createPppHeader(header, headerLength);
    }

    if (header.headerType.value === "SCTP") {
      return createSctpHeader(header, headerLength);
    }
    if (header.headerType.value === "RTP") {
      return createRtpHeader(header, headerLength);
    }
    if (header.headerType.value === "NSS") {
      return createNssHeader(header, headerLength);
    }
    if (header.headerType.value === "SMB2") {
      return createSmb2Header(header, headerLength);
    }
    if (header.headerType.value === "HTTP") {
      return createHttpHeader(header, headerLength);
    }
    if (header.headerType.value === "TLS") {
      return createTlsHeader(header, headerLength);
    }

    if (header.headerType.value === "BGP") {
      return createBgpHeader(header, headerLength);
    }

    if (header.headerType.value === "Syslog") {
      return createSyslogHeader(header, headerLength);
    }

    if (header.headerType.value === "FTP") {
      return createFtpHeader(header, headerLength);
    }

    return <div></div>;
  }

  function initiateRenderingPayload(packetId: any) {
    let packetInformation = packetDetails.get(packetId);

    if (!packetInformation) {
      // TODO add spinner
      console.log("not fetched yet");
      return;
    }

    setHighlight(-1, -1);

    if (packetId === renderedId) {
      setChosenPacket([]);
      setRenderedId(-1);
      setRenderPayload(false);
      setHexRows([]);
      setPlainTextRows([]);
      indexForHexHighlight = 0;
      indexForPlainTextHighlight = 0;
    } else {
      setChosenPacket(packetInformation);
      indexForHexHighlight = 0;
      let hexArray = packetInformation.packetAsHexString.split("");

      let plainText = "";
      for (let n = 0; n < packetInformation.packetAsHexString.length; n += 2) {
        let string = String.fromCharCode(
          parseInt(packetInformation.packetAsHexString.substr(n, 2), 16)
        );
        if (/^[\x20-\x7E]*$/.test(string)) {
          plainText += string;
        } else {
          plainText += String.fromCodePoint(0x2022);
        }
      }

      let iterations = Math.ceil(hexArray.length / hexLettersPerRow);
      let hexHelperArray: any = [];

      for (let i = 0; i < iterations; i++) {
        if (hexArray.length <= hexLettersPerRow) {
          hexHelperArray.push(hexArray);
        } else {
          hexHelperArray.push(hexArray.slice(0, hexLettersPerRow));
          hexArray = hexArray.slice(hexLettersPerRow, hexArray.length);
        }
      }
      setHexRows(hexHelperArray);
      let plainTextArray = plainText.split("");
      let plainTextHelperArray: any = [];
      iterations = Math.ceil(plainTextArray.length / plainTextLettersPerRow);

      for (let i = 0; i < iterations; i++) {
        if (plainTextArray.length <= plainTextLettersPerRow) {
          plainTextHelperArray.push(plainTextArray);
        } else {
          plainTextHelperArray.push(plainTextArray.slice(0, plainTextLettersPerRow));
          plainTextArray = plainTextArray.slice(plainTextLettersPerRow, plainTextArray.length);
        }
      }

      setPlainTextRows(plainTextHelperArray);

      if (renderedId === -1) {
        setRenderPayload(true);
      }
      setRenderedId(packetId);
    }
  }

  function initiateFollowModal(e: any, row: any) {
    props.parent.openTables();
    props.parent.openFollowPackets(row);
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  let sliceRows = stableSort(rows, getSorting(order, orderBy)).slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  let iDs = sliceRows
    .map((row: any) => {
      return row.id;
    })
    .filter((id: any) => {
      return !packetDetails.get(id);
    });

  if (iDs.length !== 0) {
    requestPacketDetails(iDs);
  }

  return (
    <div className={classes.root}>
      <Menu
        id="simple-menu"
        open={mouseCoordinates.mouseY !== null}
        onClose={closeMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          mouseCoordinates.mouseY !== null && mouseCoordinates.mouseX !== null
            ? { top: mouseCoordinates.mouseY, left: mouseCoordinates.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={(e) => initiateFollowModal(e, followRow)}>Follow</MenuItem>
      </Menu>
      <Paper elevation={10} className={classes.paper} style={{ height: 322, overflow: "auto" }}>
        {fetching && (
          <CircularProgress
            color="secondary"
            style={{
              position: "absolute",
              top: "45%",
              left: "45%",
              width: 50,
              height: 50,
              margin: 10
            }}
          />
        )}
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {sliceRows.map((row) => {
                let volumeString =
                  row.volume > 1024
                    ? row.volume + " (~" + bytes2String(row.volume as number) + ")"
                    : row.volume + " B";

                let highlightStyle =
                  row.id === renderedId
                    ? {
                        backgroundColor: "#426ab5"
                      }
                    : {};

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    onClick={() => initiateRenderingPayload(row.id)}
                    style={highlightStyle}
                    onContextMenu={(e) => {
                      handleMenu(e, row);
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.time}
                    </TableCell>
                    <TableCell align="left">{row.srcIP}</TableCell>
                    <TableCell align="left">{row.srcPort}</TableCell>
                    <TableCell align="left">{row.destIP}</TableCell>
                    <TableCell align="left">{row.destPort}</TableCell>
                    <TableCell align="left">{row.protocol}</TableCell>
                    <TableCell align="left">{volumeString}</TableCell>
                    <TableCell align="left">{row.inOut}</TableCell>
                    <TableCell align="left">{row.info}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 45 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </Paper>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          "aria-label": "Previous Page"
        }}
        nextIconButtonProps={{
          "aria-label": "Next Page"
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      <Divider variant="middle" />
      <Paper style={{ overflow: "auto", height: 264 }}>
        <List component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
          {renderPayload ? (
            chosenPacket.packetDetails.map((header: any) => {
              let key = header.headerType.value;
              return (
                <div key={key}>
                  {/* @ts-ignore */}
                  {renderHeader(header)}
                </div>
              );
            })
          ) : (
            <ListItem>
              <ListItemText primary="Please select a Packet from the List" />
            </ListItem>
          )}
        </List>
      </Paper>
      <Divider variant="middle" />
      <Paper elevation={10} style={{ overflow: "auto", height: 264 }}>
        <Table>
          <TableHead>
            {renderPayload ? (
              <TableRow>
                <TableCell>
                  <Typography style={{ fontSize: 16 }}>Hex Representation</Typography>
                </TableCell>
                <TableCell>
                  <Typography style={{ fontSize: 16 }}>Plain Text Representation</Typography>
                </TableCell>
              </TableRow>
            ) : (
              <TableRow></TableRow>
            )}
          </TableHead>
          <TableBody>
            <TableRow key={"hexAndPlainText"}>
              <TableCell>
                {/*  <TableCell>
              <Table size={"small"} padding={"none"} style={{maxWidth: "70%", fontFamily: "monospace"}}>
                <TableBody>
                  {renderPayload ? hexRows.map((rowArray: any) => {
                      let i = indexForHexHighlight;
                      indexForHexHighlight = indexForHexHighlight + hexLettersPerRow;
                      return (
                        <TableRow>
                          {renderRow(rowArray, i, true)}
                        </TableRow>
                      )
                    })
                    :
                    (iDs.length === 0) ? <div></div>
                      :
                      <div>
                        <CircularProgress color="secondary" style={{
                          position: "absolute",
                          top: "45%",
                          left: "45%",
                          width: 50,
                          height: 50,
                          marginTop: 40
                        }}/>
                      </div>
                  }
                </TableBody>
              </Table>
            </TableCell> */}
                {renderPayload ? (
                  hexRows.map((rowArray: any[]) => {
                    let lineText = rowArray.reduce((a, b) => a + b);
                    let i = indexForHexHighlight2;
                    indexForHexHighlight2 = indexForHexHighlight2 + hexLettersPerRow;
                    let start = Math.min(rowArray.length, highlightStart - i - 1);
                    let end = Math.max(0, highlightEnd - i);
                    let key = "hex" + i;

                    return (
                      <Typography
                        style={{
                          maxWidth: "100%",
                          fontSize: 18,
                          fontFamily: "monospace",
                          paddingBottom: 10
                        }}
                        key={key}
                      >
                        <span>{lineText.substring(0, start)}</span>
                        <span style={{ backgroundColor: "#92a8d1" }}>
                          {lineText.substring(start, end)}
                        </span>
                        <span>{lineText.substring(end)}</span>
                      </Typography>
                    );
                  })
                ) : (
                  <div />
                )}
              </TableCell>
              {/*  <TableCell>
              <Table size={"small"} padding={"none"} style={{maxWidth: "100%", fontFamily: "monospace"}}>
                <TableBody>
                  {renderPayload ? plainTextRows.map((rowArray: any) => {
                      let i = indexForPlainTextHighlight;
                      indexForPlainTextHighlight = indexForPlainTextHighlight + plainTextLettersPerRow * 2;
                      return (
                        <TableRow>
                          {renderRow(rowArray, i, false)}
                        </TableRow>
                      )
                    })
                    :
                    <div></div>
                  }
                </TableBody>
              </Table>
            </TableCell> */}

              <TableCell>
                {renderPayload ? (
                  plainTextRows.map((rowArray: any[]) => {
                    let lineText = rowArray.reduce((a, b) => a + b);
                    let i = indexForPlainTextHighlight2;
                    indexForPlainTextHighlight2 += rowArray.length * 2;
                    let start = Math.min(
                      rowArray.length,
                      Math.max(0, Math.floor((highlightStart - i) / 2))
                    );
                    let end = Math.max(
                      0,
                      Math.min(rowArray.length, Math.ceil((highlightEnd - i) / 2))
                    );
                    let key = "plainText" + i;

                    return (
                      <Typography
                        style={{
                          maxWidth: "100%",
                          fontSize: 18,
                          fontFamily: "monospace",
                          paddingBottom: 10
                        }}
                        key={key}
                      >
                        <span>{lineText.substring(0, start)}</span>
                        <span style={{ backgroundColor: "#92a8d1" }}>
                          {lineText.substring(start, end)}
                        </span>
                        <span>{lineText.substring(end)}</span>
                      </Typography>
                    );
                  })
                ) : (
                  <div />
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </div>
  );

  function renderRow(rowArray: [], index: number, isHex: boolean) {
    return rowArray.map((hexLetter: string) => {
      if (isHex) {
        index = index + 1;
      } else {
        index = index + 2;
      }
      return highlightStart <= index && index <= highlightEnd ? (
        <TableCell>
          <Typography style={{ fontSize: 16, backgroundColor: "#92a8d1" }}>{hexLetter}</Typography>
        </TableCell>
      ) : (
        <TableCell>
          <Typography style={{ fontSize: 16 }}>{hexLetter}</Typography>
        </TableCell>
      );
    });
  }
}

export default withStyles(styles)(EnhancedTable);
