import React, { useEffect, useState } from "react";
import { createStyles, Theme, WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { Typography, Divider, List, ListItem, ListItemText } from "@material-ui/core";
import classNames from "classnames";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import { bytes2String } from "@utils/HelperFunctions";
import { useSelector } from "react-redux";
import { ApplicationState } from "@store/index";
import { PacketDetailsState } from "@store/packetDetails/types";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
      // marginTop: theme.spacing(3),
    },
    paper: {
      width: "100%"
      // marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 200,
      columnFill: "auto",
      tableLayout: "fixed"
    },
    tableWrapper: {
      overflowX: "auto"
    },
    subListItem: {
      width: "100%",
      cursor: "pointer"
    },
    subListItemText: {
      cursor: "text",
      marginLeft: "4.5%",
      fontSize: 14
    },
    in: {
      color: theme.barColors.incoming
    },
    out: {
      color: theme.barColors.outgoing
    },
    internal: {
      color: theme.barColors.internal
    }
  });

interface FollowPacketProps {
  data: any;
  row: any;
  requestPacketDetails: any;
}

interface Data {
  id: number;
  time: string;
  srcIP: string;
  srcPort: number;
  destIP: string;
  destPort: number;
  protocol: string;
  volume: number;
  inOut: string;
  info: string;
}

interface HeadRow {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headRows: HeadRow[] = [
  { id: "time", numeric: true, disablePadding: false, label: "Time" },
  { id: "srcIP", numeric: false, disablePadding: false, label: "Source IP" },
  { id: "srcPort", numeric: true, disablePadding: false, label: "Source Port" },
  { id: "destIP", numeric: false, disablePadding: false, label: "Destination IP" },
  { id: "destPort", numeric: true, disablePadding: false, label: "Destination Port" },
  { id: "protocol", numeric: false, disablePadding: false, label: "Protocol" },
  { id: "volume", numeric: true, disablePadding: false, label: "Payload in Bytes" },
  { id: "inOut", numeric: false, disablePadding: false, label: "In / Out / Internal" },
  { id: "info", numeric: false, disablePadding: false, label: "Info" }
];

type CombinedProps = FollowPacketProps & WithStyles<typeof styles>;

//Modal for PacketPayloads shows alle Data encoded in ASCII with the srcIp, srcPort and destIp and destPort
function FollowModal(props: CombinedProps) {
  const { classes, row, requestPacketDetails } = props;

  const details: PacketDetailsState = useSelector((state: ApplicationState) => state.packetDetails);

  let filteredData = props.data.filter((e: any) => filterPacket(row, e));

  filteredData = filteredData.sort((a: any, b: any) => {
    let aTime = new Date(a.time);
    let bTime = new Date(b.time);
    return aTime.valueOf() - bTime.valueOf();
  });

  useEffect(() => requestPacketDetails(filteredData.map((e: any) => e.packetId)), [
    row,
    requestPacketDetails
  ]);

  function filterPacket(row: any, packet: any) {
    return (
      (row.srcIP == packet.srcIP &&
        row.srcPort == packet.srcPort &&
        row.destIP == packet.destIP &&
        row.destPort == packet.destPort) ||
      (row.srcIP == packet.destIP &&
        row.srcPort == packet.destPort &&
        row.destIP == packet.srcIP &&
        row.destPort == packet.srcPort)
    );
  }
  function getInOut(n: number) {
    if (n === 0) {
      return "Incoming";
    } else {
      return n === 1 ? "Outgoing" : "Internal";
    }
  }

  //converts Packet into a TableRow and a Row with all the Packetinfo
  function toPlainText(packet: any) {
    let packetInformation = details.packetDetails.get(packet.packetId);
    let plainText = "";

    if (packetInformation) {
      for (let n = 0; n < packetInformation.packetAsHexString.length; n += 2) {
        let string = String.fromCharCode(
          parseInt(packetInformation.packetAsHexString.substr(n, 2), 16)
        );
        if (/^([\x20-\x7E]*|\x0A*)$/.test(string)) {
          plainText += string;
        } else {
          plainText += String.fromCodePoint(0x2022);
        }
      }
      return (
        <TableCell colSpan={8}>
          {packet.inOut == 0 && <Typography className={classes.in}>Incoming</Typography>}
          {packet.inOut == 1 && <Typography className={classes.out}>Outgoing</Typography>}
          {packet.inOut == 2 && <Typography className={classes.internal}>Internal</Typography>}
          <Typography
            style={{
              whiteSpace: "break-spaces",
              wordBreak: "break-all",
              overflowWrap: "anywhere"
            }}
          >
            {plainText}
          </Typography>
        </TableCell>
      );
    }
    return;
  }

  //creates the Table for the Packets
  function renderRows(row: any) {
    return (
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        onWheel={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <TableHead>
          <TableRow>
            {headRows.map((row) => (
              <TableCell
                key={row.id}
                align={"left"}
                padding={row.disablePadding ? "none" : "default"}
              >
                <TableSortLabel>{row.label}</TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map((row: any) => {
            let volumeString =
              row.volume > 1024
                ? row.volume + " (~" + bytes2String(row.volume as number) + ")"
                : row.volume + " B";
            return (
              <React.Fragment key={row.packetId}>
                <TableRow tabIndex={-1}>
                  <TableCell component="th" scope="row">
                    {new Date(row.time).toLocaleDateString() +
                      " " +
                      new Date(row.time).toLocaleTimeString()}
                  </TableCell>
                  <TableCell align="left">{row.srcIP}</TableCell>
                  <TableCell align="left">{row.srcPort}</TableCell>
                  <TableCell align="left">{row.destIP}</TableCell>
                  <TableCell align="left">{row.destPort}</TableCell>
                  <TableCell align="left">{row.protocol}</TableCell>
                  <TableCell align="left">{volumeString}</TableCell>
                  <TableCell align="left">{getInOut(row.inOut)}</TableCell>
                  <TableCell align="left">{row.info}</TableCell>
                </TableRow>
                <TableRow tabIndex={-1}>{toPlainText(row)}</TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    );
  }

  return <div className={classes.root}>{renderRows(row)}</div>;
}

export default withStyles(styles)(FollowModal);
