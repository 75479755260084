import * as PIXI from "pixi.js";

// The IP/DNS name label attached to each node
export default class Label extends PIXI.Text {
  constructor(node, graph) {
    super();

    this.node = node;
    this.graph = graph;

    this.style = {
      fontFamily: "Helvetica",
      fontSize: 12,
      fill: this.graph.theme.palette.text.primary
    };
    this.updateLabelText();
    this.updatePosition();

    this.resolution = 2;
    this.anchor.set(0.5, 0);
    this.alpha = 0;
  }

  // Reevaluated the shows label text (IP or DNS name)
  updateLabelText() {
    this.text =
      this.graph.dnsMap && this.graph.dnsMap[this.node.value]
        ? this.graph.dnsMap[this.node.value]
        : this.node.value;
    this.style.fill = this.graph.theme.palette.text.primary;
  }

  // Updates the labels position
  updatePosition() {
    this.x = this.node.x;
    this.y = this.node.y + this.node.calcNodeRadius();
  }
}
