import React, { Component, RefObject } from "react";
import { connect } from "react-redux";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core";
import { returnType } from "@utils/TypeUtils";
import { ApplicationState } from "@store/index";
import { FilterType } from "@store/filter/FilterStatus";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CloseIcon from "@material-ui/icons/Close";
import {bytes2String, count2String} from "@utils/HelperFunctions";
import * as d3 from "d3";
import { FilterAction, FilterField } from "@store/filter/types";
import { editGraphPositions } from "@store/user/actions";
import * as filterActions from "@store/filter/actions";
import ForceGraph from "./ForceGraph";
import GraphNode from "./Node";
import { setGraphPositionsLocal } from "@store/websocket/actions";

// Definition of injected styles
const styles = (theme: Theme) =>
  createStyles({
    closeIcon: {
      color: theme.palette.text.primary,
      position: "absolute",
      top: 5,
      right: 5,
      cursor: "Pointer"
    }
  });

// Component-specific props.
interface ComponentProps {
  theme: Theme; // has to be defined here when using redux connect and forwardRef because https://github.com/mui-org/material-ui/issues/8958#issuecomment-410231896
}

// Redux: Map redux store state to component props
const mapStateToProps = (state: ApplicationState) => ({
  graphData: state.websocket.graphData,
  graphMode: state.visMode.graphMode,
  filterState: state.filter,
  dnsMap: state.websocket.dnsMap,
  sessionId: state.websocket.sessionId,
  username: state.user.username,
  fileId: state.user.fileId,
  user: state.user
});

// Redux: Map action dispatchers to props
const mapDispatchToProps = (dispatch: any) => ({
  updateFilter: (filterType: FilterType, act: FilterAction, field: FilterField, payload: string) =>
    dispatch(filterActions.updateFilter(filterType, act, field, payload)),
  editGraphPositions: (username: string, fileId: number, data: string, sessionId: string) =>
    dispatch(editGraphPositions(username, fileId, data, sessionId)),
  setGraphPositionsLocal: (data: string) => dispatch(setGraphPositionsLocal(data))
});

// Props passed from mapStateToProps
const reduxProps = returnType(mapStateToProps);
type PropsFromReduxState = typeof reduxProps;

// Props passed from mapDispatchToProps
const reduxActions = returnType(mapDispatchToProps);
type PropsFromReduxDispatch = typeof reduxActions;

// Component state
interface ComponentState {
  showTooltip: boolean;
  tooltipData: any;
  mouseX: number | null;
  mouseY: number | null;
  node: GraphNode | null;
}

type CombinedProps = PropsFromReduxState &
  PropsFromReduxDispatch &
  ComponentProps &
  WithStyles<typeof styles>;

class GraphComponent extends Component<CombinedProps, ComponentState> {
  public _chart: any;
  public myRef: RefObject<HTMLDivElement>;
  constructor(props: CombinedProps) {
    super(props);
    this.myRef = React.createRef();
    this.updateDimensions = this.updateDimensions.bind(this);
    this.state = {
      showTooltip: false,
      tooltipData: [],
      mouseX: null,
      mouseY: null,
      node: null
    };
  }

  callContextMenu(mouseX: number, mouseY: number, node: GraphNode) {
    this.setState({
      mouseX,
      mouseY,
      node
    });
  }

  updateDimensions() {
    this._chart.updateDimensions(this.myRef.current);
  }

  componentWillUnmount(): void {
    window.removeEventListener("resize", this.updateDimensions);
  }

  componentDidMount() {
    this._chart = new ForceGraph(
      this.myRef.current,
      this.props,
      [],
      {
        showTooltip: this.showTooltip.bind(this)
      },
      this.callContextMenu.bind(this)
    );
    window.addEventListener("resize", this.updateDimensions);
    if (this.props.dnsMap) {
      this._chart.updateDnsMap(this.props.dnsMap);
    }

    if (
      Object.keys(this.props.graphData).length !== 0 &&
      this.props.graphData.constructor === Object
    ) {
      let data =
        this.props.graphMode === "ip" ? this.props.graphData.ip : this.props.graphData.port;
      this._chart.updateData(
        data,
        this.props.filterState,
        this.props.graphMode,
        this.props.graphData.graphPositions,
        this.props.graphData.progress,
        this.props.graphData.networkExpansionLevel
      );
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevProps.graphData != this.props.graphData ||
      this.props.graphMode != prevProps.graphMode ||
      prevProps.user.showVolumeGraphVis !== this.props.user.showVolumeGraphVis
    ) {
      let data =
        this.props.graphMode === "ip" ? this.props.graphData.ip : this.props.graphData.port;
      this._chart.updateData(
        data,
        this.props.filterState,
        this.props.graphMode,
        this.props.graphData.graphPositions,
        this.props.graphData.progress,
        this.props.graphData.networkExpansionLevel
      );
      this.clearTooltip();
    }
    if (this.props.theme != prevProps.theme) {
      this._chart.updateTheme(this.props.theme);
    }
    if (this.props.filterState != prevProps.filterState) {
      this._chart.updateSelection(this.props.filterState);
    }
    if (this.props.dnsMap != prevProps.dnsMap) {
      this._chart.updateDnsMap(this.props.dnsMap);
    }
  }

  clearTooltip() {
    this.setState({
      showTooltip: false
    });
  }

  showTooltip(toolTipData: any) {
    this.setState({
      showTooltip: true,
      tooltipData: toolTipData
    });
  }

  resetView() {
    this._chart.resetView();
  }

  render() {
    const { classes } = this.props;
    let rows: any = [];
    let data: any;
    let format = d3.format(".2s");
    let selectedNodeText: any;
    if (this.state.showTooltip) {
      data = this.state.tooltipData;
      let sortArray: any = [];
      data.adjNodes.forEach((e: any) => {
        let volume = data.adjLinks.filter(
          (x: any) => x.source.value === e.value || x.target.value === e.value
        )[0].volume;
        sortArray.push({ value: e.value, volume: volume });
      });
      sortArray.sort((a: any, b: any) => b.volume - a.volume);
      sortArray.forEach((e: any) => {
        let name = e.value;
        if (this.props.dnsMap && this.props.dnsMap[e.value]) {
          name = (
            <span style={{ marginLeft: 20, wordBreak: "break-all", display: "inline-flex" }}>
              {this.props.dnsMap[e.value]}
              <br />({e.value})
            </span>
          );
        }
        rows.push(
          <Typography key={e.value} style={{ marginLeft: 20 }}>
            <b style={{ color: this.props.theme.palette.secondary.main }}>
              {this.props.user.showVolumeGraphVis
                ? bytes2String(e.volume)
                : count2String(e.volume)}
            </b>{" "}
            with: <b style={{ color: this.props.theme.palette.secondary.main }}>{name}</b>
          </Typography>
        );
      });
      selectedNodeText = data.selectedNode.value;
      if (this.props.dnsMap && this.props.dnsMap[data.selectedNode.value])
        selectedNodeText = (
          <span>
            <br />
            {this.props.dnsMap[data.selectedNode.value]}
            <br />({data.selectedNode.value})
          </span>
        );
    }

    const handleClose = () => {
      this.setState({ mouseY: null, mouseX: null });
    };

    return (
      <div style={{ height: "100%", overflow: "hidden" }} ref={this.myRef}>
        {this.state.node && (
          <Menu
            onContextMenu={(e) => e.preventDefault()}
            keepMounted={false}
            open={this.state.mouseY !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
              this.state.mouseY !== null && this.state.mouseX !== null
                ? { top: this.state.mouseY, left: this.state.mouseX }
                : undefined
            }
          >
            <MenuItem
              onClick={() => {
                this._chart.options.showTooltip({
                  selectedNode: this.state.node,
                  adjNodes: this.state.node!.getAdjacentNodes(),
                  adjLinks: this.state.node!.getAdjacentLinks()
                });
                handleClose();
              }}
            >
              Show additional info
            </MenuItem>
            <hr />
            <MenuItem
              onClick={() => {
                this.state.node!.expandAllNeighbors();
                handleClose();
              }}
            >
              Show all neighbors of {this.state.node.value}
            </MenuItem>
            <MenuItem
              onClick={() => {
                this.state.node!.hideNode();
                handleClose();
              }}
            >
              Hide node {this.state.node.value}
            </MenuItem>
            <hr />
            <MenuItem
              onClick={() => {
                this._chart.filterSrc(FilterType.include, this._chart.mode, this.state.node);
                handleClose();
              }}
            >
              Filter for Source {this.state.node.value}
            </MenuItem>
            <MenuItem
              onClick={() => {
                this._chart.filterDest(FilterType.include, this._chart.mode, this.state.node);
                handleClose();
              }}
            >
              Filter for Destination {this.state.node.value}
            </MenuItem>
            <MenuItem
              onClick={() => {
                this._chart.filterSrc(FilterType.include, this._chart.mode, this.state.node);
                this._chart.filterDest(FilterType.include, this._chart.mode, this.state.node);
                handleClose();
              }}
            >
              Filter for both {this.state.node.value}
            </MenuItem>
            <hr />
            <MenuItem
              onClick={() => {
                this._chart.filterSrc(FilterType.exclude, this._chart.mode, this.state.node);
                handleClose();
              }}
            >
              Exclude Source {this.state.node.value}
            </MenuItem>
            <MenuItem
              onClick={() => {
                this._chart.filterDest(FilterType.exclude, this._chart.mode, this.state.node);
                handleClose();
              }}
            >
              Exclude Destination {this.state.node.value}
            </MenuItem>
            <MenuItem
              onClick={() => {
                this._chart.filterSrc(FilterType.exclude, this._chart.mode, this.state.node);
                this._chart.filterDest(FilterType.exclude, this._chart.mode, this.state.node);
                handleClose();
              }}
            >
              Exclude both {this.state.node.value}
            </MenuItem>
          </Menu>
        )}
        {this.state.showTooltip && (
          <Paper
            elevation={3}
            id={"additional-info"}
            style={{
              padding: 5,
              paddingRight: 35,
              position: "absolute",
              right: 0,
              marginRight: 20,
              top: 20,
              textAlign: "left",
              display: "flex",
              flexDirection: "column",
              maxHeight: this.myRef.current!.clientHeight,
              maxWidth: 300,
              wordBreak: "break-all"
            }}
          >
            <Typography>
              Selected Node:{" "}
              <b style={{ color: this.props.theme.palette.secondary.main }}>{selectedNodeText}</b>
            </Typography>
            <Typography>
              Connections:{" "}
              <b style={{ color: this.props.theme.palette.secondary.main }}>
                {format(data.selectedNode.connectivity)}
              </b>
            </Typography>
            <Typography>Adjacency:</Typography>
            <div style={{ overflow: "auto", flexGrow: 1, marginRight: -28, paddingRight: 5 }}>
              {rows}
            </div>
            <CloseIcon onClick={this.clearTooltip.bind(this)} className={classes.closeIcon} />
          </Paper>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  withStyles(styles, { withTheme: true })(GraphComponent)
);
