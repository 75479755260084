import * as d3 from "d3";
import {isFilterClean} from "@store/filter/reducer";
import {FilterState} from "@store/filter/types";
import {WiresharkParser} from "./WiresharkParser";
import {Filter} from "@store/filter/FilterStatus";


export function getBucketSize(aggregation: string) {
  switch (aggregation) {
    case "Second":
      return 1000;
    case "10 Second":
      return 10000;
    case "Minute":
      return 60000;
    case "10 Minute":
      return 600000;
    case "Hour":
      return 3600000;
    default:
      return 1000;
  }
}

export function getAggregationText(bucketSize: number) {
  switch (bucketSize) {
    case 1000:
      return "Second";
    case 10000:
      return "10 Second";
    case 60000:
      return "Minute";
    case 600000:
      return "10 Minute";
    case 3600000:
      return "Hour";
    default:
      return "Second";
  }
}

export function bytes2String(bytes: number) {
  if (bytes === 0 || bytes == null) {
    return "0 B";
  }
  let thresh = 1000;
  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }
  let units = ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + " " + units[u];
}

export function count2String(count: number) {
  if (count === null || Math.abs(count) < 1000) {
    return count.toFixed(0);
  }
  let format = d3.format('.2s');
  return format(count)
}

/**
 * Parses Wireshark display filter using `WiresharkParser.js` (see {@link WiresharkParser}),
 * then reformats them into appropriate {@link Filter} objects. `WiresharkParser.js` is generated
 * from `WiresharkDisplayFilter.pegjs`
 *
 * @param string queryString
 * @return
 */
export function parseWiresharkFilter(queryString: string): { include: Filter, exclude: Filter } {
  let parsedStrings: string[][] = WiresharkParser.parse(queryString);
  console.log('parsedStrings', parsedStrings);
  // init blank filters
  let includeFilter = {
    srcPort: [] as number[],
    destPort: [] as number[],
    srcIP: [] as string[],
    destIP: [] as string[],
    startTime: -1,
    endTime: -1,
    protocol: [] as string[],
    inOut: [] as string[]
  };
  let excludeFilter = {
    srcPort: [] as number[],
    destPort: [] as number[],
    srcIP: [] as string[],
    destIP: [] as string[],
    startTime: -1,
    endTime: -1,
    protocol: [] as string[],
    inOut: [] as string[]
  };
  for (let item of parsedStrings) {
    let ft = includeFilter;
    // remove double negations
    while (item[0].startsWith('!!'))
      item[0] = item[0].substring(2);
    if (item[0].startsWith('!')) {
      ft = excludeFilter;
      item[0] = item[0].substring(1);
    }
    ;
    switch (item[0]) {
      case ('startTime'):
        if (ft.startTime !== -1)
          throw new WiresharkParser.SyntaxError(`Multiple start times not allowed! (Found: ${item[1]})`,
            `'(frame.time >= "%b %d, %Y %H:%M:%S")' must be unique!`, item[1], null);
        let startTime: string = parseWiresharkFilterDateTime(item[1]);
        ft.startTime = parseInt(startTime);
        break;
      case ('endTime'):
        if (ft.endTime !== -1)
          throw new WiresharkParser.SyntaxError(`Multiple end times not allowed! (Found: ${item[1]})`,
            `'(frame.time <= "%b %d, %Y %H:%M:%S")' must be unique!`, item[1], null);
        let endTime: string = parseWiresharkFilterDateTime(item[1]);
        ft.endTime = parseInt(endTime);
        break;
      case ('srcIP'):
        if (!ft.srcIP.includes(item[1]))
          ft.srcIP.push(item[1]);
        break;
      case ('destIP'):
        if (!ft.destIP.includes(item[1]))
          ft.destIP.push(item[1]);
        break;
      case ('srcPort'):
        let srcPort = parseInt(item[1]);
        if (!ft.srcPort.includes(srcPort))
          ft.srcPort.push(srcPort);
        break;
      case ('destPort'):
        let destPort = parseInt(item[1]);
        if (!ft.destPort.includes(destPort))
          ft.destPort.push(destPort);
        break;
      case ('arp'):
      case ('tcp'):
      case ('udp'):
      case ('http'):
      case ('dns'):
        if (!ft.protocol.includes(item[0]))
          ft.protocol.push(item[0].toUpperCase());
        break;
      default:
        // should not happen
        throw new WiresharkParser.SyntaxError(`Error parsing "${item[0]}".`, null, null, null);
    }
    ;
  }
  ;
  if (includeFilter.endTime <= includeFilter.startTime && (includeFilter.startTime !== -1 && includeFilter.endTime !== -1))
    throw new RangeError("Time range must be greater than zero!");
  return {include: includeFilter, exclude: excludeFilter};
}

function parseWiresharkFilterDateTime(queryString: string) {
  // let timeParser = d3.timeParse("%b %d, %Y %H:%M:%S");
  // let time = timeParser(queryString);
  let time = new Date(queryString).getTime();
  if (!isNaN(time))
    return time.toFixed(0);
  else {
    throw new WiresharkParser.SyntaxError(
      `Invalid date time! Expected "%b %d, %Y %H:%M:%S" but found "${queryString}".`,
      '"%b %d, %Y %H:%M:%S"', queryString, null);
  }

}


export function createWiresharkFilterString(filterState: FilterState) {
  if (isFilterClean(filterState)) return "No filters set";

  let formatTime = d3.timeFormat("%b %d, %Y %H:%M:%S");
  let startTimeString = '';
  let endTimeString = '';
  if (filterState.includeFilter.startTime !== -1)
    startTimeString = '(frame.time >= "' + formatTime(new Date(filterState.includeFilter.startTime)) + '")';
  if (filterState.includeFilter.endTime !== -1)
    endTimeString = '(frame.time <= "' + formatTime(new Date(filterState.includeFilter.endTime)) + '")';
  let timeString = '';
  if (startTimeString !== '' && endTimeString !== '')
    timeString = startTimeString + ' && ' + endTimeString;
  else if (startTimeString !== '')
    timeString = startTimeString;
  else if (endTimeString !== '')
    timeString = endTimeString;
  let first = timeString === ""; // is the first string already filled
  let srcIPInclude = "";
  for (let i = 0; i < filterState.includeFilter.srcIP.length; i++) {
    if (i === 0) {
      if (!first) {
        srcIPInclude += " && ";
      } else first = false;
      srcIPInclude = srcIPInclude + "(ip.src == " + filterState.includeFilter.srcIP[i];
    } else {
      srcIPInclude = srcIPInclude + " || ip.src == " + filterState.includeFilter.srcIP[i];
    }
    if (i === filterState.includeFilter.srcIP.length - 1) srcIPInclude = srcIPInclude + ")";
  }
  let srcIPExclude = "";
  for (let i = 0; i < filterState.excludeFilter.srcIP.length; i++) {
    if (i === 0) {
      if (!first) {
        // add "&& if it is not the first string
        srcIPExclude += " && ";
      } else first = false;
      srcIPExclude = srcIPExclude + "!(ip.src == " + filterState.excludeFilter.srcIP[i];
    } else {
      srcIPExclude = srcIPExclude + " || ip.src == " + filterState.excludeFilter.srcIP[i];
    }
    if (i === filterState.excludeFilter.srcIP.length - 1) srcIPExclude = srcIPExclude + ")";
  }
  let srcIPString = srcIPInclude + srcIPExclude;

  let destIPInclude = "";
  for (let i = 0; i < filterState.includeFilter.destIP.length; i++) {
    if (i === 0) {
      if (!first) {
        destIPInclude += " && ";
      } else first = false;

      destIPInclude = destIPInclude + "(ip.dst == " + filterState.includeFilter.destIP[i];
    } else {
      destIPInclude = destIPInclude + " || ip.dst == " + filterState.includeFilter.destIP[i];
    }
    if (i === filterState.includeFilter.destIP.length - 1) destIPInclude = destIPInclude + ")";
  }
  let destIPExclude = "";
  for (let i = 0; i < filterState.excludeFilter.destIP.length; i++) {
    if (i === 0) {
      if (!first) {
        destIPExclude += " && ";
      } else first = false;
      destIPExclude = destIPExclude + "!(ip.dst == " + filterState.excludeFilter.destIP[i];
    } else {
      destIPExclude = destIPExclude + " || ip.dst == " + filterState.excludeFilter.destIP[i];
    }
    if (i === filterState.excludeFilter.destIP.length - 1) destIPExclude = destIPExclude + ")";
  }
  let destIPString = destIPInclude + destIPExclude;

  let srcPortInclude = "";
  for (let i = 0; i < filterState.includeFilter.srcPort.length; i++) {
    if (i === 0) {
      if (!first) {
        srcPortInclude += " && ";
      } else first = false;
      srcPortInclude =
        srcPortInclude +
        "((tcp.srcport == " +
        filterState.includeFilter.srcPort[i] +
        " || udp.srcport == " +
        filterState.includeFilter.srcPort[i] +
        ")";
    } else {
      srcPortInclude =
        srcPortInclude +
        " || (tcp.srcport == " +
        filterState.includeFilter.srcPort[i] +
        " || udp.srcport == " +
        filterState.includeFilter.srcPort[i] +
        ")";
    }
    if (i === filterState.includeFilter.srcPort.length - 1) srcPortInclude = srcPortInclude + ")";
  }
  let srcPortExclude = "";
  for (let i = 0; i < filterState.excludeFilter.srcPort.length; i++) {
    if (i === 0) {
      if (!first) {
        srcPortExclude += " && ";
      } else first = false;
      srcPortExclude =
        srcPortExclude +
        "!((tcp.srcport == " +
        filterState.excludeFilter.srcPort[i] +
        " || udp.srcport == " +
        filterState.excludeFilter.srcPort[i] +
        ")";
    } else {
      srcPortExclude =
        srcPortExclude +
        " || (tcp.srcport == " +
        filterState.excludeFilter.srcPort[i] +
        " || udp.srcport == " +
        filterState.excludeFilter.srcPort[i] +
        ")";
    }
    if (i === filterState.excludeFilter.srcPort.length - 1) srcPortExclude = srcPortExclude + ")";
  }
  let srcPortString = srcPortInclude + srcPortExclude;

  let destPortInclude = "";
  for (let i = 0; i < filterState.includeFilter.destPort.length; i++) {
    if (i === 0) {
      if (!first) {
        destPortInclude += " && ";
      } else first = false;
      destPortInclude =
        destPortInclude +
        "((tcp.dstport == " +
        filterState.includeFilter.destPort[i] +
        " || udp.dstport == " +
        filterState.includeFilter.destPort[i] +
        ")";
    } else {
      destPortInclude =
        destPortInclude +
        " || (tcp.dstport == " +
        filterState.includeFilter.destPort[i] +
        " || udp.dstport == " +
        filterState.includeFilter.destPort[i] +
        ")";
    }
    if (i === filterState.includeFilter.destPort.length - 1)
      destPortInclude = destPortInclude + ")";
  }
  let destPortExclude = "";
  for (let i = 0; i < filterState.excludeFilter.destPort.length; i++) {
    if (i === 0) {
      if (!first) {
        destPortExclude += " && ";
      } else first = false;
      destPortExclude =
        destPortExclude +
        "!((tcp.dstport == " +
        filterState.excludeFilter.destPort[i] +
        " || udp.dstport == " +
        filterState.excludeFilter.destPort[i] +
        ")";
    } else {
      destPortExclude =
        destPortExclude +
        " || (tcp.dstport == " +
        filterState.excludeFilter.destPort[i] +
        " || udp.dstport == " +
        filterState.excludeFilter.destPort[i] +
        ")";
    }
    if (i === filterState.excludeFilter.destPort.length - 1)
      destPortExclude = destPortExclude + ")";
  }
  let destPortString = destPortInclude + destPortExclude;

  let protocolInclude = "";
  for (let i = 0; i < filterState.includeFilter.protocol.length; i++) {
    if (i === 0) {
      if (!first) {
        protocolInclude += " && ";
      } else first = false;
      protocolInclude =
        protocolInclude + "(" + filterState.includeFilter.protocol[i].toLowerCase();
    } else {
      protocolInclude =
        protocolInclude + " || " + filterState.includeFilter.protocol[i].toLowerCase();
    }
    if (i === filterState.includeFilter.protocol.length - 1)
      protocolInclude = protocolInclude + ")";
  }
  let protocolExclude = "";
  for (let i = 0; i < filterState.excludeFilter.protocol.length; i++) {
    if (i === 0) {
      if (!first) {
        protocolExclude += " && ";
      } else first = false;
      protocolExclude =
        protocolExclude + "!(" + filterState.excludeFilter.protocol[i].toLowerCase();
    } else {
      protocolExclude =
        protocolExclude + " || " + filterState.excludeFilter.protocol[i].toLowerCase();
    }
    if (i === filterState.excludeFilter.protocol.length - 1)
      protocolExclude = protocolExclude + ")";
  }
  let protocolString = protocolInclude + protocolExclude;

  return timeString + srcIPString + destIPString + srcPortString + destPortString + protocolString;
}
