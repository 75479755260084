export enum GlobalHeaderMode {
  NOT_FOUND = "NOT_FOUND",
  NORMAL = "NORMAL",
  SWAPPED = "SWAPPED",
  NANO_NORMAL = "NANO_NORMAL",
  NANO_SWAPPED = "NANO_SWAPPED",
  WIRESHARK = "WIRESHARK",
  NG_NORMAL = "NG_NORMAL",
  NG_SWAPPED = "NG_SWAPPED"
}

export function getGlobalHeaderInfo(array: Array<number>) {
  let potentialHexHeader = byteArrayToHex(array, 0, 100);
  let params = getGlobalHeaderParameters(potentialHexHeader, array);

  const globalHederStart = params.start;
  const mode = params.mode;
  const length = params.length;

  if (mode === GlobalHeaderMode.NORMAL || mode === GlobalHeaderMode.SWAPPED) {
    let startInMillis;
    if (mode === GlobalHeaderMode.SWAPPED)
      startInMillis = getTimestampInMillis(array, globalHederStart + length, true);
    else startInMillis = getTimestampInMillis(array, globalHederStart + length, false);

    const header = array.slice(globalHederStart, globalHederStart + length);
    return {
      header: header,
      mode: mode,
      start: globalHederStart,
      length: length,
      timestamp: startInMillis
    };
  } else if (mode === GlobalHeaderMode.NG_NORMAL || mode === GlobalHeaderMode.NG_SWAPPED) {
    let startInMillis;
    if (mode === GlobalHeaderMode.NG_SWAPPED)
      startInMillis = getTimestampInMillis_NG(array, globalHederStart + length, true);
    else startInMillis = getTimestampInMillis_NG(array, globalHederStart + length, false);

    const header = array.slice(globalHederStart, globalHederStart + length);
    return {
      header: header,
      mode: mode,
      start: globalHederStart,
      length: length,
      timestamp: startInMillis
    };
  } else return { header: null, mode: mode, start: globalHederStart, length: length, timestamp: 0 };
}

export function modifyPacket(
  array: Array<number>,
  packetHeaderStart: number,
  defaultCutLengthInByte: number,
  swapped: boolean,
  selectedProtocols: string[],
  cutPayload: boolean
) {
  let protocol: any;
  let oldPacketLengthInByte;
  let cutLengthInByte;
  let newPosition;
  let packetData = null;

  const etherType = getEthernetType(array, packetHeaderStart + 16);
  switch (etherType) {
    case "IPv4":
      // The IPv4 packet starts at byte 14 of the packet data.
      // Therefore add 16 bytes of the PCAP Packet Record to get the IPv4 packet at byte 30.
      protocol = getTransportProtocol(array, packetHeaderStart + 30, "IPv4", cutPayload);

      // get old packet size
      oldPacketLengthInByte = getPacket_incl_len(array, packetHeaderStart, swapped) + 16;
      // calc next position
      newPosition = packetHeaderStart + oldPacketLengthInByte;

      if (selectedProtocols.includes(protocol.protocol)) {
        cutLengthInByte =
          protocol.protocolHeaderLength === 0
            ? defaultCutLengthInByte
            : 30 + protocol.ipHeaderLength + protocol.protocolHeaderLength;

        // slice with new size
        cutLengthInByte = Math.min(oldPacketLengthInByte, cutLengthInByte);
        packetData = array.slice(packetHeaderStart, newPosition);
        // modify PCAP packet header only in case payload is cut off
        if (cutPayload) {
          packetData = array.slice(packetHeaderStart, packetHeaderStart + cutLengthInByte);
          modifyPacketHeader(packetData, cutLengthInByte, swapped);
        }       
      }
      // return sliced packet and position
      return [newPosition, packetData];

      case "IPv6":
        protocol = getTransportProtocol(array, packetHeaderStart + 30, "IPv6", cutPayload);
  
        // get old packet size
        oldPacketLengthInByte = getPacket_incl_len(array, packetHeaderStart, swapped) + 16;
        // calc next position
        newPosition = packetHeaderStart + oldPacketLengthInByte;
  
        if (selectedProtocols.includes(protocol.protocol)) {
          cutLengthInByte =
            protocol.protocolHeaderLength === 0
              ? defaultCutLengthInByte
              : 30 + protocol.ipHeaderLength + protocol.protocolHeaderLength;
  
          // slice with new size
          cutLengthInByte = Math.min(oldPacketLengthInByte, cutLengthInByte);
          packetData = array.slice(packetHeaderStart, packetHeaderStart + cutLengthInByte);
          // modify PCAP packet header only in case payload is cut off
          if (cutPayload) {
            packetData = array.slice(packetHeaderStart, packetHeaderStart + cutLengthInByte);
            modifyPacketHeader(packetData, cutLengthInByte, swapped);
          }
        }
        // return sliced packet and position
        return [newPosition, packetData];

    case "ARP":
      // get old packet size
      oldPacketLengthInByte = getPacket_incl_len(array, packetHeaderStart, swapped) + 16;
      // calc next position
      newPosition = packetHeaderStart + oldPacketLengthInByte;

      if (selectedProtocols.includes("ARP")) {
        cutLengthInByte = 58;

        // slice with new size
        cutLengthInByte = Math.min(oldPacketLengthInByte, cutLengthInByte);
        packetData = array.slice(packetHeaderStart, packetHeaderStart + cutLengthInByte);
        // modify PCAP packet header only in case payload is cut off
        if (cutPayload) {
          packetData = array.slice(packetHeaderStart, packetHeaderStart + cutLengthInByte);
          modifyPacketHeader(packetData, cutLengthInByte, swapped);
        }
      }
      // return sliced packet and position
      return [newPosition, packetData];

    default:
      // TODO what to do if not IPv4?
      // get old packet size
      oldPacketLengthInByte = getPacket_incl_len(array, packetHeaderStart, swapped) + 16;
      // calc next position
      newPosition = packetHeaderStart + oldPacketLengthInByte;

      if (selectedProtocols.includes("other")) {
        // slice with new size
        cutLengthInByte = Math.min(oldPacketLengthInByte, defaultCutLengthInByte);
        packetData = array.slice(packetHeaderStart, packetHeaderStart + cutLengthInByte);
        // modify PCAP packet header only in case payload is cut off
        if (cutPayload) {
          packetData = array.slice(packetHeaderStart, packetHeaderStart + cutLengthInByte);
          modifyPacketHeader(packetData, cutLengthInByte, swapped);
        }
      }
      // return sliced packet and position
      return [newPosition, packetData];
  }
}

export function modifyPacket_NG(
  array: Array<number>,
  packetBlockStart: number,
  defaultCutLengthInByte: number,
  swapped: boolean,
  selectedProtocols: string[],
  cutPayload: boolean
) {
  let protocol: any;
  let oldPacketLengthInByte = swapped
    ? byteArrayToDecSwapped(array, packetBlockStart + 4, packetBlockStart + 8)
    : byteArrayToDec(array, packetBlockStart + 4, packetBlockStart + 8);
  let newPosition = packetBlockStart + oldPacketLengthInByte;
  let packetData = null;
  let newPacketDataLength;

  // check if enhanced packet block: 6
  let blockType;
  if (swapped) blockType = byteArrayToDecSwapped(array, packetBlockStart, packetBlockStart + 4);
  else blockType = byteArrayToDec(array, packetBlockStart, packetBlockStart + 4);

  if (blockType !== 6) return [newPosition, packetData];

  // The packet data of the PCAPNG Enhanced Packet Block starts at byte 28
  // see: https://www.ietf.org/staging/draft-tuexen-opsawg-pcapng-02.html#section_epb
  const etherType = getEthernetType(array, packetBlockStart + 28);
  switch (etherType) {
    case "IPv4":
      // The IPv4 packet starts at byte 14 of the packet data.
      // Therefore add 28 bytes of the PCAPNG Enhanced Packet Block to get the IPv4 packet at byte 42.
      protocol = getTransportProtocol(array, packetBlockStart + 42, "IPv4", cutPayload);

      if (selectedProtocols.includes(protocol.protocol)) {
        newPacketDataLength = 14 + protocol.ipHeaderLength + protocol.protocolHeaderLength;
        packetData = modifyPacketBlock(
          array,
          packetBlockStart,
          oldPacketLengthInByte,
          newPacketDataLength,
          swapped
        );
      }
      return [newPosition, packetData];

      case "IPv6":
        // The IPv6 packet starts at byte 14 of the packet data.
        // Therefore add 28 bytes of the PCAPNG Enhanced Packet Block to get the IPv6 packet at byte 42.
        protocol = getTransportProtocol(array, packetBlockStart + 42, "IPv6", cutPayload);
  
        if (selectedProtocols.includes(protocol.protocol)) {
          newPacketDataLength = 14 + protocol.ipHeaderLength + protocol.protocolHeaderLength;
          packetData = modifyPacketBlock(
            array,
            packetBlockStart,
            oldPacketLengthInByte,
            newPacketDataLength,
            swapped
          );
        }
        return [newPosition, packetData];

    case "ARP":
      if (selectedProtocols.includes("ARP")) {
        newPacketDataLength = 64;
        packetData = modifyPacketBlock(
          array,
          packetBlockStart,
          oldPacketLengthInByte,
          newPacketDataLength,
          swapped
        );
      }
      return [newPosition, packetData];

    default:
      if (selectedProtocols.includes("other")) {
        newPacketDataLength = 72;
        packetData = modifyPacketBlock(
          array,
          packetBlockStart,
          oldPacketLengthInByte,
          newPacketDataLength,
          swapped
        );
      }
      return [newPosition, packetData];
  }
}

export function getProtocolAndPacketLength(
  array: Array<number>,
  packetHeaderStart: number,
  swapped: boolean,
  cutPayload: boolean
) {
  // The packet data of the PCAP Packet Record starts at byte 16
  // see: https://tools.ietf.org/id/draft-gharris-opsawg-pcap-00.html#name-packet-record
  const etherType = getEthernetType(array, packetHeaderStart + 16);

  let protocol: any;
  let packetLengthInByte;

  switch (etherType) {
    case "IPv4":
      // The IPv4 packet starts at byte 14 of the packet data.
      // Therefore add 16 bytes of the PCAP Packet Record to get the IPv4 packet at byte 30.
      protocol = getTransportProtocol(array, packetHeaderStart + 30, "IPv4", cutPayload);
      const ipv4PacketSrcDest = getIPv4SrcDest(array, packetHeaderStart + 30);

      packetLengthInByte = getPacket_incl_len(array, packetHeaderStart, swapped) + 16;
      return {
        length: packetLengthInByte,
        protocol: protocol.protocol,
        src: ipv4PacketSrcDest[0],
        dest: ipv4PacketSrcDest[1]
      };
    case "IPv6":
      // The IPv6 packet starts at byte 14 of the packet data.
      // Therefore add 16 bytes of the PCAP Packet Record to get the IPv4 packet at byte 30.
      protocol = getTransportProtocol(array, packetHeaderStart + 30, "IPv6", cutPayload);
      const ipv6PacketSrcDest = getIPv6SrcDest(array, packetHeaderStart + 30);

      packetLengthInByte = getPacket_incl_len(array, packetHeaderStart, swapped) + 16;
      return {
        length: packetLengthInByte,
        protocol: protocol.protocol,
        src: ipv6PacketSrcDest[0],
        dest: ipv6PacketSrcDest[1]
      };
    case "ARP":
      packetLengthInByte = getPacket_incl_len(array, packetHeaderStart, swapped) + 16;
      return { length: packetLengthInByte, protocol: "ARP" };
    default:
      packetLengthInByte = getPacket_incl_len(array, packetHeaderStart, swapped) + 16;
      return { length: packetLengthInByte, protocol: protocol ? protocol.protocol : etherType + ": currently unsupported." };
  }
}

export function getProtocolAndPacketLength_NG(
  array: Array<number>,
  packetBlockStart: number,
  swapped: boolean,
  cutPayload: boolean
) {
  const packetLengthInByte = swapped
    ? byteArrayToDecSwapped(array, packetBlockStart + 4, packetBlockStart + 8)
    : byteArrayToDec(array, packetBlockStart + 4, packetBlockStart + 8);

  // check if enhanced packet block: 6
  let blockType;
  if (swapped) blockType = byteArrayToDecSwapped(array, packetBlockStart, packetBlockStart + 4);
  else blockType = byteArrayToDec(array, packetBlockStart, packetBlockStart + 4);

  if (blockType === 6) {
    // The packet data of the PCAPNG Enhanced Packet Block starts at byte 28
    // see: https://www.ietf.org/staging/draft-tuexen-opsawg-pcapng-02.html#section_epb
    const etherType = getEthernetType(array, packetBlockStart + 28);
    let protocol: any;

    switch (etherType) {
      case "IPv4":
        // The IPv4 packet starts at byte 14 of the packet data.
        // Therefore add 28 bytes of the PCAPNG Enhanced Packet Block to get the IPv4 packet at byte 42.
        protocol = getTransportProtocol(array, packetBlockStart + 42, "IPv4", cutPayload);
        const ipv4PacketSrcDest = getIPv4SrcDest(array, packetBlockStart + 42);

        return {
          length: packetLengthInByte,
          protocol: protocol.protocol,
          src: ipv4PacketSrcDest[0],
          dest: ipv4PacketSrcDest[1]
        };

        case "IPv6":
        // The IPv6 packet starts at byte 14 of the packet data.
        // Therefore add 28 bytes of the PCAPNG Enhanced Packet Block to get the IPv4 packet at byte 42.
        protocol = getTransportProtocol(array, packetBlockStart + 42, "IPv6", cutPayload);
        const ipv6PacketSrcDest = getIPv6SrcDest(array, packetBlockStart + 42);

        return {
          length: packetLengthInByte,
          protocol: protocol.protocol,
          src: ipv6PacketSrcDest[0],
          dest: ipv6PacketSrcDest[1]
        };

      case "ARP":
        return { length: packetLengthInByte, protocol: "ARP" };
      default:
        return { length: packetLengthInByte, protocol: protocol ? protocol.protocol : etherType + ": currently unsupported." };
    }
  } else return { length: packetLengthInByte, protocol: "ng block not parsed" };
}

export function getTimestampInMillis(
  array: Array<number>,
  packetHeaderStart: number,
  swapped: boolean
) {
  let timestampSeconds;
  let timestampMicros;

  if (swapped) {
    timestampSeconds = byteArrayToDecSwapped(array, packetHeaderStart, packetHeaderStart + 4);
    timestampMicros = byteArrayToDecSwapped(array, packetHeaderStart + 4, packetHeaderStart + 8);
  } else {
    timestampSeconds = byteArrayToDec(array, packetHeaderStart, packetHeaderStart + 4);
    timestampMicros = byteArrayToDec(array, packetHeaderStart + 4, packetHeaderStart + 8);
  }

  return timestampSeconds * 1000 + Math.round(timestampMicros / 1000);
}

export function getTimestampInMillis_NG(
  array: Array<number>,
  paketBlockStart: number,
  swapped: boolean
): number {
  let timeMicro;

  if (swapped) {
    const timestampHigh32 = array.slice(paketBlockStart + 12, paketBlockStart + 16);
    const timestampLow32 = array.slice(paketBlockStart + 16, paketBlockStart + 20);
    const timestamp64 = timestampLow32.concat(timestampHigh32);
    timeMicro = byteArrayToDecSwapped(timestamp64, 0, 8);
  } else {
    timeMicro = byteArrayToDec(array, paketBlockStart + 12, paketBlockStart + 20);
  }

  return Math.round(timeMicro / 1000);
}

export function getGlobalHeaderParameters(hexHeader: string, byteHeader: number[]) {
  const notFoundReturn = { mode: GlobalHeaderMode.NOT_FOUND, start: -1, length: -1 };
  let headerLength: number = hexHeader.length;

  if (headerLength < 8) return notFoundReturn;

  for (let i = 0; i <= headerLength - 8; i++) {
    let substring = hexHeader.substring(i, i + 8);

    switch (substring) {
      case "a1b2c3d4":
        return { mode: GlobalHeaderMode.NORMAL, start: 0.5 * i, length: 24 };
      case "d4c3b2a1":
        return { mode: GlobalHeaderMode.SWAPPED, start: 0.5 * i, length: 24 };
      case "a1b23c4d":
        return { mode: GlobalHeaderMode.NANO_NORMAL, start: 0.5 * i, length: 24 };
      case "4d3cb2a1":
        return { mode: GlobalHeaderMode.NANO_SWAPPED, start: 0.5 * i, length: 24 };
      case "0a0d0d0a":
        let ngMagicNr = hexHeader.substring(i + 16, i + 24);

        switch (ngMagicNr) {
          case "1a2b3c4d":
            const ngOffsetNormal = getOffsetToFirstPacketBlock(byteHeader, 0.5 * i, false);
            if (ngOffsetNormal === -1) return notFoundReturn;
            else
              return { mode: GlobalHeaderMode.NG_NORMAL, start: 0.5 * i, length: ngOffsetNormal };
          case "4d3c2b1a":
            const ngOffsetSwapped = getOffsetToFirstPacketBlock(byteHeader, 0.5 * i, true);
            if (ngOffsetSwapped === -1) return notFoundReturn;
            else
              return { mode: GlobalHeaderMode.NG_SWAPPED, start: 0.5 * i, length: ngOffsetSwapped };
          default:
            return notFoundReturn;
        }
    }
  }

  return notFoundReturn;
}

function getOffsetToFirstPacketBlock(array: Array<number>, sectionStart: number, swapped: boolean) {
  let offset = sectionStart;

  while (offset < array.length) {
    let blockType;
    if (swapped) blockType = byteArrayToDecSwapped(array, offset, offset + 4);
    else blockType = byteArrayToDec(array, offset, offset + 4);

    // search only for 6: enhanced packet block (simple packet block: 3 does not support timestamps)
    if (blockType === 6) {
      return offset;
    } else {
      if (swapped) offset += byteArrayToDecSwapped(array, offset + 4, offset + 8);
      else offset += byteArrayToDec(array, offset + 4, offset + 8);
    }
  }

  return -1;
}

function getTimeResolutionFromIDB(
  array: Array<number>,
  potentialIDBStart: number,
  swapped: boolean
) {
  // check if IDB
  let blockType;
  if (swapped) blockType = byteArrayToDecSwapped(array, potentialIDBStart, potentialIDBStart + 4);
  else blockType = byteArrayToDec(array, potentialIDBStart, potentialIDBStart + 4);

  if (blockType === 1) {
    // find code 9
    let optionCode;
    let optionLength;
    let blockLength;
    if (swapped) {
      blockLength = byteArrayToDecSwapped(array, potentialIDBStart + 4, potentialIDBStart + 8);
      optionCode = byteArrayToDecSwapped(array, potentialIDBStart + 16, potentialIDBStart + 18);
      optionLength = byteArrayToDecSwapped(array, potentialIDBStart + 18, potentialIDBStart + 20);
    } else {
      blockLength = byteArrayToDec(array, potentialIDBStart + 4, potentialIDBStart + 8);
      optionCode = byteArrayToDec(array, potentialIDBStart + 16, potentialIDBStart + 18);
      optionLength = byteArrayToDec(array, potentialIDBStart + 18, potentialIDBStart + 20);
    }

    console.log(optionCode, optionLength);
    return null;
  } else return null;
}

function modifyGlobalHeaderSnaplen(
  array: Array<number>,
  headerStart: number,
  packetpacketLengthInByte: number
) {
  // TODO remove console spam
  let header = array.slice(headerStart, headerStart + 24);
  console.log("header:", header);

  let snaplen = array.slice(headerStart + 16, headerStart + 20);

  let modifiedSnaplenDec = 100;
  let modifiedSnaplen = decToByteArray(modifiedSnaplenDec, 4);
  console.log("replacing global header snaplen:", snaplen, "->", modifiedSnaplen);

  // replace
  for (let i = 0; i < 4; i++) {
    array[headerStart + 16 + i] = modifiedSnaplen[i];
  }

  let modifiedHeader = array.slice(headerStart, headerStart + 24);
  console.log("header:", modifiedHeader);
}

function modifyPacketHeader(array: Array<number>, cutLengthInByte: number, swapped: boolean) {
  let modified_incl_len = swapped
    ? decToByteArraySwapped(cutLengthInByte - 16, 4)
    : decToByteArray(cutLengthInByte - 16, 4);

  // replace
  for (let i = 0; i < 4; i++) {
    array[8 + i] = modified_incl_len[i];
  }
}

function modifyPacketBlock(
  array: Array<number>,
  packetBlockStart: number,
  oldPacketBlockLength: number,
  newCapturedPacketLength: number,
  swapped: boolean
) {
  const newCapturedPacketLengthPadded = numberToNext4x(newCapturedPacketLength);

  const capturedPacketLength = swapped
    ? byteArrayToDecSwapped(array, packetBlockStart + 20, packetBlockStart + 24)
    : byteArrayToDec(array, packetBlockStart + 20, packetBlockStart + 24);

  const oldCapturedPacketLengthPadded = numberToNext4x(capturedPacketLength);

  if (newCapturedPacketLengthPadded >= oldCapturedPacketLengthPadded) {
    return array.slice(packetBlockStart, packetBlockStart + oldPacketBlockLength);
  } else {
    // cut, apply changes in fields, re-append modified block total length at the end of packet block,
    let packetBlock = array.slice(
      packetBlockStart,
      packetBlockStart + 28 + newCapturedPacketLengthPadded
    );
    let newBlockLength = packetBlock.length + 4;
    let modifiedBlockTotalLength = swapped
      ? decToByteArraySwapped(newBlockLength, 4)
      : decToByteArray(newBlockLength, 4);

    let modifiedCapturedPacketLength = swapped
      ? decToByteArraySwapped(newCapturedPacketLength, 4)
      : decToByteArray(newCapturedPacketLength, 4);

    for (let i = 0; i < 4; i++) packetBlock[4 + i] = modifiedBlockTotalLength[i];

    for (let i = 0; i < 4; i++) packetBlock[20 + i] = modifiedCapturedPacketLength[i];

    return packetBlock.concat(modifiedBlockTotalLength);
  }
}

function getPacket_incl_len(array: Array<number>, packetHeaderStart: number, swapped: boolean) {
  return swapped
    ? byteArrayToDecSwapped(array, packetHeaderStart + 8, packetHeaderStart + 12)
    : byteArrayToDec(array, packetHeaderStart + 8, packetHeaderStart + 12);
}

function getEthernetType(array: Array<number>, ethernetHeaderStart: number) {
  const etherType = byteArrayToHex(array, ethernetHeaderStart + 12, ethernetHeaderStart + 14);

  // 0x0800 IP Internet Protocol (IPv4)
  // 0x0806 Address Resolution Protocol (ARP)
  // 0x8035 Reverse Address Resolution Protocol (RARP)
  // 0x809b AppleTalk (Ethertalk)
  // 0x80f3 Appletalk Address Resolution Protocol (AARP)
  // 0x8137 Novell IPX (alt)
  // 0x8138 Novell
  // 0x86DD Internet Protocol, Version 6 (IPv6)

  switch (etherType) {
    case "0800":
      return "IPv4";
    case "86dd":
      return "IPv6";
    case "0806":
      return "ARP";
    default:
      return "n/A";
  }
}

function getTransportProtocol(array: Array<number>, ipHeaderStart: number, version: String, cutPayload: boolean) {
  let protocol = 0;
  let ipHeaderLength = 0;
  
  if (version === "IPv4") {
    protocol = byteArrayToDec(array, ipHeaderStart + 9, ipHeaderStart + 10);
    ipHeaderLength = getIPv4HeaderLength(array, ipHeaderStart);
  }
  else if (version === "IPv6") {
    protocol = byteArrayToDec(array, ipHeaderStart + 6, ipHeaderStart + 7);
    ipHeaderLength = 40;
  }

  switch (protocol) {
    case 1:
      return { ipHeaderLength: ipHeaderLength, protocol: "ICMP", protocolHeaderLength: 8 };
    case 6:
      return {
        // If "Upload Payload of Data" is not selected the application protocol data is cut off.
        ipHeaderLength: ipHeaderLength,
        protocol: getTCPProtocol(array, ipHeaderStart + ipHeaderLength),
        protocolHeaderLength: (version === "IPv4")
        ? getIPv4PayloadLength(array, ipHeaderStart, ipHeaderLength, cutPayload)
        : getIPv6PayloadLength(array, ipHeaderStart, cutPayload)
      };
    case 17:
      return {
        ipHeaderLength: ipHeaderLength,
        protocol: getUDPProtocol(array, ipHeaderStart + ipHeaderLength),
        protocolHeaderLength: 8
      };
    default:
      const ret = {
        ipHeaderLength: ipHeaderLength,
        protocol: "IPv4/IPv6 payload protocol: other",
        protocolHeaderLength: 0
      };
      console.log("not registered IPv4/IPv6 payload protocol:", protocol.toString());
      return ret;
  }
}

function getTCPProtocol(array: Array<number>, tcpHeaderStart: number) {
  const srcPort = byteArrayToDec(array, tcpHeaderStart, tcpHeaderStart + 2);
  const dstPort = byteArrayToDec(array, tcpHeaderStart + 2, tcpHeaderStart + 4);

  let tcpPorts = new Map([
    [21, "FTP"],
    [53, "DNS"],
    [80, "HTTP"],
    [179, "BGP"],
    [443, "TLS"],
    [514, "Syslog"]
  ]);

  if (tcpPorts.has(srcPort)) {return tcpPorts.get(srcPort);}
  else if (tcpPorts.has(dstPort)) {return tcpPorts.get(dstPort);}
  else {
      const ret = "TCP: other";
      console.log("not registered TCP protocol: Src:", srcPort.toString(), "Dst:", dstPort.toString());
      return ret;
  }
}

function getUDPProtocol(array: Array<number>, udpHeaderStart: number) {
  const srcPort = byteArrayToDec(array, udpHeaderStart, udpHeaderStart + 2);
  const dstPort = byteArrayToDec(array, udpHeaderStart + 2, udpHeaderStart + 4);

  let udpPorts = new Map([
    [53, "DNS"],
    [514, "Syslog"]
  ]);

  if (udpPorts.has(srcPort)) {return udpPorts.get(srcPort);}
  else if (udpPorts.has(dstPort)) {return udpPorts.get(dstPort);}
  else {
    const ret = "UDP: other";
    console.log("not registered UDP protocol: Src:", srcPort.toString(), "Dst:", dstPort.toString());
    return ret;
  }
}

function getIPv4SrcDest(array: Array<number>, ipv4HeaderStart: number) {
  const src =
    byteArrayToDec(array, ipv4HeaderStart + 12, ipv4HeaderStart + 13).toString() +
    "." +
    byteArrayToDec(array, ipv4HeaderStart + 13, ipv4HeaderStart + 14).toString() +
    "." +
    byteArrayToDec(array, ipv4HeaderStart + 14, ipv4HeaderStart + 15).toString() +
    "." +
    byteArrayToDec(array, ipv4HeaderStart + 15, ipv4HeaderStart + 16).toString();

  const dest =
    byteArrayToDec(array, ipv4HeaderStart + 16, ipv4HeaderStart + 17).toString() +
    "." +
    byteArrayToDec(array, ipv4HeaderStart + 17, ipv4HeaderStart + 18).toString() +
    "." +
    byteArrayToDec(array, ipv4HeaderStart + 18, ipv4HeaderStart + 19).toString() +
    "." +
    byteArrayToDec(array, ipv4HeaderStart + 19, ipv4HeaderStart + 20).toString();

  return [src, dest];
}

function getIPv6SrcDest(array: Array<number>, ipv6HeaderStart: number) {
  const src =
    byteArrayToHex(array, ipv6HeaderStart + 8, ipv6HeaderStart + 10).toString() +
    ":" +
    byteArrayToHex(array, ipv6HeaderStart + 10, ipv6HeaderStart + 12).toString() +
    ":" +
    byteArrayToHex(array, ipv6HeaderStart + 12, ipv6HeaderStart + 14).toString() +
    ":" +
    byteArrayToHex(array, ipv6HeaderStart + 14, ipv6HeaderStart + 16).toString() +
    ":" +
    byteArrayToHex(array, ipv6HeaderStart + 16, ipv6HeaderStart + 18).toString() +
    ":" +
    byteArrayToHex(array, ipv6HeaderStart + 18, ipv6HeaderStart + 20).toString() +
    ":" +
    byteArrayToHex(array, ipv6HeaderStart + 20, ipv6HeaderStart + 22).toString() +
    ":" +
    byteArrayToHex(array, ipv6HeaderStart + 22, ipv6HeaderStart + 24).toString();

  const dest =
    byteArrayToHex(array, ipv6HeaderStart + 24, ipv6HeaderStart + 26).toString() +
    ":" +
    byteArrayToHex(array, ipv6HeaderStart + 26, ipv6HeaderStart + 28).toString() +
    ":" +
    byteArrayToHex(array, ipv6HeaderStart + 28, ipv6HeaderStart + 30).toString() +
    ":" +
    byteArrayToHex(array, ipv6HeaderStart + 30, ipv6HeaderStart + 32).toString() +
    ":" +
    byteArrayToHex(array, ipv6HeaderStart + 32, ipv6HeaderStart + 34).toString() +
    ":" +
    byteArrayToHex(array, ipv6HeaderStart + 34, ipv6HeaderStart + 36).toString() +
    ":" +
    byteArrayToHex(array, ipv6HeaderStart + 36, ipv6HeaderStart + 38).toString() +
    ":" +
    byteArrayToHex(array, ipv6HeaderStart + 38, ipv6HeaderStart + 40).toString();

  return [src, dest];
}

function getTCPHeaderLength(array: Array<number>, tcpHeaderStart: number) {
  const byte: number = array.slice(tcpHeaderStart + 12, tcpHeaderStart + 13)[0];

  // take first 4 bits of the byte and multiply by 4 to get header length in byte
  // TODO specify extra bytes, current: 24 extra bytes allow wireshark to recognize underlying HTTP protocols with and without TLS encryption
  return 4 * Math.floor(byte / 16) + 24;
}

function getIPv4HeaderLength(array: Array<number>, ipv4HeaderStart: number) {
  const byte: number = array.slice(ipv4HeaderStart, ipv4HeaderStart + 1)[0];

  // take last 4 bits of the byte and multiply by 4 to get header length in byte
  return 4 * (byte % 16);
}

// TODO: Check with swapped file.
function getIPv4PayloadLength(array: Array<number>, ipv4HeaderStart: number, ipv4HeaderLength: number, cutPayload: boolean) {
  const totalLength = byteArrayToDec(array, ipv4HeaderStart + 2, ipv4HeaderStart + 4);
  const payloadLength = totalLength - ipv4HeaderLength
  
  const tcpHeaderStart = ipv4HeaderStart + ipv4HeaderLength;
  // Get "Data offset" field in TCP header and shift right by 4 and multiply by 4 to receive TCP header length in bytes.
  const dataOffset = byteArrayToDec(array, tcpHeaderStart + 12, tcpHeaderStart + 13);
  const tcpHeaderLength = (dataOffset >> 4) * 4;
  
  return cutPayload
  ? tcpHeaderLength
  : payloadLength;
}

// TODO: Check with swapped file.
function getIPv6PayloadLength(array: Array<number>, ipv6HeaderStart: number, cutPayload: boolean) {
  const payloadLength = byteArrayToDec(array, ipv6HeaderStart + 4, ipv6HeaderStart + 6);
  const tcpHeaderStart = ipv6HeaderStart + 40;
  // Get "Data offset" field in TCP header and shift right by 4 and multiply by 4 to receive TCP header length in bytes.
  const dataOffset = byteArrayToDec(array, tcpHeaderStart + 12, tcpHeaderStart + 13);
  const tcpHeaderLength = (dataOffset >> 4) * 4;
  
  return cutPayload
  ? tcpHeaderLength
  : payloadLength;
}

export function byteArrayToHex(array: Array<number>, start: number, end: number) {
  let result = "",
    h = "0123456789abcdef";

  for (let i = start; i < end; i++) {
    result += h[array[i] >> 4] + h[array[i] & 15];
  }
  return result;
}

function byteArrayToDec(array: Array<number>, start: number, end: number) {
  let result = 0;
  for (let i = start; i < end; i++) {
    result = 256 * result + array[i];
  }
  return result;
}

function byteArrayToDecSwapped(array: Array<number>, start: number, end: number) {
  let result = 0;
  for (let i = end - 1; i >= start; i--) {
    result = 256 * result + array[i];
  }
  return result;
}

function decToByteArray(dec: number, length: number) {
  let array = [];
  let n = dec;
  let base: number = 256;

  while (n >= base) {
    array.unshift(n % base);
    n = Math.floor(n / base);
  }

  array.unshift(n);

  while (array.length < length) {
    array.unshift(0);
  }

  return array;
}

function decToByteArraySwapped(dec: number, length: number) {
  let array = [];
  let n = dec;
  let base: number = 256;

  while (n >= base) {
    array.push(n % base);
    n = Math.floor(n / base);
  }

  array.push(n);

  while (array.length < length) {
    array.push(0);
  }

  return array;
}

function numberToNext4x(n: number) {
  let rest = n % 4;
  return rest === 0 ? n : n + (4 - rest);
}
