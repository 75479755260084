import * as PIXI from "pixi.js";

// Visual Circle over Internal Nodes
export default class ClusterRing extends PIXI.Graphics {
  constructor(graph) {
    super();

    this.graph = graph;
    this.radius = graph.width / 4;
    this.center = {
      x: 0,
      y: 0
    };
  }

  // Recalculates circle dimensions and position and redraws
  update() {
    this.clear();

    if (this.graph.clusterInternal && this.graph.mode === "ip") {
      const [minX, maxX, minY, maxY, radius] = this.clusterRingSettings();

      this.lineStyle(2, parseInt(Number("0x" + this.graph.theme.internalNode.substr(1))), 0.3);
      this.drawCircle(0, 0, radius + 3);
      this.x = (maxX + minX) / 2;
      this.y = (maxY + minY) / 2;
    }
  }

  // Calculation of dimensions and position
  clusterRingSettings() {
    const internal = this.graph.nodes.filter(
      (elem) =>
        elem.internal &&
        (!this.graph.nodes ||
          !this.graph.nodes.getNode(elem.value) ||
          this.graph.nodes.getNode(elem.value).visible)
    );
    const minX = Math.min(...internal.map((elem) => elem.x - elem.calcNodeRadius()));
    const maxX = Math.max(...internal.map((elem) => elem.x + elem.calcNodeRadius()));
    const minY = Math.min(...internal.map((elem) => elem.y - elem.calcNodeRadius()));
    const maxY = Math.max(...internal.map((elem) => elem.y + elem.calcNodeRadius()));
    //const maxRad = Math.max(...internal.map(elem => this.nodeMap.get(elem.value).gfx.calcNodeRadius()))

    const radius = Math.max(
      ...internal.map(
        (elem) =>
          Math.sqrt(
            Math.pow(elem.x - (maxX + minX) / 2, 2) + Math.pow(elem.y - (maxY + minY) / 2, 2)
          ) + elem.calcNodeRadius()
      )
    );

    this.radius = radius;
    this.center.x = (maxX + minX) / 2;
    this.center.y = (maxY + minY) / 2;

    return [minX, maxX, minY, maxY, radius];
  }
}
