import * as PIXI from "pixi.js";
import Label from "./Label";

export default class Node extends PIXI.Graphics {
  constructor(graph, nodeData, callContextMenu, theme) {
    super();

    this.x = nodeData.x;
    this.y = nodeData.y;
    this.connectivity = nodeData.connectivity;
    this.value = nodeData.value;
    this.internal = nodeData.internal;
    this.graph = graph;
    this.callContextMenu = callContextMenu;
    this.theme = theme;

    this.label = new Label(this, this.graph);

    this.init();
  }

  // Initializes the node with all its PIXI related properties (for interaction)
  init() {
    this.interactive = true;
    this.buttonMode = true;
    this.hitArea = new PIXI.Circle(0, 0, this.calcNodeRadius());

    this.on("mouseover", this.focus);
    this.on("mouseout", this.unfocus);

    this.on("pointerdown", onDragStart)
      .on("pointerup", onDragEnd)
      .on("pointerupoutside", onDragEnd)
      .on("pointermove", onDragMove)
      .on("rightclick", (e) => {
        this.callContextMenu(e.data.originalEvent.clientX, e.data.originalEvent.clientY, this);
        e.stopPropagation();
      });

    function onDragStart(event) {
      this.data = event.data;
      this.dragging = true;
      event.stopPropagation();
    }

    function onDragMove() {
      if (this.dragging) {
        const newPosition = this.data.getLocalPosition(this.parent);
        this.x = newPosition.x;
        this.y = newPosition.y;
        this.label.updatePosition();
        this.graph.clusterRing.update();
        this.graph.links.redrawLinks();
      }
    }

    function onDragEnd() {
      this.dragging = false;
      this.data = null;
      this.graph.savePositions();
    }

    this.redraw();
  }

  // Redraws the node with its correct color
  redraw() {
    const getColor = () => {
      if (this.graph.mode === "ip") {
        if (
          this.graph.filterState.includeFilter.destIP.includes(this.value) ||
          this.graph.filterState.includeFilter.srcIP.includes(this.value)
        )
          return this.graph.theme.palette.secondary.main;
        else return this.internal ? this.theme.internalNode : "#000000";
      } else {
        if (
          this.graph.filterState.includeFilter.destPort.includes(this.value) ||
          this.graph.filterState.includeFilter.srcPort.includes(this.value)
        )
          return this.graph.theme.palette.secondary.main;
        else return "#000000";
      }
    };

    this.clear();

    this.beginFill(parseInt(Number("0x" + getColor().substr(1))));
    this.drawCircle(0, 0, this.calcNodeRadius());
    this.endFill();

    this.label.updateLabelText();
  }

  // Returns the node radius based on its connectivity
  calcNodeRadius() {
    return (
      2 *
      (3 +
        7 *
          ((this.connectivity - this.graph.minConnectivity) /
            (this.graph.maxConnectivity - this.graph.minConnectivity)))
    );
  }

  // Sets the postion of the node and its attached label
  setPosition(x, y) {
    this.x = x;
    this.y = y;
    this.label.updatePosition();
  }

  // Expand/show all adjacent neighbors of the node
  expandAllNeighbors() {
    this.getAdjacentNodes().forEach((node) => {
      this.graph.nodes.getNode(node.value).visible = true;
    });
    this.graph.links.redrawLinks();
    this.graph.clusterRing.update();
  }

  // Hides/collapses the node and all its solo neighbors
  hideNode() {
    this.getAdjacentNodes().forEach((node) => {
      if (this.getAdjacentNodes(node.value).filter((n) => n.visible).length === 1)
        node.visible = false;
    });
    this.visible = false;
    this.graph.links.redrawLinks();
    this.graph.clusterRing.update();
  }

  // Redraws the node (in case of color change) and returns whether it's in any selection
  checkForSelection() {
    this.redraw();

    return (
      this.graph.filterState.includeFilter.destIP.includes(this.value) ||
      this.graph.filterState.includeFilter.srcIP.includes(this.value) ||
      this.graph.filterState.includeFilter.destPort.includes(this.value) ||
      this.graph.filterState.includeFilter.srcPort.includes(this.value)
    );
  }

  // Visually focus on node and neigbors when hovering over node
  focus() {
    this.graph.nodeLayer.children.forEach((child) => (child.alpha = 0.1));
    this.graph.linkLayer.children.forEach((child) => (child.alpha = 0.1));

    this.getAdjacentNodes().forEach((node) => {
      if (!node.visible) {
        node.visible = true;
        node.alpha = 0.1;
        node.label.alpha = 0.1;
      } else {
        node.alpha = 1;
        node.label.alpha = 1;
      }
    });

    this.alpha = 1;
    this.label.alpha = 1;

    this.graph.links.redrawLinks();
    this.graph.clusterRing.update();
  }

  getAdjacentLinks(nodeName = this.value) {
    return this.graph.links.filter(
      (link) => link.source.value === nodeName || link.target.value === nodeName
    );
  }

  // Returns an array of all other nodes with connections to
  getAdjacentNodes(nodeName = this.value) {
    return Array.from(
      new Set(
        this.getAdjacentLinks(nodeName).map((link) =>
          link.source.value === nodeName
            ? this.graph.nodes.getNode(link.target.value)
            : this.graph.nodes.getNode(link.source.value)
        )
      )
    );
  }

  // Returns the nodes volume
  getVolume() {
    return this.getAdjacentLinks().reduce((acc, link) => acc + link.volume, 0);
  }

  // Returns the nodes degree
  getDegree() {
    return this.getAdjacentNodes().length;
  }

  // Unfocuses graph when hovering away from node
  unfocus() {
    this.getAdjacentNodes().forEach((node) => {
      if (node.alpha === 0.1) {
        node.visible = false;
      }
    });

    this.graph.links.redrawLinks();
    this.graph.clusterRing.update();

    this.graph.nodeLayer.children.forEach((child) => (child.alpha = 1));
    this.graph.links.forEach((link) => (link.alpha = 0.6));
    this.graph.labelLayer.children.forEach((child) => (child.alpha = 0));
  }

  // Updates the nodes and its labels position
  updatePosition(x, y) {
    this.x = x;
    this.y = y;
    this.label.updatePosition();
  }
}
