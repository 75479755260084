import Visualization from "../../../../utils/visualization";

// require('./style.css')
import React from "react";
import * as d3 from "d3";
import {FilterType} from "@store/filter/FilterStatus";
import {FilterAction, FilterField} from "@store/filter/types";
import {bytes2String} from "../../../../utils/HelperFunctions";
import d3Tip from "d3-tip";

export class TimeLineChart extends Visualization {
  constructor(node, parentProps, data, options) {
    super(node, parentProps, data, options);
  }

  getDefaultOptions() {
    return {
      padding: {top: 7, right: 12, bottom: 15, left: 55, between: 25}
    };
  }

  init() {
    const {padding} = this.options;
    this.theme = this.parentProps.theme;
    this.colors = {
      in: this.theme.barColors.incoming,
      out: this.theme.barColors.outgoing,
      intern: this.theme.barColors.internal
    };
    this.keys = ["in", "out", "intern"];
    this.maxTime = 0;
    this.minTime = Number.MAX_VALUE;
    this.noData = true;

    this.width = this.width - padding.left - padding.right;
    this.heightFocus = (this.height - padding.bottom - padding.top - padding.between) * 0.8;
    this.heightContext = (this.height - padding.bottom - padding.top - padding.between) * 0.2;

    this.svg = d3.select(this.node).append("svg").style("width", "100%").style("height", "100%");

    this.focus = this.svg.append("g");
    this.focus
      .attr("class", "focus")
      .attr("height", this.heightFocus)
      .attr("width", this.width)
      .attr("transform", "translate(" + padding.left + "," + padding.top + ")");

    this.context = this.svg.append("g");
    this.context
      .attr("class", "overview")
      .attr("width", this.width)
      .attr("height", this.heightContext)
      .attr(
        "transform",
        "translate(" + padding.left + "," + (padding.top + this.heightFocus + padding.between) + ")"
      );

    this.stackedContext = this.context.append("g");
    this.stackedFocus = this.focus.append("g");
    this.stackedBrushInterior = this.context.append("g");

    // SCALES
    this.xScaleFocus = d3.scaleTime().range([0, this.width]);
    this.xScaleContext = d3.scaleTime().range([0, this.width]);
    this.yScaleFocus = d3.scaleLinear().range([this.heightFocus, 0]);
    this.yScaleContext = d3.scaleLinear().range([this.heightContext, 0]);
    this.yScaleBrushInterior = d3.scaleLinear().range([this.heightContext, 0]);

    // AXIS
    let formatMillisecond = d3.timeFormat(".%L"),
      formatSecond = d3.timeFormat(":%S"),
      formatMinute = d3.timeFormat("%H:%M"),
      formatHour = d3.timeFormat("%H:%M"),
      formatDay = d3.timeFormat("%a %d"),
      formatWeek = d3.timeFormat("%b %d"),
      formatMonth = d3.timeFormat("%B"),
      formatYear = d3.timeFormat("%Y");

    // Define filter conditions
    function multiFormat(date) {
      return (d3.timeSecond(date) < date
        ? formatMillisecond
        : d3.timeMinute(date) < date
          ? formatSecond
          : d3.timeHour(date) < date
            ? formatMinute
            : d3.timeDay(date) < date
              ? formatHour
              : d3.timeMonth(date) < date
                ? d3.timeWeek(date) < date
                  ? formatDay
                  : formatWeek
                : d3.timeYear(date) < date
                  ? formatMonth
                  : formatYear)(date);
    }

    this.xAxisFocus = d3.axisBottom(this.xScaleFocus).tickFormat(multiFormat);
    this.xAxisContext = d3.axisBottom(this.xScaleContext).ticks(0);
    this.yAxis = d3.axisLeft(this.yScaleFocus).ticks(5).tickFormat(d3.format(".2s"));

    this.focusClipPath = this.focus
      .append("clipPath")
      .attr("id", "clip")
      .append("rect")
      .attr("width", this.width)
      .attr("height", this.heightFocus);

    this.clipBrush = this.context
      .append("clipPath")
      .attr("id", "clipBrush")
      .append("rect")
      .attr("height", this.heightContext)
      .attr("width", this.width);

    let maskBrush = this.context.append("clipPath").attr("id", "maskBrush");

    this.clipBeforeBrush = maskBrush
      .append("rect")
      .attr("height", this.heightContext)
      .attr("width", 0);
    this.clipAfterBrush = maskBrush
      .append("rect")
      .attr("height", this.heightContext)
      .attr("width", 0);

    // this.zoom = d3.zoom()
    //   .scaleExtent([1, Infinity])
    //   .translateExtent([[0, 0], [this.width, this.heightFocus]])
    //   .extent([[0, 0], [this.width, this.heightFocus]])
    //   .on('zoom', this.zoomMove.bind(this))
    //   .on('end', this.zoomEnd.bind(this))

    this.brush = d3.brushX();
    this.brush
      .extent([
        [0, 0],
        [this.width, this.heightContext]
      ])
      .on("brush", this.brushMove.bind(this))
      .on("end", this.brushEnd.bind(this));

    this.focusXAxis = this.stackedFocus
      .append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + this.heightFocus + ")");

    this.focusYAxis = this.stackedFocus
      .append("g")
      .attr("class", "y axis")
      .attr("transform", "translate(-1,0)"); // so bars dont cover yaxis

    this.contextXAxis = this.context
      .append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + this.heightContext + ")");

    this.brushContainer = this.context
      .append("g")
      .attr("class", "brush")
      .attr("height", this.heightContext)
      .attr("width", this.width);
    this.brushContainer.call(this.brush);

    this.context
      .select(".brush")
      .append("text")
      .text("local scale!")
      .attr("fill", this.theme.palette.text.primary)
      .attr("text-anchor", "middle")
      .attr("x", this.width / 2)
      .attr("dy", "1em")
      .style("font-size", 10);

    // this.aggtext = this.svg.append('g')
    // this.aggtext.append('text')
    //   .attr('x', (this.width))
    //   .attr('y', (this.heightFocus / 10))
    //   .attr('fill', this.theme.palette.text.primary)
    //   .attr('text-anchor', 'left')
    //   .style('font-size', '16px')
    //   .text('test');

    // this.zoomrect = this.svg.append('rect')
    //   .attr('class', 'zoom')
    //   .attr('width', this.width)
    //   .attr('height', this.heightFocus)
    //   .attr('cursor', 'move')
    //   .attr('fill', 'none')
    //   .attr('pointer-events', 'all')
    //   .attr('transform', 'translate(' + padding.left + ',' + padding.top + ')');

    this.focusYAxis.call(this.yAxis);
    this.focusXAxis.call(this.xAxisFocus);
    this.contextXAxis.call(this.xAxisContext);
    // this.zoomrect.call(this.zoom);

    this.tip = d3Tip()
      .attr("class", "d3-tip")
      // .offset([0, 10])
      .direction("n")
      .html((d, i, data) => {
        let formatTime = d3.timeFormat("%H:%M:%S.%L - %d.%m.%Y");
        return (
          "<strong>Time: " +
          formatTime(new Date(d.data.time)) +
          "</strong><br/> " +
          " in: <span style='color:" +
          this.colors.in +
          "'>" +
          bytes2String(d.data.in) +
          "</span>" +
          " out: <span style='color:" +
          this.colors.out +
          "'>" +
          bytes2String(d.data.out) +
          "</span>" +
          " intern: <span style='color:" +
          this.colors.intern +
          "'>" +
          bytes2String(d.data.intern) +
          "</span>"
        );
      });
    this.svg.call(this.tip);
  }

  updateData(formattedData) {
    // split in context and focus update
    throw new Error("use updateFocusData or updateContextData");
  }

  updateFocusData(data, bucketSize, filterState) {
    // if(!data || data.length < 1) return;
    this.bucketSizeFocus = bucketSize;
    this.filterState = filterState;
    let extent;
    extent = d3.extent(data, function (d) {
      return d.time;
    });
    extent[1] = extent[1] + bucketSize;

    if (filterState.includeFilter.startTime !== -1 || filterState.includeFilter.endTime !== -1) {
      extent = [
        filterState.includeFilter.startTime === -1
          ? this.minTime
          : filterState.includeFilter.startTime,
        filterState.includeFilter.endTime === -1
          ? this.maxTime
          : filterState.includeFilter.endTime
      ];
    } else {
      // async case where context is earlier updated than focus if user made no interactions
      if (extent[1] < this.maxTime || extent[0] > this.minTime) {
        extent = [this.minTime, this.maxTime];
      } // else leave extent be
    }

    let xDom = this.xScaleFocus.domain(extent);
    this.xDomFocus = xDom;
    let maxVolume = d3.max(data, function (d) {
      return d.in + d.out + d.intern;
    });
    let yDom = this.yScaleFocus.domain([0, maxVolume]).nice();
    this.yDomFocus = yDom;
    this.xAxisFocus.scale(xDom);
    this.yAxis.scale(yDom);

    this.stackedFocus.select(".x").transition().duration(500).call(this.xAxisFocus);

    this.stackedFocus.select(".y").transition().duration(500).call(this.yAxis);

    let calcWidth = xDom(extent[0] + bucketSize); // may be NaN if extent[0] === Number.MAX_VALUE

    let colors = this.colors;

    let stack = d3.stack().keys(this.keys).order(d3.stackOrderNone).offset(d3.stackOffsetNone);

    let stackedFocus = this.stackedFocus;

    this.keys.forEach((key, key_index) => {
      let bar = stackedFocus.selectAll(".bar-" + key).data(stack(data)[key_index], function (d) {
        return d.data.time + "-" + key;
      });

      let numOldElements = 0;
      if (bar._exit[0].length < bar._enter[0].length) {
        // less bars exit than enter --> regular update --> only animate the new data
        numOldElements = bar._exit[0].length;
      }

      bar
        .exit()
        .transition()
        .duration(500)
        .delay(function (d, i) {
          return Math.floor(i / 10) * 50;
        })
        .attr("y", yDom(0))
        .attr("height", 0)
        .remove();

      // modifiy existing bars
      bar
        .transition()
        .duration(500)
        .delay(function (d, i) {
          if (i > numOldElements) return (i - numOldElements) * 50;
          else return 0;
        })
        .attr("height", function (d) {
          return yDom(d[0]) - yDom(d[1]);
        })
        .attr("y", function (d) {
          return yDom(d[1]);
        })
        .attr("x", function (d) {
          return xDom(d.data.time);
        })
        .attr("width", calcWidth);

      // modifiy new bars
      bar
        .enter()
        .append("rect")
        .attr("class", "bar bar-" + key)
        .attr("x", function (d) {
          return xDom(d.data.time);
        })
        .attr("width", calcWidth)
        .attr("clip-path", "url(#clip)")
        .attr("fill", colors[key])
        .attr("y", yDom(0))
        .attr("height", 0)
        .on("mouseover", (d, i, n) => this.tip.show(d, i, data, n[i]))
        .on("mouseout", this.tip.hide)
        .on("click", (d) => this.clickFocusBar(d, bucketSize))
        .transition()
        .duration(500)
        .delay(function (d, i) {
          if (i > numOldElements) return (i - numOldElements) * 50;
          else return 0;
        })
        .attr("y", function (d) {
          return yDom(d[1]);
        })
        .attr("height", function (d) {
          return yDom(d[0]) - yDom(d[1]);
        });
    });
  }

  updateAxis(bool) {
    if (bool) {
      this.yAxis = d3.axisLeft(this.yScaleFocus).ticks(5).tickFormat(bytes2String);
    } else {
      this.yAxis = d3.axisLeft(this.yScaleFocus).ticks(5).tickFormat(d3.format(".2s"));
    }
  }

  updateBrushInterior(data, bucketSize) {
    let maxVolume = d3.max(data, function (d) {
      return d.in + d.out + d.intern;
    });
    let yDom = this.yScaleBrushInterior.domain([0, maxVolume]).nice();
    this.yDomBrushInterior = yDom;

    let extent = [];
    let xDom;
    if (!this.xDomContext) {
      extent = d3.extent(data, function (d) {
        return d.time;
      });
      xDom = this.xScaleContext.domain(extent);
    } else {
      extent = d3.extent(data, function (d) {
        return d.time;
      });
      this.maxTime = extent[1] + bucketSize > this.maxTime ? extent[1] + bucketSize : this.maxTime;
      this.minTime = extent[0] < this.minTime ? extent[0] : this.minTime;
      extent[0] = this.minTime;
      extent[1] = this.maxTime;
      xDom = this.xScaleContext.domain(extent);
    }
    this.xDomBrushInterior = xDom;

    let calcWidth = xDom(extent[0] + bucketSize);

    let colors = this.colors;

    let stack = d3.stack().keys(this.keys).order(d3.stackOrderNone).offset(d3.stackOffsetNone);
    let stackedBrushInterior = this.stackedBrushInterior;

    this.keys.forEach((key, key_index) => {
      let bar = stackedBrushInterior
        .selectAll(".bar-" + key)
        .data(stack(data)[key_index], function (d) {
          return d.data.time + "-" + key;
        });

      let numOldElements = 0;
      if (bar._exit[0].length < bar._enter[0].length) {
        // less bars exit than enter --> regular update --> only animate the new data
        numOldElements = bar._exit[0].length;
      }

      bar
        .exit()
        .transition()
        .duration(500)
        .delay(function (d, i) {
          return Math.floor(i / 10) * 50;
        })
        .attr("y", yDom(0))
        .attr("height", 0)
        .remove();

      // modifiy existing bars
      bar
        .transition()
        .duration(500)
        .delay(function (d, i) {
          if (i > numOldElements) return (i - numOldElements) * 50;
          else return 0;
        })
        .attr("height", function (d) {
          return yDom(d[0]) - yDom(d[1]);
        })
        .attr("y", function (d) {
          return yDom(d[1]);
        })
        .attr("x", function (d) {
          return xDom(d.data.time);
        })
        .attr("width", calcWidth);

      // modifiy new bars
      bar
        .enter()
        .append("rect")
        .attr("class", "bar bar-" + key)
        .attr("x", function (d) {
          return xDom(d.data.time);
        })
        .attr("width", calcWidth)
        .attr("fill", colors[key])
        .attr("y", yDom(0))
        .attr("clip-path", "url(#clipBrush)")
        .attr("height", 0)
        .transition()
        .duration(500)
        .delay(function (d, i) {
          if (i > numOldElements) return (i - numOldElements) * 50;
          else return 0;
        })
        .attr("y", function (d) {
          return yDom(d[1]);
        })
        .attr("height", function (d) {
          return yDom(d[0]) - yDom(d[1]);
        });
    });
  }

  updateContextData(data, bucketSize, filterState) {
    if (data.length > 0) {
      this.noData = false;
    }
    this.filterState = filterState;
    this.bucketSizeContext = bucketSize;

    let extent = d3.extent(data, function (d) {
      return d.time;
    });
    this.maxTime = extent[1] + bucketSize > this.maxTime ? extent[1] + bucketSize : this.maxTime;
    this.minTime = extent[0] < this.minTime ? extent[0] : this.minTime;
    extent[0] = this.minTime;
    extent[1] = this.maxTime;

    let xDom = this.xScaleContext.domain(extent);
    this.xDomContext = xDom;

    let maxVolume = d3.max(data, function (d) {
      return d.in + d.out + d.intern;
    });
    let yDom = this.yScaleContext.domain([0, maxVolume]).nice();
    this.yDomContext = yDom;

    let calcWidth = xDom(extent[0] + bucketSize);

    let colors = this.colors;

    let stack = d3.stack().keys(this.keys).order(d3.stackOrderNone).offset(d3.stackOffsetNone);
    let stackedContext = this.stackedContext;

    this.keys.forEach((key, key_index) => {
      let bar = stackedContext.selectAll(".bar-" + key).data(stack(data)[key_index], function (d) {
        return d.data.time + "-" + key;
      });

      let numOldElements = 0;
      if (bar._exit[0].length < bar._enter[0].length) {
        // less bars exit than enter --> regular update --> only animate the new data
        numOldElements = bar._exit[0].length;
      }

      bar
        .exit()
        .transition()
        .duration(500)
        .delay(function (d, i) {
          return Math.floor(i / 10) * 50;
        })
        .attr("y", yDom(0))
        .attr("height", 0)
        .remove();

      // modifiy existing bars
      bar
        .transition()
        .duration(500)
        .delay(function (d, i) {
          if (i > numOldElements) return (i - numOldElements) * 50;
          else return 0;
        })
        // .attr("opacity", (d) => {
        //   return (d.data.time >= filterState.includeFilter.startTime && d.data.time <= filterState.includeFilter.endTime)? 0 : 1;
        // })
        .attr("height", function (d) {
          return yDom(d[0]) - yDom(d[1]);
        })
        .attr("y", function (d) {
          return yDom(d[1]);
        })
        .attr("x", function (d) {
          return xDom(d.data.time);
        })
        .attr("width", calcWidth);

      // modifiy new bars
      bar
        .enter()
        .append("rect")
        .attr("class", "bar bar-" + key)
        .attr("x", function (d) {
          return xDom(d.data.time);
        })
        .attr("width", calcWidth)
        .attr("fill", colors[key])
        .attr("y", yDom(0))
        .attr("height", 0)
        .attr("clip-path", "url(#maskBrush)")
        .transition()
        .duration(500)
        .delay(function (d, i) {
          if (i > numOldElements) return (i - numOldElements) * 50;
          else return 0;
        })
        .attr("y", function (d) {
          return yDom(d[1]);
        })
        .attr("height", function (d) {
          return yDom(d[0]) - yDom(d[1]);
        });
    });
  }

  updateDimensions(node) {
    const {padding} = this.options;
    if (node != null) {
      this.width = node.clientWidth;
      this.height = node.clientHeight;
    }
    this.width = this.width - padding.left - padding.right;
    this.heightFocus = (this.height - padding.bottom - padding.top - padding.between) * 0.8;
    this.heightContext = (this.height - padding.bottom - padding.top - padding.between) * 0.2;

    this.focus.attr("height", this.heightFocus).attr("width", this.width);

    this.context
      .attr("width", this.width)
      .attr("height", this.heightContext)
      .attr(
        "transform",
        "translate(" + padding.left + "," + (padding.top + this.heightFocus + padding.between) + ")"
      );

    // SCALES
    this.xScaleFocus.range([0, this.width]);
    this.xScaleContext.range([0, this.width]);
    this.yScaleFocus.range([this.heightFocus, 0]);
    this.yScaleContext.range([this.heightContext, 0]);
    this.yScaleBrushInterior.range([this.heightContext, 0]);

    this.focusClipPath.attr("width", this.width).attr("height", this.heightFocus);
    this.clipBrush.attr("height", this.heightContext).attr("width", this.width);
    this.clipBeforeBrush.attr("height", this.heightContext);
    this.clipAfterBrush.attr("height", this.heightContext);

    this.focusXAxis.attr("transform", "translate(0," + this.heightFocus + ")");

    this.contextXAxis.attr("transform", "translate(0," + this.heightContext + ")");

    this.focusYAxis.transition().duration(500).call(this.yAxis);
    this.focusXAxis.transition().duration(500).call(this.xAxisFocus);
    this.contextXAxis.transition().duration(500).call(this.xAxisContext);

    // update brush
    if (this.noData || this.filterState.includeFilter.startTime === -1 && this.filterState.includeFilter.endTime === -1) {
      this.brush.extent([
        [0, 0],
        [this.width, this.heightContext]
      ]);
      this.brushContainer.call(this.brush);
      this.brush.move(this.brushContainer, this.xScaleFocus.range());
    } else {
      this.brush.extent([
        [0, 0],
        [this.width, this.heightContext]
      ]);
      this.brushContainer.call(this.brush);
      this.brush.move(this.brushContainer, [
        this.xDomContext(this.filterState.includeFilter.startTime),
        this.xDomContext(this.filterState.includeFilter.endTime)
      ]);
    }

    // update bars
    if (!this.noData) {
      let startTime =
        this.filterState.includeFilter.startTime === -1
          ? this.minTime
          : this.filterState.includeFilter.startTime;
      let focusBarWidth = this.xDomFocus(startTime + this.bucketSizeFocus);
      this.stackedFocus
        .selectAll(".bar")
        .attr("height", (d) => {
          return this.yDomFocus(d[0]) - this.yDomFocus(d[1]);
        })
        .attr("y", (d) => {
          return this.yDomFocus(d[1]);
        })
        .attr("x", (d) => {
          return this.xDomFocus(d.data.time);
        })
        .attr("width", focusBarWidth);

      let contextBarWidth = this.xDomContext(this.minTime + this.bucketSizeContext);
      this.stackedContext
        .selectAll(".bar")
        .attr("height", (d) => {
          return this.yDomContext(d[0]) - this.yDomContext(d[1]);
        })
        .attr("y", (d) => {
          return this.yDomContext(d[1]);
        })
        .attr("x", (d) => {
          return this.xDomContext(d.data.time);
        })
        .attr("width", contextBarWidth);

      let brushBarWidth = this.xDomBrushInterior(this.minTime + this.bucketSizeFocus);
      this.stackedBrushInterior
        .selectAll(".bar")
        .attr("height", (d) => {
          return this.yDomBrushInterior(d[0]) - this.yDomBrushInterior(d[1]);
        })
        .attr("y", (d) => {
          return this.yDomBrushInterior(d[1]);
        })
        .attr("x", (d) => {
          return this.xDomBrushInterior(d.data.time);
        })
        .attr("width", brushBarWidth);
    }
  }

  updateBrush(filterState) {
    if (this.noData || this.userIsMovingBrush) return;

    let extent = [
      filterState.includeFilter.startTime === -1
        ? this.minTime
        : filterState.includeFilter.startTime,
      filterState.includeFilter.endTime === -1
        ? this.maxTime
        : filterState.includeFilter.endTime
    ];

    let brushRange = [this.xDomContext(extent[0]), this.xDomContext(extent[1])];
    this.context.select(".brush").transition().duration(500).call(this.brush.move, brushRange);
  }

  updateTheme(theme) {
    this.theme = theme;
    this.colors = {
      in: this.theme.barColors.incoming,
      out: this.theme.barColors.outgoing,
      intern: this.theme.barColors.internal
    };
    this.svg.selectAll(".bar-" + "in").attr("fill", this.colors.in);
    this.svg.selectAll(".bar-" + "out").attr("fill", this.colors.out);
    this.svg.selectAll(".bar-" + "intern").attr("fill", this.colors.intern);

    this.svg.selectAll("text").attr("fill", this.theme.palette.text.primary);

    // this.svg.selectAll('.axis path').style('stroke', this.theme.palette.text.primary)
    // this.svg.selectAll('text').attr('fill', this.theme.palette.text.primary)
    // this.focusXAxis.selectAll('text').style('color', this.theme.palette.text.primary)
    // this.focusYAxis.selectAll('text').style('color', this.theme.palette.text.primary)
  }

  updateTime(startTime, endTime) {
    this.parentProps.updateFilter(
      FilterType.include,
      FilterAction.set,
      FilterField.startTime,
      startTime.toString()
    );
    this.parentProps.updateFilter(
      FilterType.include,
      FilterAction.set,
      FilterField.endTime,
      endTime.toString()
    );
  }

  clickFocusBar(d, bucketSize) {
    this.updateTime(d.data.time, d.data.time + bucketSize - 1);
  }

  brushMove() {
    const s = d3.event.selection;
    this.context
      .select(".brush")
      .selectAll("text")
      .attr("x", s[0] + (s[1] - s[0]) / 2);
    if (d3.event.sourceEvent == null) return; // to skip initial events on creation
    this.userIsMovingBrush = true;
    // if (d3.event.sourceEvent && d3.event.sourceEvent.type === 'zoom')
    //   return // ignore brush-by-zoom
    // const s = d3.event.selection || this.xScaleContext.range();
    // this.svg.select('.zoom')
    // .call(this.zoom.transform, d3.zoomIdentity
    // .scale(this.width / (s[1] - s[0])).translate(-s[0], 0))
  }

  brushEnd() {
    const s = d3.event.selection || this.xScaleContext.range();
    this.clipBrush.attr("width", s[1] - s[0]).attr("x", s[0]);

    this.clipBeforeBrush.attr("width", s[0]).attr("x", 0);
    this.clipAfterBrush.attr("width", this.width - s[1]).attr("x", s[1]);

    if (d3.event.sourceEvent == null || this.noData) return; // to skip initial events on creation and brushing without data
    // if (d3.event.sourceEvent && d3.event.sourceEvent.type === 'zoom')
    //   return // ignore brush-by-zoom

    this.userIsMovingBrush = false;

    let currentTimeStart = this.xScaleContext.invert(s[0]).getTime();
    let currentTimeEnd = this.xScaleContext.invert(s[1]).getTime();
    this.updateTime(currentTimeStart, currentTimeEnd);
  }

  resetZoom() {
    if (this.noData) return;
    if (
      this.filterState.includeFilter.startTime === this.minTime &&
      this.filterState.includeFilter.endTime === this.maxTime
    )
      return;

    this.updateTime(this.minTime, this.maxTime);

    let extent;
    extent = [this.minTime, this.maxTime];
    let brushRange = [this.xDomContext(extent[0]), this.xDomContext(extent[1])];
    this.context.select(".brush").transition().duration(500).call(this.brush.move, brushRange);
  }

  zoomMove() {
    if (d3.event.sourceEvent == null) return; // to skip initial events on creation
    if (d3.event.sourceEvent && d3.event.sourceEvent.type === "brush") return; // ignore zoom-by-brush
    let t = d3.event.transform;
    this.brush.move(this.brushContainer, this.xScaleFocus.range().map(t.invertX, t));

    // TODO look how to fix the wheel event
    // if(d3.event.sourceEvent.type === 'wheel'){ // trigger post filter for Mousewheel because the trigger on end is not well defined
    //   const s = this.xScaleFocus.range().map(t.invertX, t);
    //   this.parentProps.setFilterTime(FilterType.include, this.xScaleContext.invert(s[0]).getTime(),this.xScaleContext.invert(s[1]).getTime() )
    //   this.parentProps.postFilter();
    // }
  }

  zoomEnd() {
    if (d3.event.sourceEvent == null) return; // to skip initial events on creation / wheel end is also sourceEvent = null, because its auto triggered 150ms after last wheel start
    if (d3.event.sourceEvent && d3.event.sourceEvent.type === "brush") return; // ignore zoom-by-brush

    let t = d3.event.transform;

    const s = this.xScaleFocus.range().map(t.invertX, t);
    // this.parentProps.setFilterTime(FilterType.include, this.xScaleContext.invert(s[0]).getTime(),this.xScaleContext.invert(s[1]).getTime() )
    // this.parentProps.postFilter();
  }

  formatData(data) {
    throw new Error("not necessary because data already in correct format for update");
  }

  render() {
  }
}
