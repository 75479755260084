// Combine both state + dispatch props - as well as any props we want to pass - in a union type.
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  createStyles,
  Grid,
  Paper,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import React, { Component, Fragment } from "react";
import { returnType } from "@utils/TypeUtils";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import * as userActions from "@store/user/actions";
import { ApplicationState } from "@store/index";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme: Theme) => createStyles({});

// Redux: Map redux store state to component props
const mapStateToProps = ({ user }: ApplicationState) => ({
  auth: user.auth,
  fetching: user.fetching,
  error: user.fetchError,
  userFiles: user.userFiles,
  userShareFiles: user.userShareFiles,
  fileId: user.fileId,
  username: user.username,
  shareDetails: user.shareDetails
});

// Redux: Map action dispatchers to props
const mapDispatchToProps = (dispatch: any) => ({
  getShareDetails: (token: string) => dispatch(userActions.getShareDetails(token)),
  acceptSharedFile: (username: string, token: string) =>
    dispatch(userActions.acceptSharedFile(username, token)),
  getSharedFiles: (username: string) => dispatch(userActions.getSharedFiles(username)),
  openShareWithToken: (id: number, token: string) =>
    dispatch(userActions.openShareWithToken(id, token)),
  selectShareByID: (shareId: number) => dispatch(userActions.selectShareByID(shareId))
});

// Props passed from mapStateToProps
const reduxProps = returnType(mapStateToProps);
type PropsFromReduxState = typeof reduxProps;

// Props passed from mapDispatchToProps
const reduxActions = returnType(mapDispatchToProps);
type PropsFromReduxDispatch = typeof reduxActions;

// Component-specific props.
interface ComponentProps extends RouteComponentProps {
  theme: Theme; // has to be defined here when using redux connect and forwardRef because https://github.com/mui-org/material-ui/issues/8958#issuecomment-410231896
}
const shareLight = require("../../../../images/share_light.png");
const shareDark = require("../../../../images/share_dark.png");

// Component state
interface ComponentState {
  token: string;
}

// Combine both state + dispatch props - as well as any props we want to pass - in a union type.
type CombinedProps = PropsFromReduxState &
  PropsFromReduxDispatch &
  ComponentProps &
  WithStyles<typeof styles>;

class SharePage extends Component<CombinedProps, ComponentState> {
  constructor(props: CombinedProps) {
    super(props);

    const query = new URLSearchParams(this.props.location.search);
    const token = query.get("token");
    console.log("Got share token: " + query.get("token"));

    if (token !== null) {
      this.state = {
        token: token
      };
    } else {
      this.state = {
        token: ""
      };
    }
  }

  componentDidMount() {
    this.props.getShareDetails(this.state.token);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.auth && this.props.auth != prevProps.auth) {
      this.props.getSharedFiles(this.props.username);
    }
    if (
      this.props.shareDetails.share_id &&
      this.props.userShareFiles
        .map((s: any) => {
          return s.share_id;
        })
        .includes(this.props.shareDetails.share_id)
    ) {
      this.props.selectShareByID(this.props.shareDetails.share_id);
      this.props.history.push("/analysis");
    }
  }

  private onAcceptButtonClicked() {
    this.props.acceptSharedFile(this.props.username, this.state.token);
  }

  private onViewButtonClicked() {
    this.props.openShareWithToken(this.props.shareDetails.share_id, this.state.token);
    this.props.history.push("/analysis");
  }

  render() {
    let image: any;
    if (this.props.theme.palette.type === "dark") {
      image = shareDark;
    } else {
      image = shareLight;
    }

    let ready: boolean =
      this.props.shareDetails.owner && this.props.userShareFiles && !this.props.fetching;

    let disableAccept: boolean =
      !ready ||
      !this.props.auth ||
      this.props.userShareFiles.some(
        (sharedFile: any) => sharedFile.share_id === this.props.shareDetails.share_id
      );

    let disableView: boolean = !ready;

    return (
      <Fragment>
        <Grid
          container
          spacing={0}
          direction="row"
          alignItems="center"
          justify="center"
          style={{ minHeight: "50vh" }}
        >
          {!ready ? (
            <CircularProgress color="secondary" style={{ width: 30, height: 30, margin: 10 }} />
          ) : (
            <Grid item xs={4}>
              <Card raised={true}>
                <CardMedia component="img" alt="Sess' Chicken" height="160" image={image} />
                <CardContent>
                  <Typography align="left" gutterBottom variant="h5" component="h2">
                    {this.props.shareDetails.file.filename}
                  </Typography>
                  <Typography align="left" variant="body1" color="textSecondary" component="p">
                    The user "{this.props.shareDetails.owner}" wants to share the file "
                    {this.props.shareDetails.file.filename}" with you.{" "}
                  </Typography>
                  <Typography align="left" variant="body1" color="textSecondary" component="p">
                    {" "}
                    You can accept the share to add it to your own shared files.
                  </Typography>
                  <Typography align="left" variant="body1" color="textSecondary" component="p">
                    {" "}
                    If you simply want to checkout this file, press the "View only" button.{" "}
                  </Typography>
                </CardContent>

                <CardActions>
                  <Button
                    size="small"
                    color="secondary"
                    disabled={disableAccept}
                    variant="outlined"
                    onClick={() => this.onAcceptButtonClicked()}
                  >
                    Accept share
                  </Button>
                  <Button
                    size="small"
                    color="primary"
                    disabled={disableView}
                    variant="outlined"
                    onClick={() => this.onViewButtonClicked()}
                  >
                    View only
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}
        </Grid>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  withStyles(styles, { withTheme: true })(SharePage)
);
/*<div style={{paddingLeft: "5vw", paddingRight: "5vw", textAlign: "center"}}>
          <Typography variant="h4" align="center">
            {(!ready) ?
              <CircularProgress color="secondary" style={{width: 30, height: 30, margin: 10}}/> :
              <Paper>
                {this.props.shareDetails.owner} wants to share the
                file {this.props.shareDetails.file.filename} with you.
                What do you want to do with this file?
              </Paper>
            }
          </Typography>
          <Button
            disabled={disableAccept}
            variant="outlined"
            color="secondary"
            onClick={() => this.onAcceptButtonClicked()}>
            Accept share
          </Button>
          <Button
            disabled={disableView}
            variant="outlined"
            color="secondary"
            onClick={() => this.onViewButtonClicked()}>
            View only
          </Button>
        </div>*/
