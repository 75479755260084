import Link from "./Link";
import ForceGraph from "./ForceGraph";

// The array of graph links between nodes in the tree
export default class LinkArray extends Array<Link> {
  graph: ForceGraph;

  constructor(graph: ForceGraph) {
    super();
    this.graph = graph;
  }

  // Redraw all links
  redrawLinks() {
    const oldSizeFactor = this.graph.linkSizeFactor;
    this.forEach((link) => link.draw());
    if (this.graph!.linkSizeFactor! < oldSizeFactor!) {
      this.forEach((link) => link.draw());
    }
  }

  // Clears all PIXI references of links and the links themselves
  clearAllLinks() {
    this.forEach((link) => {
      link.clear();
      this.graph.linkLayer!.removeChild(link);
      link.destroy();
    });
  }

  // Initializes the array from new link data
  initLinksFromData(links: Array<any>) {
    links.forEach((link) => {
      const linkObject = new Link(this.graph, link);

      this.graph.linkLayer!.addChild(linkObject);
      this.push(linkObject);
    });
  }
}
