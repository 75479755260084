import _ from "lodash";
import { EventEmitter } from "events";

/**
 * Base class for visualizations (ressembly to other frameworks intended).
 */
class Visualization extends EventEmitter {
  constructor(node, parentProps, data, options) {
    super();
    this.node = node;
    this.parentProps = parentProps;
    this.options = _.defaults(options || {}, this.getDefaultOptions());
    this.width = node.clientWidth;
    this.height = node.clientHeight;
    this.init();
  }

  /**
   * Override to return default values if the visualization,
   * offers optional customization options.
   *
   * @return {object} an object with default values.
   */
  getDefaultOptions() {
    return {};
  }

  /**
   * Override with your initialization logic (like setting up your SVG container).
   *
   * The following attributes are set:
   * - this.width: the width of the DOM container
   * - this.height: the height of the DOM container
   * - this.node: the container DOM node
   * - this.data: the initial data passed through formatData()
   * - this.options: the options passed on construction.
   */
  init() {
    throw new Error("init() not implemented!");
  }

  /**
   * Override with your data transformation routine.
   *
   * @param  {object} data the raw data.
   * @return {object}      the transformed data.
   */
  formatData(data) {
    // console.warn('formatData() not implemented!');
    return data;
  }

  /**
   * Override if your visualization can re-render with new data.
   *
   * Minimal example:
   *     updateData(data) {
   *       this.data = formatData(data);
   *       this.render();
   *     }
   *
   * @param  {object} formattedData the new transformed data.
   */
  updateData(formattedData) {
    // console.warn('updateData() not implemented!');
  }

  /**
   * Call this when your container size has changed and update all necessary vis widths and heights
   *
   */
  updateDimensions() {
    console.warn("updateDimensions() not implemented!");
  }

  /**
   * Insert your rendering code here.
   *
   */
  render() {
    throw new Error("render() not implemented!");
  }
}

export default Visualization;
