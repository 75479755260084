import React from "react";

import Visualization from "../../../../utils/visualization";
import { FilterAction, FilterField } from "@store/filter/types";

import * as d3 from "d3";
import d3Tip from "d3-tip";
import { FilterType } from "@store/filter/FilterStatus";
import { bytes2String } from "@utils/HelperFunctions";

export class BarChart extends Visualization {
  constructor(node, parentProps, data, options) {
    super(node, parentProps, data, options);
  }

  getDefaultOptions() {
    return {
      padding: { top: 5, right: 13, bottom: 24, left: 40 }
    };
  }

  init() {
    this.noData = true;
    this.theme = this.parentProps.theme;
    this.colors = {
      in: this.theme.barColors.incoming,
      out: this.theme.barColors.outgoing,
      intern: this.theme.barColors.internal
    };
    this.colorsSelected = {
      in: this.theme.barColors.incomingSelected,
      out: this.theme.barColors.outgoingSelected,
      intern: this.theme.barColors.internalSelected
    };
    const { padding } = this.options;
    this.keys = ["in", "out", "intern"];
    this.width = this.width - padding.left - padding.right;
    this.height = this.height - padding.bottom - padding.top;

    this.svg = d3.select(this.node).append("svg").style("width", "100%").style("height", "100%");

    this.stacked = this.svg.append("g");
    this.stacked
      .attr("class", "stacked")
      .attr("height", this.height)
      .attr("width", this.width)
      .attr("transform", "translate(" + padding.left + "," + padding.top + ")");

    this.xScale = d3.scaleLinear().range([0, this.width]);
    this.yScale = d3.scaleBand().range([this.height, 0]).padding(0.05);

    this.xAxis = d3.axisBottom(this.xScale).ticks(5).tickFormat(d3.format(".2s"));
    this.yAxis = d3.axisLeft(this.yScale);

    this.xAxisGroup = this.stacked
      .append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + this.height + ")");

    this.yAxisGroup = this.stacked
      .append("g")
      .attr("class", "y axis")
      .attr("transform", "translate(-1,0)"); // so bars dont cover yaxis

    this.format = d3.format(".2s");

    this.tip = d3Tip()
      .attr("class", "d3-tip")
      // .offset([0, 10])
      .direction("e")
      .html((d, i, data) => {
        if (typeof d === "string" || d instanceof String) {
          let t = data.filter((x) => x.protocol == d)[0];
          return (
            "<strong>" +
            d +
            ":" +
            "</strong><br/> " +
            " in: <span style='color:" +
            this.colors.in +
            "'>" +
            this.format(t.in) +
            "</span>" +
            " out: <span style='color:" +
            this.colors.out +
            "'>" +
            this.format(t.out) +
            "</span>" +
            " intern: <span style='color:" +
            this.colors.intern +
            "'>" +
            this.format(t.intern) +
            "</span>"
          );
        } else {
          return (
            "<strong>" +
            d.data.protocol +
            ":" +
            "</strong><br/> " +
            " in: <span style='color:" +
            this.colors.in +
            "'>" +
            this.format(d.data.in) +
            "</span>" +
            " out: <span style='color:" +
            this.colors.out +
            "'>" +
            this.format(d.data.out) +
            "</span>" +
            " intern: <span style='color:" +
            this.colors.intern +
            "'>" +
            this.format(d.data.intern) +
            "</span>"
          );
          // return "<strong>" + d.data.protocol + ":" + "</strong> <span style='color:red'>" + Math.floor(d[1] - d[0]) + "</span>";
        }
      });
    this.svg.call(this.tip);

    this.xAxisGroup.transition().duration(500).call(this.xAxis);

    this.yAxisGroup.transition().duration(500).call(this.yAxis);
  }

  updateData(data, filterState) {
    this.noData = false;
    this.filterState = filterState;
    let protocols = data.map((x) => x.protocol);
    let maxVolume = d3.max(data, function (d) {
      return d.in + d.out + d.intern;
    });
    let xDom = this.xScale.domain([0, maxVolume]);
    let yDom = this.yScale.domain(protocols);
    this.xDom = xDom;
    this.yDom = yDom;

    this.xAxis.scale(xDom);
    this.yAxis.scale(yDom);
    this.stacked.select(".x").transition().duration(500).call(this.xAxis);

    this.stacked.select(".y").transition().duration(500).call(this.yAxis);

    this.yAxisGroup
      .selectAll(".tick")
      .on("click", this.handleSelect.bind(this))
      .style("cursor", "pointer")
      .on("mouseover", (d, i, n) => this.tip.show(d, i, data, n[i]))
      .on("mouseout", this.tip.hide);

    let colors = this.colors;
    let colorsSelected = this.colorsSelected;

    let stack = d3.stack().keys(this.keys).order(d3.stackOrderNone).offset(d3.stackOffsetNone);

    let stacked = this.stacked;

    this.keys.forEach((key, key_index) => {
      let bar = stacked.selectAll(".bar-" + key).data(stack(data)[key_index], function (d) {
        return d.data.protocol + "-" + key;
      });

      bar.exit().transition().duration(500).attr("x", 0).attr("width", 0).remove();

      // modifiy existing bars
      bar
        .transition()
        .duration(500)
        .attr("height", yDom.bandwidth())
        .attr("y", function (d) {
          return yDom(d.data.protocol);
        })
        .attr("x", function (d) {
          return xDom(d[0]);
        })
        .attr("width", function (d) {
          return xDom(d[1]) - xDom(d[0]);
        });

      // modifiy new bars
      bar
        .enter()
        .append("rect")
        .attr("class", "bar bar-" + key)
        .attr("x", 0)
        .attr("width", 0)
        .attr("height", yDom.bandwidth())
        .style("cursor", "pointer")
        .on("mouseover", (d, i, n) => this.tip.show(d, i, data, n[i]))
        .on("mouseout", this.tip.hide)
        .attr("y", function (d) {
          return yDom(d.data.protocol);
        })
        .attr("fill", function (d) {
          if (filterState.includeFilter.protocol.includes(d.data.protocol))
            return colorsSelected[key];
          else return colors[key];
        })
        .on("click", this.handleSelect.bind(this))
        .transition()
        .duration(500)
        .attr("x", function (d) {
          return xDom(d[0]);
        })
        .attr("width", function (d) {
          return xDom(d[1]) - xDom(d[0]);
        });
    });
  }

  updateTheme(theme) {
    this.theme = theme;
    this.colors = {
      in: this.theme.barColors.incoming,
      out: this.theme.barColors.outgoing,
      intern: this.theme.barColors.internal
    };
    this.colorsSelected = {
      in: this.theme.barColors.incomingSelected,
      out: this.theme.barColors.outgoingSelected,
      intern: this.theme.barColors.internalSelected
    };
    this.svg.selectAll(".bar-" + "in").attr("fill", (d) => {
      if (this.filterState.includeFilter.protocol.includes(d.data.protocol))
        return this.colorsSelected.in;
      else return this.colors.in;
    });
    this.svg.selectAll(".bar-" + "out").attr("fill", (d) => {
      if (this.filterState.includeFilter.protocol.includes(d.data.protocol))
        return this.colorsSelected.out;
      else return this.colors.out;
    });
    this.svg.selectAll(".bar-" + "intern").attr("fill", (d) => {
      if (this.filterState.includeFilter.protocol.includes(d.data.protocol))
        return this.colorsSelected.intern;
      else return this.colors.intern;
    });
  }

  updateAxis(bool) {
    if (bool) {
      this.xAxis = d3.axisBottom(this.xScale).ticks(2).tickFormat(bytes2String);
    } else {
      this.xAxis = d3.axisBottom(this.xScale).ticks(5).tickFormat(d3.format(".2s"));
    }
  }

  updateDimensions(node) {
    const { padding } = this.options;
    if (node != null) {
      this.width = node.clientWidth;
      this.height = node.clientHeight;
    }
    this.width = this.width - padding.left - padding.right;
    this.height = this.height - padding.bottom - padding.top;

    this.xScale.range([0, this.width]);
    this.yScale.range([this.height, 0]).padding(0.05);

    this.xAxisGroup.attr("transform", "translate(0," + this.height + ")");

    this.xAxisGroup.transition().duration(500).call(this.xAxis);

    this.yAxisGroup.transition().duration(500).call(this.yAxis);

    if (!this.noData) {
      this.svg
        .selectAll(".bar")
        .attr("height", this.yDom.bandwidth())
        .attr("y", (d) => {
          return this.yDom(d.data.protocol);
        })
        .attr("x", (d) => {
          return this.xDom(d[0]);
        })
        .attr("width", (d) => {
          return this.xDom(d[1]) - this.xDom(d[0]);
        });
    }
  }

  updateSelection(filterState) {
    this.filterState = filterState;
    this.svg.selectAll(".bar-" + "in").attr("fill", (d) => {
      if (filterState.includeFilter.protocol.includes(d.data.protocol))
        return this.colorsSelected.in;
      else return this.colors.in;
    });
    this.svg.selectAll(".bar-" + "out").attr("fill", (d) => {
      if (filterState.includeFilter.protocol.includes(d.data.protocol))
        return this.colorsSelected.out;
      else return this.colors.out;
    });
    this.svg.selectAll(".bar-" + "intern").attr("fill", (d) => {
      if (filterState.includeFilter.protocol.includes(d.data.protocol))
        return this.colorsSelected.intern;
      else return this.colors.intern;
    });
  }

  handleSelect(d) {
    if (typeof d === "string" || d instanceof String) {
      // axis label clicked
      if (this.filterState.includeFilter.protocol.includes(d)) {
        this.parentProps.updateFilter(
          FilterType.include,
          FilterAction.remove,
          FilterField.protocol,
          d
        );
      } else {
        this.parentProps.updateFilter(
          FilterType.include,
          FilterAction.add,
          FilterField.protocol,
          d
        );
      }
    } else {
      // bar clicked
      if (this.filterState.includeFilter.protocol.includes(d.data.protocol)) {
        this.parentProps.updateFilter(
          FilterType.include,
          FilterAction.remove,
          FilterField.protocol,
          d.data.protocol
        );
      } else {
        this.parentProps.updateFilter(
          FilterType.include,
          FilterAction.add,
          FilterField.protocol,
          d.data.protocol
        );
      }
    }
  }

  formatData(data) {}

  render() {}
}
