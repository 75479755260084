import Visualization from "../../../../utils/visualization";
import { FilterAction, FilterField } from "@store/filter/types";
import { FilterType } from "@store/filter/FilterStatus";

import React from "react";
import * as d3 from "d3";
import d3Tip from "d3-tip";

export class InOutChart extends Visualization {
  constructor(node, parentProps, data, options) {
    super(node, parentProps, data, options);
  }

  getDefaultOptions() {
    return {
      padding: { top: 8, right: 20, bottom: 12, left: 30 }
    };
  }

  init() {
    this.noData = true;
    this.theme = this.parentProps.theme;
    this.colors = {
      in: this.theme.barColors.incoming,
      out: this.theme.barColors.outgoing,
      intern: this.theme.barColors.internal
    };
    this.colorsSelected = {
      in: this.theme.barColors.incomingSelected,
      out: this.theme.barColors.outgoingSelected,
      intern: this.theme.barColors.internalSelected
    };
    const { padding } = this.options;
    this.keys = ["pIn", "pOut", "pIntern"];

    this.width = this.width - padding.left - padding.right;
    this.height = this.height - padding.bottom - padding.top;

    this.svg = d3
      .select(this.node)
      .append("svg")
      .style("width", "100%")
      .style("height", this.height); // 100% is extending the whole page

    this.stacked = this.svg.append("g");
    this.stacked
      .attr("class", "stacked")
      .attr("height", this.height)
      .attr("width", this.width)
      .attr("transform", "translate(" + padding.left + "," + padding.top + ")");

    this.xScale = d3.scaleLinear().rangeRound([0, this.width]);

    this.format = d3.format(".2s");

    this.tip = d3Tip()
      .attr("class", "d3-tip")
      // .offset([0, 10])
      .direction("e")
      .html((d, i, data) => {
        return (
          "<strong>" +
          d.data.type +
          ":" +
          "</strong><br/> " +
          " in: <span style='color:" +
          this.colors.in +
          "'>" +
          this.format(d.data.in) +
          " (" +
          d.data.pIn.toFixed(2) +
          "%)</span>" +
          " out: <span style='color:" +
          this.colors.out +
          "'>" +
          this.format(d.data.out) +
          " (" +
          d.data.pOut.toFixed(2) +
          "%)</span>" +
          " intern: <span style='color:" +
          this.colors.intern +
          "'>" +
          this.format(d.data.intern) +
          " (" +
          d.data.pIntern.toFixed(2) +
          "%)</span>"
        );
      });
    this.svg.call(this.tip);
  }

  updateData(data, filterState) {
    this.noData = false;
    this.filterState = filterState;

    let xDom = this.xScale.domain([0, 100]).nice();
    this.xDom = xDom;

    let percentData;
    if (data[0].in === 0 && data[0].out === 0 && data[0].intern === 0) {
      percentData = [
        { type: "Connections", in: 0, out: 0, intern: 0, pIn: 0, pOut: 0, pIntern: 0 }
      ];
    } else {
      percentData = data.map((x) => ({
        type: x.type,
        in: x.in,
        out: x.out,
        intern: x.intern,
        pIn: (x.in / (x.in + x.out + x.intern)) * 100,
        pOut: (x.out / (x.in + x.out + x.intern)) * 100,
        pIntern: (x.intern / (x.in + x.out + x.intern)) * 100
      }));
    }

    let colors = this.colors;
    let colorsSelected = this.colorsSelected;
    let stacked = this.stacked;

    let stack = d3.stack().keys(this.keys).order(d3.stackOrderNone).offset(d3.stackOffsetNone);

    this.keys.forEach((key, key_index) => {
      let bar = stacked.selectAll(".bar-" + key).data(stack(percentData)[key_index], function (d) {
        return d.data.type + "-" + key;
      });

      // modifiy existing bars
      bar
        .attr("fill", () => {
          let s = "";
          if (key === "pIn") s = "in";
          else if (key === "pOut") s = "out";
          else s = "intern";
          if (filterState.includeFilter.inOut.includes(s)) return colorsSelected[s];
          else return colors[s];
        })
        .transition()
        .duration(500)
        .attr("height", this.height)
        .attr("y", 0)
        .attr("x", function (d) {
          return xDom(d[0]);
        })
        .attr("width", function (d) {
          return xDom(d[1]) - xDom(d[0]);
        });

      // modifiy new bars
      bar
        .enter()
        .append("rect")
        .attr("class", "bar bar-" + key)
        .attr("x", 0)
        .attr("width", 0)
        .attr("height", this.height)
        .style("cursor", "pointer")
        .on("mouseover", (d, i, n) => this.tip.show(d, i, data, n[i]))
        .on("mouseout", this.tip.hide)
        .attr("y", 0)
        .attr("fill", () => {
          let s = "";
          if (key === "pIn") s = "in";
          else if (key === "pOut") s = "out";
          else s = "intern";
          if (filterState.includeFilter.inOut.includes(s)) return colorsSelected[s];
          else return colors[s];
        })
        .on("click", () => this.handleSelect.bind(this)(key_index))
        .transition()
        .duration(500)
        .attr("x", function (d) {
          return xDom(d[0]);
        })
        .attr("width", function (d) {
          return xDom(d[1]) - xDom(d[0]);
        });
    });
  }

  updateSelection(filterState) {
    this.filterState = filterState;
    this.svg.selectAll(".bar-pIn").attr("fill", (d) => {
      if (filterState.includeFilter.inOut.includes("in")) return this.colorsSelected.in;
      else return this.colors.in;
    });
    this.svg.selectAll(".bar-pOut").attr("fill", (d) => {
      if (filterState.includeFilter.inOut.includes("out")) return this.colorsSelected.out;
      else return this.colors.out;
    });
    this.svg.selectAll(".bar-pIntern").attr("fill", (d) => {
      if (filterState.includeFilter.inOut.includes("intern")) return this.colorsSelected.intern;
      else return this.colors.intern;
    });
  }

  updateTheme(theme) {
    this.theme = theme;
    this.colors = {
      in: this.theme.barColors.incoming,
      out: this.theme.barColors.outgoing,
      intern: this.theme.barColors.internal
    };
    this.colorsSelected = {
      in: this.theme.barColors.incomingSelected,
      out: this.theme.barColors.outgoingSelected,
      intern: this.theme.barColors.internalSelected
    };
    this.svg.selectAll(".bar-" + "pIn").attr("fill", (d) => {
      if (this.filterState.includeFilter.inOut.includes("in")) return this.colorsSelected.in;
      else return this.colors.in;
    });
    this.svg.selectAll(".bar-" + "pOut").attr("fill", (d) => {
      if (this.filterState.includeFilter.inOut.includes("out")) return this.colorsSelected.out;
      else return this.colors.out;
    });
    this.svg.selectAll(".bar-" + "pIntern").attr("fill", (d) => {
      if (this.filterState.includeFilter.inOut.includes("intern")) return this.colorsSelected.intern;
      else return this.colors.intern;
    });
  }

  updateDimensions(node) {
    const { padding } = this.options;
    if (node != null) {
      this.width = node.clientWidth;
      this.height = node.clientHeight;
    }
    this.width = this.width - padding.left - padding.right;
    this.height = this.height - padding.bottom - padding.top;

    this.xScale.rangeRound([0, this.width]);

    this.svg.style("height", this.height); // 100% is extending the whole page

    if (!this.noData) {
      this.svg
        .selectAll(".bar")
        .attr("height", this.height)
        .attr("x", (d) => {
          return this.xDom(d[0]);
        })
        .attr("width", (d) => {
          return this.xDom(d[1]) - this.xDom(d[0]);
        });
    }
  }

  handleSelect(d) {
    const mode = ["in", "out", "intern"];
    let s = mode[d];
    if (this.filterState.includeFilter.inOut.includes(s)) {
      this.parentProps.updateFilter(FilterType.include, FilterAction.remove, FilterField.inOut, s);
    } else {
      this.parentProps.updateFilter(FilterType.include, FilterAction.add, FilterField.inOut, s);
    }
  }

  formatData(data) {
    return data;
  }

  render() {}
}
