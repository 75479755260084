import React, { Component, RefObject } from "react";
import { connect } from "react-redux";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core";
import { ApplicationState } from "@store/index";
import { returnType } from "@utils/TypeUtils";
import * as filterActions from "@store/filter/actions";
import { FilterType } from "@store/filter/FilterStatus";
import { HorzBarChart } from "./HorzBarChart";
import { FilterAction, FilterField } from "@store/filter/types";

// Definition of injected styles
const styles = (theme: Theme) => createStyles({});

// Component-specific props.
interface ComponentProps {
  theme: Theme; // has to be defined here when using redux connect and forwardRef because https://github.com/mui-org/material-ui/issues/8958#issuecomment-410231896
}

// Redux: Map redux store state to component props
const mapStateToProps = (state: ApplicationState) => ({
  srcData: state.websocket.srcData,
  srcMode: state.visMode.srcMode,
  filterState: state.filter,
  dnsMap: state.websocket.dnsMap,
  showVolumeTopSourceIpsVis: state.user.showVolumeTopSourceIpsVis
});

// Redux: Map action dispatchers to props
const mapDispatchToProps = (dispatch: any) => ({
  updateFilter: (filterType: FilterType, act: FilterAction, field: FilterField, payload: string) =>
    dispatch(filterActions.updateFilter(filterType, act, field, payload))
});

// Props passed from mapStateToProps
const reduxProps = returnType(mapStateToProps);
type PropsFromReduxState = typeof reduxProps;

// Props passed from mapDispatchToProps
const reduxActions = returnType(mapDispatchToProps);
type PropsFromReduxDispatch = typeof reduxActions;

type CombinedProps = PropsFromReduxState &
  PropsFromReduxDispatch &
  ComponentProps &
  WithStyles<typeof styles>;

class SrcComponent extends Component<CombinedProps> {
  public _chart: any;
  public myRef: RefObject<HTMLDivElement>;

  constructor(props: CombinedProps) {
    super(props);
    this.myRef = React.createRef();
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  updateDimensions() {
    this._chart.updateDimensions(this.myRef.current);
  }

  componentWillUnmount(): void {
    window.removeEventListener("resize", this.updateDimensions);
  }

  componentDidMount() {
    this._chart = new HorzBarChart(this.myRef.current, this.props, "src");
    window.addEventListener("resize", this.updateDimensions);
    if (this.props.dnsMap) {
      this._chart.updateDnsMap(this.props.dnsMap);
    }
    if (Object.keys(this.props.srcData).length !== 0 && this.props.srcData.constructor === Object) {
      let data = this.props.srcMode === "ip" ? this.props.srcData.ip : this.props.srcData.port;
      this._chart.updateData(data, this.props.filterState);
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.srcData != this.props.srcData || this.props.srcMode != prevProps.srcMode) {
      let data = this.props.srcMode === "ip" ? this.props.srcData.ip : this.props.srcData.port;
      this._chart.updateData(data, this.props.filterState);
    }
    if (this.props.theme != prevProps.theme) {
      this._chart.updateTheme(this.props.theme, this.props.filterState);
    }
    if (this.props.filterState != prevProps.filterState) {
      this._chart.updateSelection(this.props.filterState);
    }
    if (this.props.dnsMap != prevProps.dnsMap) {
      this._chart.updateDnsMap(this.props.dnsMap);
    }
    if (this.props.showVolumeTopSourceIpsVis != prevProps.showVolumeTopSourceIpsVis) {
      this._chart.updateAxis(this.props.showVolumeTopSourceIpsVis);
      this.updateDimensions();
    }
  }

  render() {
    return <div style={{ height: "100%" }} ref={this.myRef} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  withStyles(styles, { withTheme: true })(SrcComponent)
);
