import React, {Component} from "react";
import {
  Checkbox,
  createStyles,
  Grow,
  IconButton,
  Theme,
  WithStyles,
  withStyles
} from "@material-ui/core";
import {Settings} from "@material-ui/icons";
import {RouteComponentProps, withRouter} from "react-router";
import {connect} from "react-redux";
import {ApplicationState} from "@store/index";
import {Dispatch} from "redux";
import {returnType} from "@utils/TypeUtils";
import Slide from "@material-ui/core/Slide";
import Paper from "@material-ui/core/Paper";
import Modal from "@material-ui/core/Modal";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import * as userActions from "@store/user/actions";
import TableBody from "@material-ui/core/TableBody";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import TableContainer from "@material-ui/core/TableContainer";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      margin: theme.spacing(3, 0, 2)
    },
    buttonProgress: {
      color: theme.palette.secondary.main,
      position: "absolute",
      left: "50%",
      marginTop: 30,
      marginLeft: -12
    }
  });

const mapStateToProps = ({user, websocket}: ApplicationState) => ({
  auth: user.auth,
  fetching: user.fetching,
  error: user.fetchError,
  userFiles: user.userFiles,
  userShareFiles: user.userShareFiles,
  fileId: user.fileId,
  username: user.username,
  sessionId: websocket.sessionId,
  fetchedIPs: user.fetchedIPs,
  resetLayout: user.resetLayout,
  shareId: user.shareId
});
// Redux: Map action dispatchers to props
const mapDispatchToProps = (dispatch: Dispatch) => ({
  getIpsFromFile: (username: string, fileId: number, sessionId: string) =>
    dispatch(userActions.getIpsFromFile(username, fileId, sessionId)),
  getIpsFromSharedFile: (username: string, sShareId: number) =>
    dispatch(userActions.getIpsFromSharedFile(username, sShareId)),
  changeIpName: (username: string, fileId: number, ip: string, name: string, sessionId: string) =>
    dispatch(userActions.editIpName(username, fileId, ip, name, sessionId)),
  updateInternalIps: (username: string, fileId: number, ipLIst: any[], sessionId: string) =>
    dispatch(userActions.editInternalIpsFromFile(username, fileId, ipLIst, sessionId)),
  handleResetLayout: () => dispatch(userActions.resetLayout()),
});

// Props passed from mapStateToProps
const reduxProps = returnType(mapStateToProps);
type PropsFromReduxState = typeof reduxProps;

// Props passed from mapDispatchToProps
const reduxActions = returnType(mapDispatchToProps);
type PropsFromReduxDispatch = typeof reduxActions;

interface ComponentProps extends RouteComponentProps {
  style?: any;
}

interface ComponentState {
  openSettingsMenu: boolean;
  openIpTable: boolean;
  openInternalIpMenu: boolean;
  chosenIp: string;
  newIpName: string;
  updatedInternalIpList: any[];
  tryToUpdateInternalIps: boolean;
}

// Combine both state + dispatch props - as well as any props we want to pass - in a union type.
type CombinedProps = PropsFromReduxState &
  PropsFromReduxDispatch &
  ComponentProps &
  WithStyles<typeof styles>;

class SettingsComponent extends Component<CombinedProps, ComponentState> {
  constructor(props: CombinedProps) {
    super(props);

    this.state = {
      openSettingsMenu: false,
      openIpTable: false,
      openInternalIpMenu: false,
      chosenIp: "-1",
      newIpName: "",
      updatedInternalIpList: [],
      tryToUpdateInternalIps: false,
    };
  }

  openOrCloseSettingsMenu() {
    if (this.state.openSettingsMenu) {
      this.setState({openIpTable: false});
      // this.setState({openChangeVisualisationWindow: false});
    }
    this.setState({
      openSettingsMenu: !this.state.openSettingsMenu
    });
  }

  openIpTable() {
    this.setState({openIpTable: true, updatedInternalIpList: []});
    if (this.props.shareId == -1) {
      this.props.getIpsFromFile(this.props.username, this.props.fileId, this.props.sessionId);
    } else {
      this.props.getIpsFromSharedFile(this.props.username, this.props.shareId);
    }
  }

  closeIpTable() {
    this.setState({openIpTable: false});
  }

  addOrRemoveInternalIp(ip: string, isInternal: boolean) {
    let bool = this.state.updatedInternalIpList.find((x) => x.ip === ip);
    let tmp = this.state.updatedInternalIpList;

    if (!bool) {
      tmp.push({ip: ip, name: "", isInternal: !isInternal});
    } else {
      tmp = tmp.filter((value: any, index, arr) => {
        return value.ip !== ip;
      });
    }
    this.setState({updatedInternalIpList: tmp});
  }

  handleInternalIpUpdate() {
    this.setState({tryToUpdateInternalIps: true});
    this.props.updateInternalIps(
      this.props.username,
      this.props.fileId,
      this.state.updatedInternalIpList,
      this.props.sessionId
    );
  }

  handleResetLayout() {
    this.props.handleResetLayout();
    this.openOrCloseSettingsMenu();
  }

  handleIpNameChange(ip: string, name: string) {
    this.props.changeIpName(this.props.username, this.props.fileId, ip, name, this.props.sessionId);
  }

  componentDidUpdate(
    prevProps: Readonly<CombinedProps>,
    prevState: Readonly<ComponentState>,
    snapshot?: any
  ): void {
    // after name change reset the state
    if (prevProps.fetchedIPs !== this.props.fetchedIPs && !this.state.tryToUpdateInternalIps) {
      this.setState({chosenIp: "-1", newIpName: ""});
    }

    // after successful internal ip change close the window
    if (
      prevProps.fetching &&
      this.state.tryToUpdateInternalIps &&
      !this.props.error.localeCompare("")
    ) {
      this.setState({tryToUpdateInternalIps: false});
      this.closeIpTable();
      this.openOrCloseSettingsMenu();
    }
  }

  render() {
    return (
      <div>
        <IconButton onClick={this.openOrCloseSettingsMenu.bind(this)}>
          <Settings/>
        </IconButton>

        <Grow timeout={400} in={this.state.openSettingsMenu} mountOnEnter unmountOnExit>
          <Paper
            elevation={20}
            style={{
              position: "fixed",
              right: 0,
              top: 0,
              width: 180,
              marginTop: 48,
              marginRight: 130
            }}
          >
            <MenuList>
              <MenuItem
                disabled={this.props.fileId == -1 && this.props.shareId == -1}
                onClick={() => {
                  this.openIpTable();
                }}
              >
                {" "}
                Configure Ips{" "}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  this.handleResetLayout();
                }}
                disabled={this.props.resetLayout}
              >
                Reset Layout
              </MenuItem>
            </MenuList>

            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.state.openIpTable}
              onClose={this.closeIpTable.bind(this)}
            >
              <Slide
                timeout={400}
                direction="left"
                in={this.state.openIpTable}
                mountOnEnter
                unmountOnExit
              >
                <Paper
                  elevation={20}
                  style={{position: "fixed", right: 0, top: 0, marginTop: 48, marginRight: 0}}
                >
                  <TableContainer style={{maxHeight: 600, minWidth: 450}}>
                    <Table aria-label="ip table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{paddingLeft: 40}} align="left">
                            IP-Address
                          </TableCell>
                          <TableCell style={{paddingLeft: 10}} align="left">
                            Name
                          </TableCell>
                          <TableCell style={{}} align="left">
                            {" "}
                            Edit
                          </TableCell>
                          <TableCell style={{}} align="left">
                            Is Internal Ip{" "}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.props.fetchedIPs.map((fetchedData: any) => {
                          return (
                            <TableRow key={fetchedData.ip}>
                              <TableCell style={{paddingLeft: 40}} align="left">
                                <TextField
                                  style={{width: 300, fontSize: 11}}
                                  value={fetchedData.ip}
                                  InputProps={{
                                    disableUnderline: true
                                  }}
                                ></TextField>{" "}
                              </TableCell>
                              <TableCell style={{paddingLeft: 10}} align="left">
                                {this.state.chosenIp === fetchedData.ip ? (
                                  <TextField
                                    style={{width: 190}}
                                    value={this.state.newIpName}
                                    InputProps={{
                                      style: {fontSize: 14},
                                      endAdornment: (
                                        <IconButton
                                          onClick={() => {
                                            this.setState({chosenIp: "-1"});
                                          }}
                                          size={"small"}
                                        >
                                          <CancelIcon/>
                                        </IconButton>
                                      )
                                    }}
                                    onChange={(event) => {
                                      this.setState({newIpName: event.target.value});
                                    }}
                                  ></TextField>
                                ) : (
                                  <TextField
                                    style={{width: 190, fontSize: 11}}
                                    value={fetchedData.name}
                                    InputProps={{
                                      disableUnderline: true
                                    }}
                                  ></TextField>
                                )}
                              </TableCell>

                              <TableCell style={{}} align="left">
                                <IconButton
                                  style={{padding: 0}}
                                  color="inherit"
                                  disabled={
                                    (this.state.chosenIp !== "-1" &&
                                      this.state.chosenIp !== fetchedData.ip) ||
                                    (this.state.chosenIp === fetchedData.ip &&
                                      this.state.newIpName === fetchedData.name)
                                  }
                                  onClick={() => {
                                    if (this.state.chosenIp !== fetchedData.ip)
                                      this.setState({
                                        chosenIp: fetchedData.ip,
                                        newIpName: fetchedData.name
                                      });
                                    else {
                                      this.handleIpNameChange(fetchedData.ip, this.state.newIpName);
                                    }
                                  }}
                                >
                                  {this.state.chosenIp === fetchedData.ip ? (
                                    <CheckIcon/>
                                  ) : (
                                    <EditIcon/>
                                  )}
                                </IconButton>
                              </TableCell>

                              <TableCell style={{}} align="left">
                                <Checkbox
                                  checked={
                                    this.state.updatedInternalIpList.some(
                                      (value: any) => value.ip === fetchedData.ip
                                    )
                                      ? !fetchedData.isInternal
                                      : fetchedData.isInternal
                                  }
                                  onChange={(event) =>
                                    this.addOrRemoveInternalIp(
                                      fetchedData.ip,
                                      !event.target.checked
                                    )
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Paper style={{marginTop: 25, textAlign: "center"}}>
                    {this.props.fetching && (
                      <CircularProgress
                        color="secondary"
                        style={{
                          position: "absolute",
                          bottom: 50,
                          left: 325,
                          height: 80,
                          width: 80
                        }}
                      />
                    )}
                    <Button
                      onClick={() => this.handleInternalIpUpdate()}
                      disabled={this.state.updatedInternalIpList.length < 1 || this.props.fetching}
                      variant="contained"
                      color="primary"
                      style={{bottom: 15}}
                    >
                      Confirm Internal Ips
                    </Button>
                  </Paper>
                </Paper>
              </Slide>
            </Modal>
          </Paper>
        </Grow>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SettingsComponent))
);
