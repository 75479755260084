export interface Filter {
  readonly srcPort: number[];
  readonly destPort: number[];
  readonly srcIP: string[];
  readonly destIP: string[];
  readonly startTime: number;
  readonly endTime: number;
  readonly protocol: string[];
  readonly inOut: string[];
}

export enum FilterType {
  include = "include",
  exclude = "exclude"
}
