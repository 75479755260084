import React, { Component } from "react";
import { connect } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import "typeface-roboto";

import { ApplicationState } from "@store/index";
import { returnType } from "@utils/TypeUtils";
import createMuiTheme, { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";

// Redux: Map redux store state to component props
const mapStateToProps = ({ applicationLayout }: ApplicationState) => ({
  type: applicationLayout.type,
  primaryColor: applicationLayout.primaryColor,
  secondaryColor: applicationLayout.secondaryColor
});

// Props passed from mapStateToProps
const reduxProps = returnType(mapStateToProps);
type PropsFromReduxState = typeof reduxProps;

// Extend MuiTheme with custom properties in the Typescript way!
declare module "@material-ui/core/styles/createMuiTheme" {
  interface Theme {
    barColors: {
      incoming: string;
      outgoing: string;
      internal: string;
      incomingSelected: string;
      outgoingSelected: string;
      internalSelected: string;
    };
    serverConnection: {
      red: string;
      yellow: string;
      green: string;
    };
    layoutHead: string;
    boxShade: string;
    internalNode: string;
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    barColors?: {
      incoming?: string;
      outgoing?: string;
      internal?: string;
      incomingSelected?: string;
      outgoingSelected?: string;
      internalSelected?: string;
    };
    serverConnection?: {
      red?: string;
      yellow?: string;
      green?: string;
    };
    layoutHead?: string;
    boxShade?: string;
    internalNode?: string;
  }
}

class ThemeProvider extends Component<PropsFromReduxState> {
  createMyTheme(options: ThemeOptions) {
    return createMuiTheme({
      ...options
    });
  }

  darkTheme = this.createMyTheme({
    internalNode: "#e6b932",
    barColors: {
      incoming: "#3d6ba4",
      outgoing: "#62a460",
      internal: "#a49360",
      incomingSelected: "#3e5791",
      outgoingSelected: "#3e7d3e",
      internalSelected: "#7e6e3e"
    },
    serverConnection: {
      red: "#8f0600",
      yellow: "#858f00",
      green: "#1b8f00"
    },
    layoutHead: "#00000008",
    boxShade: "rgba(0,0,0,0.04)",
    overrides: {
      MuiLink: {
        root: {
          color: "#7daee1"
        }
      },
      MuiFormLabel: {
        root: {
          "&$focused": {
            color: "#009374"
          }
        },

        focused: {}
      },
      MuiInputBase: {
        input: {
          "&:-webkit-autofill": {
            transitionDelay: "9999s",
            transitionProperty: "background-color, color"
          }
        }
      }
    },
    palette: {
      type: "dark",
      primary: {
        main: "#000000"
      },
      secondary: {
        main: "#009374"
      }
    }
  });

  lightTheme = this.createMyTheme({
    internalNode: "#e6b932",
    barColors: {
      incoming: "#4a88c8",
      outgoing: "#71c86f",
      internal: "#a49360",
      incomingSelected: "#4565a0",
      outgoingSelected: "#57a057",
      internalSelected: "#7e6e3e"
    },
    serverConnection: {
      red: "#cb0600",
      yellow: "#bacb00",
      green: "#25ca00"
    },
    layoutHead: "#00000005",
    boxShade: "rgba(147,147,147,0.05)",
    overrides: {
      MuiLink: {
        root: {
          color: "#356cb1"
        }
      }
    },
    palette: {
      type: "light",
      primary: {
        main: "#009374"
      },
      secondary: {
        main: "#bf0025"
      }
    }
  });

  getTheme() {
    const { type } = this.props;
    if (type === "dark") return this.darkTheme;
    else return this.lightTheme;
  }

  render() {
    return (
      <MuiThemeProvider theme={this.getTheme()}>
        <CssBaseline />
        {this.props.children}
      </MuiThemeProvider>
    );
  }
}

// Connect this component to the redux store
export default connect(mapStateToProps)(ThemeProvider);
