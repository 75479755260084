import Button, { ButtonProps } from "@material-ui/core/Button";
import React, { Component } from "react";
import { Link, LinkProps } from "react-router-dom";

/**
 * Workaround solution to link buttons, source: https://github.com/mui-org/material-ui/issues/7186
 */
export default class LinkButton extends Component<ButtonProps & LinkProps> {
  render() {
    return <Button component={Link} {...(this.props as any)} />;
  }
}
