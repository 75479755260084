export const WiresharkParser =
  /*
   *  See WiresharkDisplayFilter.pegjs for readable-code
   *
   *  Generated by WiresharkParser.js 0.10.0.
   *
   * http://pegjs.org/
   */
  (function () {
    "use strict";

    function peg$subclass(child, parent) {
      function ctor() {
        this.constructor = child;
      }

      ctor.prototype = parent.prototype;
      child.prototype = new ctor();
    }

    function peg$SyntaxError(message, expected, found, location) {
      this.message = message;
      this.expected = expected;
      this.found = found;
      this.location = location;
      this.name = "SyntaxError";

      if (typeof Error.captureStackTrace === "function") {
        Error.captureStackTrace(this, peg$SyntaxError);
      }
    }

    peg$subclass(peg$SyntaxError, Error);

    peg$SyntaxError.buildMessage = function (expected, found) {
      var DESCRIBE_EXPECTATION_FNS = {
        literal: function (expectation) {
          return "\"" + literalEscape(expectation.text) + "\"";
        },

        "class": function (expectation) {
          var escapedParts = "",
            i;

          for (i = 0; i < expectation.parts.length; i++) {
            escapedParts += expectation.parts[i] instanceof Array
              ? classEscape(expectation.parts[i][0]) + "-" + classEscape(expectation.parts[i][1])
              : classEscape(expectation.parts[i]);
          }

          return "[" + (expectation.inverted ? "^" : "") + escapedParts + "]";
        },

        any: function (expectation) {
          return "any character";
        },

        end: function (expectation) {
          return "end of input";
        },

        other: function (expectation) {
          return expectation.description;
        }
      };

      function hex(ch) {
        return ch.charCodeAt(0).toString(16).toUpperCase();
      }

      function literalEscape(s) {
        return s
          .replace(/\\/g, '\\\\')
          .replace(/"/g, '\\"')
          .replace(/\0/g, '\\0')
          .replace(/\t/g, '\\t')
          .replace(/\n/g, '\\n')
          .replace(/\r/g, '\\r')
          .replace(/[\x00-\x0F]/g, function (ch) {
            return '\\x0' + hex(ch);
          })
          .replace(/[\x10-\x1F\x7F-\x9F]/g, function (ch) {
            return '\\x' + hex(ch);
          });
      }

      function classEscape(s) {
        return s
          .replace(/\\/g, '\\\\')
          .replace(/\]/g, '\\]')
          .replace(/\^/g, '\\^')
          .replace(/-/g, '\\-')
          .replace(/\0/g, '\\0')
          .replace(/\t/g, '\\t')
          .replace(/\n/g, '\\n')
          .replace(/\r/g, '\\r')
          .replace(/[\x00-\x0F]/g, function (ch) {
            return '\\x0' + hex(ch);
          })
          .replace(/[\x10-\x1F\x7F-\x9F]/g, function (ch) {
            return '\\x' + hex(ch);
          });
      }

      function describeExpectation(expectation) {
        return DESCRIBE_EXPECTATION_FNS[expectation.type](expectation);
      }

      function describeExpected(expected) {
        var descriptions = new Array(expected.length),
          i, j;

        for (i = 0; i < expected.length; i++) {
          descriptions[i] = describeExpectation(expected[i]);
        }

        descriptions.sort();

        if (descriptions.length > 0) {
          for (i = 1, j = 1; i < descriptions.length; i++) {
            if (descriptions[i - 1] !== descriptions[i]) {
              descriptions[j] = descriptions[i];
              j++;
            }
          }
          descriptions.length = j;
        }

        switch (descriptions.length) {
          case 1:
            return descriptions[0];

          case 2:
            return descriptions[0] + " or " + descriptions[1];

          default:
            return descriptions.slice(0, -1).join(", ")
              + ", or "
              + descriptions[descriptions.length - 1];
        }
      }

      function describeFound(found) {
        return found ? "\"" + literalEscape(found) + "\"" : "end of input";
      }

      return "Expected " + describeExpected(expected) + " but " + describeFound(found) + " found.";
    };

    function peg$parse(input, options) {
      options = options !== void 0 ? options : {};

      var peg$FAILED = {},

        peg$startRuleFunctions = {MainQuery: peg$parseMainQuery},
        peg$startRuleFunction = peg$parseMainQuery,

        peg$c0 = function (query) {
          let result = []
          query.forEach((el, i, arr) => {
            if (i % 2 === 0) result.push([el, arr[i + 1]])
          })
          return result;
        },
        peg$c1 = function (expr) {
          return expr
        },
        peg$c2 = "&&",
        peg$c3 = peg$literalExpectation("&&", false),
        peg$c4 = function (head, tail) {
          return tail.reduce((result, element) => {
            return result.concat(element[3])
          }, head);
        },
        peg$c5 = "||",
        peg$c6 = peg$literalExpectation("||", false),
        peg$c7 = "!",
        peg$c8 = peg$literalExpectation("!", false),
        peg$c9 = function (expr) {
          let result = []
          expr.forEach((el, i, arr) => {
            if (i % 2 === 0) result.push('!' + el, arr[i + 1]);
          });
          return result;
        },
        peg$c10 = function (expr) {
          return expr;
        },
        peg$c11 = "(",
        peg$c12 = peg$literalExpectation("(", false),
        peg$c13 = ")",
        peg$c14 = peg$literalExpectation(")", false),
        peg$c15 = function (expr) {
          return expr;
        },
        peg$c16 = "tcp",
        peg$c17 = peg$literalExpectation("tcp", false),
        peg$c18 = "udp",
        peg$c19 = peg$literalExpectation("udp", false),
        peg$c20 = "arp",
        peg$c21 = peg$literalExpectation("arp", false),
        peg$c22 = "http",
        peg$c23 = peg$literalExpectation("http", false),
        peg$c24 = "dns",
        peg$c25 = peg$literalExpectation("dns", false),
        peg$c26 = function (expr) {
          return [expr, null]
        },
        peg$c27 = function (datetime) {
          return datetime
        },
        peg$c28 = "frame.time",
        peg$c29 = peg$literalExpectation("frame.time", false),
        peg$c30 = function (datetime) {
          return ['startTime', datetime]
        },
        peg$c31 = function (datetime) {
          return ['endTime', datetime]
        },
        peg$c32 = peg$otherExpectation("%b %d, %Y %H:%M:%S"),
        peg$c33 = "\"",
        peg$c34 = peg$literalExpectation("\"", false),
        peg$c35 = ",",
        peg$c36 = peg$literalExpectation(",", false),
        peg$c37 = ":",
        peg$c38 = peg$literalExpectation(":", false),
        peg$c39 = ".",
        peg$c40 = peg$literalExpectation(".", false),
        peg$c41 = "'",
        peg$c42 = peg$literalExpectation("'", false),
        peg$c43 = "tcp.srcport",
        peg$c44 = peg$literalExpectation("tcp.srcport", false),
        peg$c45 = "udp.srcport",
        peg$c46 = peg$literalExpectation("udp.srcport", false),
        peg$c47 = function (port) {
          return ['srcPort', port]
        },
        peg$c48 = "tcp.dstport",
        peg$c49 = peg$literalExpectation("tcp.dstport", false),
        peg$c50 = "udp.dstport",
        peg$c51 = peg$literalExpectation("udp.dstport", false),
        peg$c52 = function (port) {
          return ['destPort', port]
        },
        peg$c53 = "ip.dst",
        peg$c54 = peg$literalExpectation("ip.dst", false),
        peg$c55 = function (ip) {
          return ['destIP', ip]
        },
        peg$c56 = "ip.src",
        peg$c57 = peg$literalExpectation("ip.src", false),
        peg$c58 = function (ip) {
          return ['srcIP', ip]
        },
        peg$c59 = "25",
        peg$c60 = peg$literalExpectation("25", false),
        peg$c61 = /^[0-5]/,
        peg$c62 = peg$classExpectation([["0", "5"]], false, false),
        peg$c63 = "2",
        peg$c64 = peg$literalExpectation("2", false),
        peg$c65 = /^[0-4]/,
        peg$c66 = peg$classExpectation([["0", "4"]], false, false),
        peg$c67 = "1",
        peg$c68 = peg$literalExpectation("1", false),
        peg$c69 = "==",
        peg$c70 = peg$literalExpectation("==", false),
        peg$c71 = "<=",
        peg$c72 = peg$literalExpectation("<=", false),
        peg$c73 = ">=",
        peg$c74 = peg$literalExpectation(">=", false),
        peg$c75 = /^[0-9]/,
        peg$c76 = peg$classExpectation([["0", "9"]], false, false),
        peg$c77 = /^[A-Za-z]/,
        peg$c78 = peg$classExpectation([["A", "Z"], ["a", "z"]], false, false),
        peg$c79 = peg$otherExpectation("whitespace"),
        peg$c80 = /^[ \t\n\r]/,
        peg$c81 = peg$classExpectation([" ", "\t", "\n", "\r"], false, false),

        peg$currPos = 0,
        peg$savedPos = 0,
        peg$posDetailsCache = [{line: 1, column: 1}],
        peg$maxFailPos = 0,
        peg$maxFailExpected = [],
        peg$silentFails = 0,

        peg$result;

      if ("startRule" in options) {
        if (!(options.startRule in peg$startRuleFunctions)) {
          throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
        }

        peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
      }

      function text() {
        return input.substring(peg$savedPos, peg$currPos);
      }

      function location() {
        return peg$computeLocation(peg$savedPos, peg$currPos);
      }

      function expected(description, location) {
        location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

        throw peg$buildStructuredError(
          [peg$otherExpectation(description)],
          input.substring(peg$savedPos, peg$currPos),
          location
        );
      }

      function error(message, location) {
        location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

        throw peg$buildSimpleError(message, location);
      }

      function peg$literalExpectation(text, ignoreCase) {
        return {type: "literal", text: text, ignoreCase: ignoreCase};
      }

      function peg$classExpectation(parts, inverted, ignoreCase) {
        return {type: "class", parts: parts, inverted: inverted, ignoreCase: ignoreCase};
      }

      function peg$anyExpectation() {
        return {type: "any"};
      }

      function peg$endExpectation() {
        return {type: "end"};
      }

      function peg$otherExpectation(description) {
        return {type: "other", description: description};
      }

      function peg$computePosDetails(pos) {
        var details = peg$posDetailsCache[pos], p;

        if (details) {
          return details;
        } else {
          p = pos - 1;
          while (!peg$posDetailsCache[p]) {
            p--;
          }

          details = peg$posDetailsCache[p];
          details = {
            line: details.line,
            column: details.column
          };

          while (p < pos) {
            if (input.charCodeAt(p) === 10) {
              details.line++;
              details.column = 1;
            } else {
              details.column++;
            }

            p++;
          }

          peg$posDetailsCache[pos] = details;
          return details;
        }
      }

      function peg$computeLocation(startPos, endPos) {
        var startPosDetails = peg$computePosDetails(startPos),
          endPosDetails = peg$computePosDetails(endPos);

        return {
          start: {
            offset: startPos,
            line: startPosDetails.line,
            column: startPosDetails.column
          },
          end: {
            offset: endPos,
            line: endPosDetails.line,
            column: endPosDetails.column
          }
        };
      }

      function peg$fail(expected) {
        if (peg$currPos < peg$maxFailPos) {
          return;
        }

        if (peg$currPos > peg$maxFailPos) {
          peg$maxFailPos = peg$currPos;
          peg$maxFailExpected = [];
        }

        peg$maxFailExpected.push(expected);
      }

      function peg$buildSimpleError(message, location) {
        return new peg$SyntaxError(message, null, null, location);
      }

      function peg$buildStructuredError(expected, found, location) {
        return new peg$SyntaxError(
          peg$SyntaxError.buildMessage(expected, found),
          expected,
          found,
          location
        );
      }

      function peg$parseMainQuery() {
        var s0, s1;

        s0 = peg$currPos;
        s1 = peg$parseQuery();
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c0(s1);
        }
        s0 = s1;

        return s0;
      }

      function peg$parseQuery() {
        var s0, s1, s2, s3;

        s0 = peg$currPos;
        s1 = peg$parse_();
        if (s1 !== peg$FAILED) {
          s2 = peg$parseAndExpr();
          if (s2 !== peg$FAILED) {
            s3 = peg$parse_();
            if (s3 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c1(s2);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }

        return s0;
      }

      function peg$parseAndExpr() {
        var s0, s1, s2, s3, s4, s5, s6, s7;

        s0 = peg$currPos;
        s1 = peg$parseOrExpr();
        if (s1 !== peg$FAILED) {
          s2 = [];
          s3 = peg$currPos;
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c2) {
              s5 = peg$c2;
              peg$currPos += 2;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$c3);
              }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseOrExpr();
                if (s7 !== peg$FAILED) {
                  s4 = [s4, s5, s6, s7];
                  s3 = s4;
                } else {
                  peg$currPos = s3;
                  s3 = peg$FAILED;
                }
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
          while (s3 !== peg$FAILED) {
            s2.push(s3);
            s3 = peg$currPos;
            s4 = peg$parse_();
            if (s4 !== peg$FAILED) {
              if (input.substr(peg$currPos, 2) === peg$c2) {
                s5 = peg$c2;
                peg$currPos += 2;
              } else {
                s5 = peg$FAILED;
                if (peg$silentFails === 0) {
                  peg$fail(peg$c3);
                }
              }
              if (s5 !== peg$FAILED) {
                s6 = peg$parse_();
                if (s6 !== peg$FAILED) {
                  s7 = peg$parseOrExpr();
                  if (s7 !== peg$FAILED) {
                    s4 = [s4, s5, s6, s7];
                    s3 = s4;
                  } else {
                    peg$currPos = s3;
                    s3 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s3;
                  s3 = peg$FAILED;
                }
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          }
          if (s2 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c4(s1, s2);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }

        return s0;
      }

      function peg$parseOrExpr() {
        var s0, s1, s2, s3, s4, s5, s6, s7, s8;

        s0 = peg$currPos;
        s1 = peg$parse_();
        if (s1 !== peg$FAILED) {
          s2 = peg$parseNotExpr();
          if (s2 !== peg$FAILED) {
            s3 = [];
            s4 = peg$currPos;
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 2) === peg$c5) {
                s6 = peg$c5;
                peg$currPos += 2;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) {
                  peg$fail(peg$c6);
                }
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parseNotExpr();
                  if (s8 !== peg$FAILED) {
                    s5 = [s5, s6, s7, s8];
                    s4 = s5;
                  } else {
                    peg$currPos = s4;
                    s4 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s4;
                  s4 = peg$FAILED;
                }
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
            while (s4 !== peg$FAILED) {
              s3.push(s4);
              s4 = peg$currPos;
              s5 = peg$parse_();
              if (s5 !== peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c5) {
                  s6 = peg$c5;
                  peg$currPos += 2;
                } else {
                  s6 = peg$FAILED;
                  if (peg$silentFails === 0) {
                    peg$fail(peg$c6);
                  }
                }
                if (s6 !== peg$FAILED) {
                  s7 = peg$parse_();
                  if (s7 !== peg$FAILED) {
                    s8 = peg$parseNotExpr();
                    if (s8 !== peg$FAILED) {
                      s5 = [s5, s6, s7, s8];
                      s4 = s5;
                    } else {
                      peg$currPos = s4;
                      s4 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s4;
                    s4 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s4;
                  s4 = peg$FAILED;
                }
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            }
            if (s3 !== peg$FAILED) {
              s4 = peg$parse_();
              if (s4 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c4(s2, s3);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }

        return s0;
      }

      function peg$parseNotExpr() {
        var s0, s1, s2, s3, s4, s5;

        s0 = peg$currPos;
        s1 = peg$parse_();
        if (s1 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 33) {
            s2 = peg$c7;
            peg$currPos++;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$c8);
            }
          }
          if (s2 !== peg$FAILED) {
            s3 = peg$parse_();
            if (s3 !== peg$FAILED) {
              s4 = peg$parseNotExpr();
              if (s4 !== peg$FAILED) {
                s5 = peg$parse_();
                if (s5 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c9(s4);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parse_();
          if (s1 !== peg$FAILED) {
            s2 = peg$parseExpr();
            if (s2 !== peg$FAILED) {
              s3 = peg$parse_();
              if (s3 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c10(s2);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        }

        return s0;
      }

      function peg$parseExpr() {
        var s0, s1, s2, s3, s4, s5, s6, s7;

        s0 = peg$currPos;
        s1 = peg$parse_();
        if (s1 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 40) {
            s2 = peg$c11;
            peg$currPos++;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$c12);
            }
          }
          if (s2 !== peg$FAILED) {
            s3 = peg$parse_();
            if (s3 !== peg$FAILED) {
              s4 = peg$parseQuery();
              if (s4 !== peg$FAILED) {
                s5 = peg$parse_();
                if (s5 !== peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 41) {
                    s6 = peg$c13;
                    peg$currPos++;
                  } else {
                    s6 = peg$FAILED;
                    if (peg$silentFails === 0) {
                      peg$fail(peg$c14);
                    }
                  }
                  if (s6 !== peg$FAILED) {
                    s7 = peg$parse_();
                    if (s7 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c15(s4);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parseFilter();
          if (s1 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c15(s1);
          }
          s0 = s1;
        }

        return s0;
      }

      function peg$parseFilter() {
        var s0;

        s0 = peg$parsesrcIP();
        if (s0 === peg$FAILED) {
          s0 = peg$parsedestIP();
          if (s0 === peg$FAILED) {
            s0 = peg$parsesrcPort();
            if (s0 === peg$FAILED) {
              s0 = peg$parsedestPort();
              if (s0 === peg$FAILED) {
                s0 = peg$parseprotocol();
              }
            }
          }
        }

        return s0;
      }

      function peg$parseprotocol() {
        var s0, s1, s2;

        s0 = peg$currPos;
        s1 = peg$currPos;
        if (input.substr(peg$currPos, 3) === peg$c16) {
          s2 = peg$c16;
          peg$currPos += 3;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c17);
          }
        }
        if (s2 === peg$FAILED) {
          if (input.substr(peg$currPos, 3) === peg$c18) {
            s2 = peg$c18;
            peg$currPos += 3;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$c19);
            }
          }
          if (s2 === peg$FAILED) {
            if (input.substr(peg$currPos, 3) === peg$c20) {
              s2 = peg$c20;
              peg$currPos += 3;
            } else {
              s2 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$c21);
              }
            }
            if (s2 === peg$FAILED) {
              if (input.substr(peg$currPos, 4) === peg$c22) {
                s2 = peg$c22;
                peg$currPos += 4;
              } else {
                s2 = peg$FAILED;
                if (peg$silentFails === 0) {
                  peg$fail(peg$c23);
                }
              }
              if (s2 === peg$FAILED) {
                if (input.substr(peg$currPos, 3) === peg$c24) {
                  s2 = peg$c24;
                  peg$currPos += 3;
                } else {
                  s2 = peg$FAILED;
                  if (peg$silentFails === 0) {
                    peg$fail(peg$c25);
                  }
                }
              }
            }
          }
        }
        if (s2 !== peg$FAILED) {
          s1 = input.substring(s1, peg$currPos);
        } else {
          s1 = s2;
        }
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c26(s1);
        }
        s0 = s1;
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parseFrameTime();
          if (s1 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c27(s1);
          }
          s0 = s1;
        }

        return s0;
      }

      function peg$parseFrameTime() {
        var s0, s1;

        s0 = peg$currPos;
        s1 = peg$parseframeStart();
        if (s1 === peg$FAILED) {
          s1 = peg$parseframeEnd();
        }
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c1(s1);
        }
        s0 = s1;

        return s0;
      }

      function peg$parseframeStart() {
        var s0, s1, s2, s3;

        s0 = peg$currPos;
        if (input.substr(peg$currPos, 10) === peg$c28) {
          s1 = peg$c28;
          peg$currPos += 10;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c29);
          }
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parsege();
          if (s2 !== peg$FAILED) {
            s3 = peg$parsedatetime();
            if (s3 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c30(s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }

        return s0;
      }

      function peg$parseframeEnd() {
        var s0, s1, s2, s3;

        s0 = peg$currPos;
        if (input.substr(peg$currPos, 10) === peg$c28) {
          s1 = peg$c28;
          peg$currPos += 10;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c29);
          }
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parsele();
          if (s2 !== peg$FAILED) {
            s3 = peg$parsedatetime();
            if (s3 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c31(s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }

        return s0;
      }

      function peg$parsedatetime() {
        var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16, s17, s18, s19, s20, s21, s22,
          s23, s24, s25, s26, s27, s28, s29;

        peg$silentFails++;
        s0 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 34) {
          s1 = peg$c33;
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c34);
          }
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$currPos;
          s3 = peg$currPos;
          s4 = peg$parseALPHA();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseALPHA();
            if (s5 !== peg$FAILED) {
              s6 = peg$parseALPHA();
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parseDIGIT();
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseDIGIT();
                    if (s9 !== peg$FAILED) {
                      if (input.charCodeAt(peg$currPos) === 44) {
                        s10 = peg$c35;
                        peg$currPos++;
                      } else {
                        s10 = peg$FAILED;
                        if (peg$silentFails === 0) {
                          peg$fail(peg$c36);
                        }
                      }
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parse_();
                        if (s11 !== peg$FAILED) {
                          s12 = peg$parseDIGIT();
                          if (s12 !== peg$FAILED) {
                            s13 = peg$parseDIGIT();
                            if (s13 !== peg$FAILED) {
                              s14 = peg$parseDIGIT();
                              if (s14 !== peg$FAILED) {
                                s15 = peg$parseDIGIT();
                                if (s15 !== peg$FAILED) {
                                  s16 = peg$parse_();
                                  if (s16 !== peg$FAILED) {
                                    s17 = peg$parseDIGIT();
                                    if (s17 !== peg$FAILED) {
                                      s18 = peg$parseDIGIT();
                                      if (s18 !== peg$FAILED) {
                                        if (input.charCodeAt(peg$currPos) === 58) {
                                          s19 = peg$c37;
                                          peg$currPos++;
                                        } else {
                                          s19 = peg$FAILED;
                                          if (peg$silentFails === 0) {
                                            peg$fail(peg$c38);
                                          }
                                        }
                                        if (s19 !== peg$FAILED) {
                                          s20 = peg$parseDIGIT();
                                          if (s20 !== peg$FAILED) {
                                            s21 = peg$parseDIGIT();
                                            if (s21 !== peg$FAILED) {
                                              if (input.charCodeAt(peg$currPos) === 58) {
                                                s22 = peg$c37;
                                                peg$currPos++;
                                              } else {
                                                s22 = peg$FAILED;
                                                if (peg$silentFails === 0) {
                                                  peg$fail(peg$c38);
                                                }
                                              }
                                              if (s22 !== peg$FAILED) {
                                                s23 = peg$parseDIGIT();
                                                if (s23 !== peg$FAILED) {
                                                  s24 = peg$parseDIGIT();
                                                  if (s24 !== peg$FAILED) {
                                                    s25 = peg$currPos;
                                                    if (input.charCodeAt(peg$currPos) === 46) {
                                                      s26 = peg$c39;
                                                      peg$currPos++;
                                                    } else {
                                                      s26 = peg$FAILED;
                                                      if (peg$silentFails === 0) {
                                                        peg$fail(peg$c40);
                                                      }
                                                    }
                                                    if (s26 !== peg$FAILED) {
                                                      s27 = peg$parseDIGIT();
                                                      if (s27 !== peg$FAILED) {
                                                        s28 = [];
                                                        s29 = peg$parseDIGIT();
                                                        while (s29 !== peg$FAILED) {
                                                          s28.push(s29);
                                                          s29 = peg$parseDIGIT();
                                                        }
                                                        if (s28 !== peg$FAILED) {
                                                          s26 = [s26, s27, s28];
                                                          s25 = s26;
                                                        } else {
                                                          peg$currPos = s25;
                                                          s25 = peg$FAILED;
                                                        }
                                                      } else {
                                                        peg$currPos = s25;
                                                        s25 = peg$FAILED;
                                                      }
                                                    } else {
                                                      peg$currPos = s25;
                                                      s25 = peg$FAILED;
                                                    }
                                                    if (s25 === peg$FAILED) {
                                                      s25 = null;
                                                    }
                                                    if (s25 !== peg$FAILED) {
                                                      s4 = [s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16, s17, s18, s19, s20, s21, s22, s23, s24, s25];
                                                      s3 = s4;
                                                    } else {
                                                      peg$currPos = s3;
                                                      s3 = peg$FAILED;
                                                    }
                                                  } else {
                                                    peg$currPos = s3;
                                                    s3 = peg$FAILED;
                                                  }
                                                } else {
                                                  peg$currPos = s3;
                                                  s3 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s3;
                                                s3 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s3;
                                              s3 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s3;
                                            s3 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s3;
                                          s3 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s3;
                                        s3 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s3;
                                      s3 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s3;
                                    s3 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s3;
                                  s3 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s3;
                                s3 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s3;
                              s3 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s3;
                            s3 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s3;
                          s3 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s3;
                        s3 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s3;
                      s3 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s3;
                    s3 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s3;
                  s3 = peg$FAILED;
                }
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
          if (s3 !== peg$FAILED) {
            s2 = input.substring(s2, peg$currPos);
          } else {
            s2 = s3;
          }
          if (s2 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 34) {
              s3 = peg$c33;
              peg$currPos++;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$c34);
              }
            }
            if (s3 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c1(s2);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 39) {
            s1 = peg$c41;
            peg$currPos++;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$c42);
            }
          }
          if (s1 !== peg$FAILED) {
            s2 = peg$currPos;
            s3 = peg$currPos;
            s4 = peg$parseALPHA();
            if (s4 !== peg$FAILED) {
              s5 = peg$parseALPHA();
              if (s5 !== peg$FAILED) {
                s6 = peg$parseALPHA();
                if (s6 !== peg$FAILED) {
                  s7 = peg$parse_();
                  if (s7 !== peg$FAILED) {
                    s8 = peg$parseDIGIT();
                    if (s8 !== peg$FAILED) {
                      s9 = peg$parseDIGIT();
                      if (s9 !== peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 44) {
                          s10 = peg$c35;
                          peg$currPos++;
                        } else {
                          s10 = peg$FAILED;
                          if (peg$silentFails === 0) {
                            peg$fail(peg$c36);
                          }
                        }
                        if (s10 !== peg$FAILED) {
                          s11 = peg$parse_();
                          if (s11 !== peg$FAILED) {
                            s12 = peg$parseDIGIT();
                            if (s12 !== peg$FAILED) {
                              s13 = peg$parseDIGIT();
                              if (s13 !== peg$FAILED) {
                                s14 = peg$parseDIGIT();
                                if (s14 !== peg$FAILED) {
                                  s15 = peg$parseDIGIT();
                                  if (s15 !== peg$FAILED) {
                                    s16 = peg$parse_();
                                    if (s16 !== peg$FAILED) {
                                      s17 = peg$parseDIGIT();
                                      if (s17 !== peg$FAILED) {
                                        s18 = peg$parseDIGIT();
                                        if (s18 !== peg$FAILED) {
                                          if (input.charCodeAt(peg$currPos) === 58) {
                                            s19 = peg$c37;
                                            peg$currPos++;
                                          } else {
                                            s19 = peg$FAILED;
                                            if (peg$silentFails === 0) {
                                              peg$fail(peg$c38);
                                            }
                                          }
                                          if (s19 !== peg$FAILED) {
                                            s20 = peg$parseDIGIT();
                                            if (s20 !== peg$FAILED) {
                                              s21 = peg$parseDIGIT();
                                              if (s21 !== peg$FAILED) {
                                                if (input.charCodeAt(peg$currPos) === 58) {
                                                  s22 = peg$c37;
                                                  peg$currPos++;
                                                } else {
                                                  s22 = peg$FAILED;
                                                  if (peg$silentFails === 0) {
                                                    peg$fail(peg$c38);
                                                  }
                                                }
                                                if (s22 !== peg$FAILED) {
                                                  s23 = peg$parseDIGIT();
                                                  if (s23 !== peg$FAILED) {
                                                    s24 = peg$parseDIGIT();
                                                    if (s24 !== peg$FAILED) {
                                                      s25 = peg$currPos;
                                                      if (input.charCodeAt(peg$currPos) === 46) {
                                                        s26 = peg$c39;
                                                        peg$currPos++;
                                                      } else {
                                                        s26 = peg$FAILED;
                                                        if (peg$silentFails === 0) {
                                                          peg$fail(peg$c40);
                                                        }
                                                      }
                                                      if (s26 !== peg$FAILED) {
                                                        s27 = peg$parseDIGIT();
                                                        if (s27 !== peg$FAILED) {
                                                          s28 = [];
                                                          s29 = peg$parseDIGIT();
                                                          while (s29 !== peg$FAILED) {
                                                            s28.push(s29);
                                                            s29 = peg$parseDIGIT();
                                                          }
                                                          if (s28 !== peg$FAILED) {
                                                            s26 = [s26, s27, s28];
                                                            s25 = s26;
                                                          } else {
                                                            peg$currPos = s25;
                                                            s25 = peg$FAILED;
                                                          }
                                                        } else {
                                                          peg$currPos = s25;
                                                          s25 = peg$FAILED;
                                                        }
                                                      } else {
                                                        peg$currPos = s25;
                                                        s25 = peg$FAILED;
                                                      }
                                                      if (s25 === peg$FAILED) {
                                                        s25 = null;
                                                      }
                                                      if (s25 !== peg$FAILED) {
                                                        s4 = [s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16, s17, s18, s19, s20, s21, s22, s23, s24, s25];
                                                        s3 = s4;
                                                      } else {
                                                        peg$currPos = s3;
                                                        s3 = peg$FAILED;
                                                      }
                                                    } else {
                                                      peg$currPos = s3;
                                                      s3 = peg$FAILED;
                                                    }
                                                  } else {
                                                    peg$currPos = s3;
                                                    s3 = peg$FAILED;
                                                  }
                                                } else {
                                                  peg$currPos = s3;
                                                  s3 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s3;
                                                s3 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s3;
                                              s3 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s3;
                                            s3 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s3;
                                          s3 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s3;
                                        s3 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s3;
                                      s3 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s3;
                                    s3 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s3;
                                  s3 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s3;
                                s3 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s3;
                              s3 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s3;
                            s3 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s3;
                          s3 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s3;
                        s3 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s3;
                      s3 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s3;
                    s3 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s3;
                  s3 = peg$FAILED;
                }
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
            if (s3 !== peg$FAILED) {
              s2 = input.substring(s2, peg$currPos);
            } else {
              s2 = s3;
            }
            if (s2 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 39) {
                s3 = peg$c41;
                peg$currPos++;
              } else {
                s3 = peg$FAILED;
                if (peg$silentFails === 0) {
                  peg$fail(peg$c42);
                }
              }
              if (s3 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c1(s2);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        }
        peg$silentFails--;
        if (s0 === peg$FAILED) {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c32);
          }
        }

        return s0;
      }

      function peg$parsesrcPort() {
        var s0, s1, s2, s3;

        s0 = peg$currPos;
        if (input.substr(peg$currPos, 11) === peg$c43) {
          s1 = peg$c43;
          peg$currPos += 11;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c44);
          }
        }
        if (s1 === peg$FAILED) {
          if (input.substr(peg$currPos, 11) === peg$c45) {
            s1 = peg$c45;
            peg$currPos += 11;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$c46);
            }
          }
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parseeq();
          if (s2 !== peg$FAILED) {
            s3 = peg$parseport();
            if (s3 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c47(s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }

        return s0;
      }

      function peg$parsedestPort() {
        var s0, s1, s2, s3;

        s0 = peg$currPos;
        if (input.substr(peg$currPos, 11) === peg$c48) {
          s1 = peg$c48;
          peg$currPos += 11;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c49);
          }
        }
        if (s1 === peg$FAILED) {
          if (input.substr(peg$currPos, 11) === peg$c50) {
            s1 = peg$c50;
            peg$currPos += 11;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$c51);
            }
          }
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parseeq();
          if (s2 !== peg$FAILED) {
            s3 = peg$parseport();
            if (s3 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c52(s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }

        return s0;
      }

      function peg$parsedestIP() {
        var s0, s1, s2, s3;

        s0 = peg$currPos;
        if (input.substr(peg$currPos, 6) === peg$c53) {
          s1 = peg$c53;
          peg$currPos += 6;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c54);
          }
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parseeq();
          if (s2 !== peg$FAILED) {
            s3 = peg$parseIPv4address();
            if (s3 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c55(s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }

        return s0;
      }

      function peg$parsesrcIP() {
        var s0, s1, s2, s3;

        s0 = peg$currPos;
        if (input.substr(peg$currPos, 6) === peg$c56) {
          s1 = peg$c56;
          peg$currPos += 6;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c57);
          }
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parseeq();
          if (s2 !== peg$FAILED) {
            s3 = peg$parseIPv4address();
            if (s3 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c58(s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }

        return s0;
      }

      function peg$parseIPv4address() {
        var s0, s1, s2, s3, s4, s5, s6, s7, s8;

        s0 = peg$currPos;
        s1 = peg$currPos;
        s2 = peg$parsedec_octet();
        if (s2 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 46) {
            s3 = peg$c39;
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$c40);
            }
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parsedec_octet();
            if (s4 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 46) {
                s5 = peg$c39;
                peg$currPos++;
              } else {
                s5 = peg$FAILED;
                if (peg$silentFails === 0) {
                  peg$fail(peg$c40);
                }
              }
              if (s5 !== peg$FAILED) {
                s6 = peg$parsedec_octet();
                if (s6 !== peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 46) {
                    s7 = peg$c39;
                    peg$currPos++;
                  } else {
                    s7 = peg$FAILED;
                    if (peg$silentFails === 0) {
                      peg$fail(peg$c40);
                    }
                  }
                  if (s7 !== peg$FAILED) {
                    s8 = peg$parsedec_octet();
                    if (s8 !== peg$FAILED) {
                      s2 = [s2, s3, s4, s5, s6, s7, s8];
                      s1 = s2;
                    } else {
                      peg$currPos = s1;
                      s1 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s1;
                    s1 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s1;
                  s1 = peg$FAILED;
                }
              } else {
                peg$currPos = s1;
                s1 = peg$FAILED;
              }
            } else {
              peg$currPos = s1;
              s1 = peg$FAILED;
            }
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
        if (s1 !== peg$FAILED) {
          s0 = input.substring(s0, peg$currPos);
        } else {
          s0 = s1;
        }

        return s0;
      }

      function peg$parsedec_octet() {
        var s0, s1, s2, s3, s4;

        s0 = peg$currPos;
        s1 = peg$currPos;
        if (input.substr(peg$currPos, 2) === peg$c59) {
          s2 = peg$c59;
          peg$currPos += 2;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c60);
          }
        }
        if (s2 !== peg$FAILED) {
          if (peg$c61.test(input.charAt(peg$currPos))) {
            s3 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$c62);
            }
          }
          if (s3 !== peg$FAILED) {
            s2 = [s2, s3];
            s1 = s2;
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
        if (s1 === peg$FAILED) {
          s1 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 50) {
            s2 = peg$c63;
            peg$currPos++;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$c64);
            }
          }
          if (s2 !== peg$FAILED) {
            if (peg$c65.test(input.charAt(peg$currPos))) {
              s3 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$c66);
              }
            }
            if (s3 !== peg$FAILED) {
              s4 = peg$parseDIGIT();
              if (s4 !== peg$FAILED) {
                s2 = [s2, s3, s4];
                s1 = s2;
              } else {
                peg$currPos = s1;
                s1 = peg$FAILED;
              }
            } else {
              peg$currPos = s1;
              s1 = peg$FAILED;
            }
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
          if (s1 === peg$FAILED) {
            s1 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 49) {
              s2 = peg$c67;
              peg$currPos++;
            } else {
              s2 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$c68);
              }
            }
            if (s2 !== peg$FAILED) {
              s3 = peg$parseDIGIT();
              if (s3 !== peg$FAILED) {
                s4 = peg$parseDIGIT();
                if (s4 !== peg$FAILED) {
                  s2 = [s2, s3, s4];
                  s1 = s2;
                } else {
                  peg$currPos = s1;
                  s1 = peg$FAILED;
                }
              } else {
                peg$currPos = s1;
                s1 = peg$FAILED;
              }
            } else {
              peg$currPos = s1;
              s1 = peg$FAILED;
            }
            if (s1 === peg$FAILED) {
              s1 = peg$currPos;
              s2 = peg$parseDIGIT();
              if (s2 !== peg$FAILED) {
                s3 = peg$parseDIGIT();
                if (s3 !== peg$FAILED) {
                  s2 = [s2, s3];
                  s1 = s2;
                } else {
                  peg$currPos = s1;
                  s1 = peg$FAILED;
                }
              } else {
                peg$currPos = s1;
                s1 = peg$FAILED;
              }
              if (s1 === peg$FAILED) {
                s1 = peg$parseDIGIT();
              }
            }
          }
        }
        if (s1 !== peg$FAILED) {
          s0 = input.substring(s0, peg$currPos);
        } else {
          s0 = s1;
        }

        return s0;
      }

      function peg$parseport() {
        var s0, s1, s2;

        s0 = peg$currPos;
        s1 = [];
        s2 = peg$parseDIGIT();
        while (s2 !== peg$FAILED) {
          s1.push(s2);
          s2 = peg$parseDIGIT();
        }
        if (s1 !== peg$FAILED) {
          s0 = input.substring(s0, peg$currPos);
        } else {
          s0 = s1;
        }

        return s0;
      }

      function peg$parseeq() {
        var s0, s1, s2, s3;

        s0 = peg$currPos;
        s1 = peg$parse_();
        if (s1 !== peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c69) {
            s2 = peg$c69;
            peg$currPos += 2;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$c70);
            }
          }
          if (s2 !== peg$FAILED) {
            s3 = peg$parse_();
            if (s3 !== peg$FAILED) {
              s1 = [s1, s2, s3];
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }

        return s0;
      }

      function peg$parsele() {
        var s0, s1, s2, s3;

        s0 = peg$currPos;
        s1 = peg$parse_();
        if (s1 !== peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c71) {
            s2 = peg$c71;
            peg$currPos += 2;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$c72);
            }
          }
          if (s2 !== peg$FAILED) {
            s3 = peg$parse_();
            if (s3 !== peg$FAILED) {
              s1 = [s1, s2, s3];
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }

        return s0;
      }

      function peg$parsege() {
        var s0, s1, s2, s3;

        s0 = peg$currPos;
        s1 = peg$parse_();
        if (s1 !== peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c73) {
            s2 = peg$c73;
            peg$currPos += 2;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$c74);
            }
          }
          if (s2 !== peg$FAILED) {
            s3 = peg$parse_();
            if (s3 !== peg$FAILED) {
              s1 = [s1, s2, s3];
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }

        return s0;
      }

      function peg$parseDIGIT() {
        var s0;

        if (peg$c75.test(input.charAt(peg$currPos))) {
          s0 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c76);
          }
        }

        return s0;
      }

      function peg$parseALPHA() {
        var s0;

        if (peg$c77.test(input.charAt(peg$currPos))) {
          s0 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c78);
          }
        }

        return s0;
      }

      function peg$parse_() {
        var s0, s1;

        peg$silentFails++;
        s0 = [];
        if (peg$c80.test(input.charAt(peg$currPos))) {
          s1 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c81);
          }
        }
        while (s1 !== peg$FAILED) {
          s0.push(s1);
          if (peg$c80.test(input.charAt(peg$currPos))) {
            s1 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$c81);
            }
          }
        }
        peg$silentFails--;
        if (s0 === peg$FAILED) {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c79);
          }
        }

        return s0;
      }

      peg$result = peg$startRuleFunction();

      if (peg$result !== peg$FAILED && peg$currPos === input.length) {
        return peg$result;
      } else {
        if (peg$result !== peg$FAILED && peg$currPos < input.length) {
          peg$fail(peg$endExpectation());
        }

        throw peg$buildStructuredError(
          peg$maxFailExpected,
          peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
          peg$maxFailPos < input.length
            ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
            : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
        );
      }
    }

    return {
      SyntaxError: peg$SyntaxError,
      parse: peg$parse
    };
  })();
