import Node from "./Node";
import ForceGraph from "./ForceGraph";

interface INodeArray {
  graph: ForceGraph;
}

// The Array of graph nodes in the tree
export default class NodeArray extends Array<Node> implements INodeArray {
  graph: ForceGraph;

  constructor(graph: ForceGraph) {
    super();
    this.graph = graph;
  }

  // Returns a node by given name (IP or port)
  getNode(value: string) {
    return this.find((node) => node.value === value);
  }

  // Returns whether the array contains a specific node
  containsNode(value: string) {
    return !!this.find((node) => node.value === value);
  }

  // Removes all nodes and references
  clearAllNodes() {
    this.forEach((node) => {
      this.graph.nodeLayer!.removeChild(node);
      this.graph.labelLayer!.removeChild(node.label);
      node.label.destroy();
      node.clear();
      node.destroy();
    });
  }

  // Updates all labels positions to their attached nodes position
  updateLabelPositions() {
    this.forEach((node) => node.label.updatePosition());
  }

  // Initializes node array from given node data
  initNodesFromData(nodes: Array<any>) {
    nodes.forEach((node) => {
      const nodeObject = new Node(this.graph, node, this.graph.callContextMenu, this.graph.theme);

      this.graph.nodeLayer!.addChild(nodeObject);
      this.graph.labelLayer!.addChild(nodeObject.label);
      this.push(nodeObject);
    });
  }
}
