import { connect } from "react-redux";

import { BarChart } from "./BarChart";
import React, { Component, RefObject } from "react";
import { ApplicationState } from "@store/index";
import { returnType } from "@utils/TypeUtils";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core";
import * as filterActions from "@store/filter/actions";
import { FilterType } from "@store/filter/FilterStatus";
import { FilterAction, FilterField } from "@store/filter/types";

// Definition of injected styles
const styles = (theme: Theme) => createStyles({});

// Component-specific props.
interface ComponentProps {
  theme: Theme; // has to be defined here when using redux connect and forwardRef because https://github.com/mui-org/material-ui/issues/8958#issuecomment-410231896
}

// Redux: Map redux store state to component props
const mapStateToProps = (state: ApplicationState) => ({
  protocolData: state.websocket.protocolData,
  filterState: state.filter,
  user: state.user
});

// Redux: Map action dispatchers to props
const mapDispatchToProps = (dispatch: any) => ({
  updateFilter: (filterType: FilterType, act: FilterAction, field: FilterField, payload: string) =>
    dispatch(filterActions.updateFilter(filterType, act, field, payload))
});

// Props passed from mapStateToProps
const reduxProps = returnType(mapStateToProps);
type PropsFromReduxState = typeof reduxProps;

// Props passed from mapDispatchToProps
const reduxActions = returnType(mapDispatchToProps);
type PropsFromReduxDispatch = typeof reduxActions;

type CombinedProps = PropsFromReduxState &
  PropsFromReduxDispatch &
  ComponentProps &
  WithStyles<typeof styles>;

export class ProtocolComponent extends Component<CombinedProps> {
  public _chart: any;
  public myRef: RefObject<HTMLDivElement>;

  constructor(props: CombinedProps) {
    super(props);
    this.myRef = React.createRef();
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  updateDimensions() {
    this._chart.updateDimensions(this.myRef.current);
  }

  componentWillUnmount(): void {
    window.removeEventListener("resize", this.updateDimensions);
  }

  componentDidMount() {
    this._chart = new BarChart(this.myRef.current, this.props);
    window.addEventListener("resize", this.updateDimensions);

    if (this.props.protocolData.length > 0) {
      this._chart.updateData(this.props.protocolData, this.props.filterState);
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.protocolData != this.props.protocolData) {
      this._chart.updateData(this.props.protocolData, this.props.filterState);
    }
    if (this.props.theme != prevProps.theme) {
      this._chart.updateTheme(this.props.theme, this.props.filterState);
    }
    if (this.props.filterState != prevProps.filterState) {
      this._chart.updateSelection(this.props.filterState);
    }
    if (prevProps.user.showVolumeProtocolVis !== this.props.user.showVolumeProtocolVis) {
      this._chart.updateAxis(this.props.user.showVolumeProtocolVis);
      this.updateDimensions();
    }
    this._chart.up;
  }

  render() {
    return <div style={{ height: "100%" }} ref={this.myRef} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  withStyles(styles, { withTheme: true })(ProtocolComponent)
);
