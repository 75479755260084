import Visualization from "../../../../utils/visualization";

import React from "react";

import * as d3 from "d3";
import d3Tip from "d3-tip";
import { FilterType } from "@store/filter/FilterStatus";
import { FilterAction, FilterField } from "@store/filter/types";
import { bytes2String } from "@utils/HelperFunctions";

export class HorzBarChart extends Visualization {
  constructor(node, parentProps, dataType, options) {
    super(node, parentProps, dataType, options);
    this.dataType = dataType;
  }

  getDefaultOptions() {
    return {
      padding: { top: 0, right: 8, bottom: 24, left: 8 }
    };
  }

  init() {
    this.noData = true;
    this.theme = this.parentProps.theme;
    this.colors = {
      in: this.theme.barColors.incoming,
      out: this.theme.barColors.outgoing,
      intern: this.theme.barColors.internal
    };
    this.colorsSelected = {
      in: this.theme.barColors.incomingSelected,
      out: this.theme.barColors.outgoingSelected,
      intern: this.theme.barColors.internalSelected
    };
    const { padding } = this.options;
    this.keys = ["in", "out", "intern"];
    this.width = this.width - padding.left - padding.right;
    this.height = this.height - padding.bottom - padding.top;
    this.cutOffset = 30;

    this.svg = d3.select(this.node).append("svg").style("width", "100%").style("height", "100%");

    this.stacked = this.svg.append("g");
    this.stacked
      .attr("class", "stacked")
      .attr("height", this.height)
      .attr("width", this.width)
      .attr("transform", "translate(" + padding.left + "," + padding.top + ")");

    this.xScale = d3.scaleLinear().rangeRound([0, this.width]);
    this.yScale = d3.scaleBand().rangeRound([this.height, 0]).padding(0);

    this.format = d3.format(".2s");

    this.xAxis = d3.axisBottom(this.xScale).ticks(5).tickFormat(d3.format(".2s"));
    this.yAxis = d3.axisLeft(this.yScale);

    this.xAxisGroup = this.stacked
      .append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + this.height + ")");

    this.yAxisGroup = this.stacked
      .append("g")
      .attr("class", "y axis")
      .attr("transform", "translate(-1,0)"); // so bars dont cover yaxis

    this.tip = d3Tip()
      .attr("class", "d3-tip")
      // .offset([0, 10])
      .direction("w")
      .html((d, i, data) => {
        if ("data" in d) {
          let name = "";
          let ip = d.data.value;
          if (this.dnsMap && this.dnsMap[ip]) {
            name = this.dnsMap[ip];
            ip = "(" + ip + ")";
          }
          return (
            "<strong>" +
            name +
            " " +
            ip +
            ":" +
            "</strong><br/> " +
            " in: <span style='color:" +
            this.colors.in +
            "'>" +
            this.format(d.data.in) +
            "</span>" +
            " out: <span style='color:" +
            this.colors.out +
            "'>" +
            this.format(d.data.out) +
            "</span>" +
            " intern: <span style='color:" +
            this.colors.intern +
            "'>" +
            this.format(d.data.intern) +
            "</span>"
          );
        } else {
          let name = "";
          let ip = d.value;
          if (this.dnsMap && this.dnsMap[ip]) {
            name = this.dnsMap[ip];
            ip = "(" + ip + ")";
          }
          return (
            "<strong>" +
            name +
            " " +
            ip +
            ":" +
            "</strong><br/> " +
            " in: <span style='color:" +
            this.colors.in +
            "'>" +
            this.format(d.in) +
            "</span>" +
            " out: <span style='color:" +
            this.colors.out +
            "'>" +
            this.format(d.out) +
            "</span>" +
            " intern: <span style='color:" +
            this.colors.intern +
            "'>" +
            this.format(d.intern) +
            "</span>"
          );
        }
      });
    this.svg.call(this.tip);

    this.xAxisGroup.transition().duration(500).call(this.xAxis);

    this.yAxisGroup.transition().duration(500).call(this.yAxis);
  }

  updateData(data, filterState) {
    this.noData = false;
    data = this.formatData(data);
    this.filterState = filterState;

    let labels = data.map((x) => x.value);
    let maxVolume = d3.max(data, function (d) {
      return d.in + d.out + d.intern;
    });
    let xDom = this.xScale.domain([0, maxVolume]).nice();
    let yDom = this.yScale.domain(labels.reverse());
    this.xDom = xDom;
    this.yDom = yDom;

    this.xAxis.scale(xDom).ticks(5);
    this.yAxis.scale(yDom);
    this.stacked.select(".x").transition().duration(500).call(this.xAxis);

    this.stacked.select(".y").transition().duration(500).call(this.yAxis);

    // this.yAxisGroup
    // .selectAll(".tick")
    // .on('mouseover', (d, i, n) => this.tip.show(d, i, data, n[i]))
    // .on('mouseout', this.tip.hide);

    let colors = this.colors;
    let colorsSelected = this.colorsSelected;

    let stack = d3.stack().keys(this.keys).order(d3.stackOrderNone).offset(d3.stackOffsetNone);

    let stacked = this.stacked;

    // let barWidth = this.height / data.length;

    this.keys.forEach((key, key_index) => {
      let bar = stacked.selectAll(".bar-" + key).data(stack(data)[key_index], function (d) {
        return d.data.value + "-" + key;
      });

      bar.exit().transition().duration(500).attr("x", 0).attr("width", 0).remove();

      // modifiy existing bars
      bar
        .transition()
        .duration(500)
        .attr("height", yDom.bandwidth())
        .attr("y", function (d) {
          return yDom(d.data.value);
        })
        .attr("x", function (d) {
          return xDom(d[0]);
        })
        .attr("width", function (d) {
          return xDom(d[1]) - xDom(d[0]);
        });

      // modifiy new bars

      bar
        .enter()
        .append("rect")
        .attr("class", "bar bar-" + key)
        .attr("x", 0)
        .attr("width", 0)
        .attr("height", yDom.bandwidth())
        .on("mouseover", (d, i, n) => this.tip.show(d, i, data, n[i]))
        .on("mouseout", this.tip.hide)
        .attr("y", function (d) {
          return yDom(d.data.value);
        })
        .attr("fill", (d) => {
          if (this.dataType === "src") {
            if (this.mode === "ip") {
              if (filterState.includeFilter.srcIP.includes(d.data.value)) {
                return colorsSelected[key];
              } else {
                return colors[key];
              }
            }
            if (this.mode === "port") {
              if (filterState.includeFilter.srcPort.includes(d.data.value)) {
                return colorsSelected[key];
              } else {
                return colors[key];
              }
            }
          }
          if (this.dataType === "dest") {
            if (this.mode === "ip") {
              if (filterState.includeFilter.destIP.includes(d.data.value)) {
                return colorsSelected[key];
              } else {
                return colors[key];
              }
            }
            if (this.mode === "port") {
              if (filterState.includeFilter.destPort.includes(d.data.value)) {
                return colorsSelected[key];
              } else {
                return colors[key];
              }
            }
          }
        })
        .transition()
        .duration(500)
        .attr("x", function (d) {
          return xDom(d[0]);
        })
        .attr("width", function (d) {
          return xDom(d[1]) - xDom(d[0]);
        });
    });

    const infoLayer = this.svg.selectAll(".infoLayer").data(data);
    this.infoLayer = infoLayer;
    infoLayer.exit().remove();

    const groupsEnter = infoLayer
      .enter()
      .append("g")
      .attr("class", "infoLayer")
      .attr("transform", (d) => `translate(0,${yDom(d.value) + yDom.bandwidth() / 2})`);

    infoLayer
      .merge(groupsEnter)
      .transition()
      .duration(500)
      .attr("transform", (d) => `translate(0,${yDom(d.value) + yDom.bandwidth() / 2})`);

    let dia = yDom.bandwidth() - 1;
    if (dia > 18) dia = 18;

    let theme = this.theme;
    const texts = groupsEnter
      .append("text")
      .attr("class", "label")
      .attr("x", 15)
      .attr("text-anchor", "left")
      .on("mouseover", (d, i, n) => this.tip.show(d, i, data, n[i]))
      .on("mouseout", this.tip.hide)
      .style("fill", theme.palette.text.primary)
      .merge(infoLayer.select(".label"))
      .text((d) => {
        if (this.dnsMap && this.dnsMap[d.value]) {
          return this.dnsMap[d.value];
        } else return d.value;
      })
      .attr("font-size", "calc(.5vw + .5vh)")
      .call(this.wrap.bind(this), this.width - dia * 2 - this.cutOffset)
      .transition()
      .duration(500)
      .attr("dy", "0.35em");

    const plus = groupsEnter
      .append("circle")
      .attr("class", "plus")
      .style("fill", "#78d46e")
      .attr("fill-opacity", 0.8)
      .attr("stroke", "black")
      .style("cursor", "pointer")
      .on("click", this.handleSelectPlus.bind(this))
      .merge(infoLayer.select(".plus"))
      .transition()
      .duration(500)
      .attr("cx", this.width - dia / 2)
      .attr("r", dia / 2);

    const plusText = groupsEnter
      .append("text")
      .attr("class", "plusText")
      .text("+")
      .attr("text-anchor", "right")
      .style("fill", theme.palette.text.primary)
      .on("click", this.handleSelectPlus.bind(this))
      .attr("dy", "0.30em")
      .style("cursor", "pointer")
      .attr("dx", "0.25em")
      .merge(infoLayer.select(".plusText"))
      .transition()
      .duration(500)
      .attr("font-size", "calc(.5vw + .5vh)")
      .attr("x", this.width - dia - 1);

    const minus = groupsEnter
      .append("circle")
      .attr("class", "minus")
      .style("fill", "#bf5453")
      .attr("fill-opacity", 0.8)
      .attr("stroke", "black")
      .style("cursor", "pointer")
      .on("click", this.handleSelectMinus.bind(this))
      .merge(infoLayer.select(".minus"))
      .transition()
      .duration(500)
      .attr("cx", this.width - dia * 1.5 - 2)
      .attr("r", dia / 2);

    const minusText = groupsEnter
      .append("text")
      .attr("class", "minusText")
      .text("-")
      .attr("text-anchor", "right")
      .style("cursor", "pointer")
      .attr("dy", "0.30em")
      .attr("dx", "0.25em")
      .on("click", this.handleSelectMinus.bind(this))
      .style("fill", theme.palette.text.primary)
      .merge(infoLayer.select(".minusText"))
      .transition()
      .duration(500)
      .attr("font-size", "calc(.5vw + .5vh)")
      .attr("x", this.width - dia * 2);
  }

  updateSelection(filterState) {
    this.filterState = filterState;
    this.updateColors(filterState);
    // this.updateData(this.rawData, filterState);
  }

  updateTheme(theme) {
    this.theme = theme;
    this.colors = {
      in: this.theme.barColors.incoming,
      out: this.theme.barColors.outgoing,
      intern: this.theme.barColors.internal
    };
    this.colorsSelected = {
      in: this.theme.barColors.incomingSelected,
      out: this.theme.barColors.outgoingSelected,
      intern: this.theme.barColors.internalSelected
    };
    this.svg.selectAll("text").style("fill", this.theme.palette.text.primary);
    if (this.filterState) {
      this.updateColors(this.filterState);
    }
  }

  updateDimensions(node) {
    const { padding } = this.options;
    if (node != null) {
      this.width = node.clientWidth;
      this.height = node.clientHeight;
    }
    this.width = this.width - padding.left - padding.right;
    this.height = this.height - padding.bottom - padding.top;

    this.xScale.rangeRound([0, this.width]);
    this.yScale.rangeRound([this.height, 0]).padding(0);

    this.xAxisGroup.attr("transform", "translate(0," + this.height + ")");

    this.xAxisGroup.transition().duration(500).call(this.xAxis);

    this.yAxisGroup.transition().duration(500).call(this.yAxis);

    if (!this.noData) {
      this.svg
        .selectAll(".bar")
        .attr("height", this.yDom.bandwidth())
        .attr("y", (d) => {
          return this.yDom(d.data.value);
        })
        .attr("x", (d) => {
          return this.xDom(d[0]);
        })
        .attr("width", (d) => {
          return this.xDom(d[1]) - this.xDom(d[0]);
        });

      this.svg
        .selectAll(".infoLayer")
        .attr("transform", (d) => `translate(0,${this.yDom(d.value) + this.yDom.bandwidth() / 2})`);

      let dia = this.yDom.bandwidth() - 1;
      if (dia > 18) dia = 18;

      this.svg.selectAll(".label").attr("font-size", "calc(.5vw + .5vh)");
      this.svg
        .selectAll(".plus")
        .attr("cx", this.width - dia / 2)
        .attr("r", dia / 2);
      this.svg
        .selectAll(".plusText")
        .attr("font-size", dia)
        .attr("x", this.width - dia);
      this.svg
        .selectAll(".minus")
        .attr("cx", this.width - dia * 1.5 - 2)
        .attr("r", dia / 2);
      this.svg
        .selectAll(".minusText")
        .attr("font-size", dia)
        .attr("x", this.width - dia * 2);

      this.svg
        .selectAll(".label")
        .call(this.wrap.bind(this), this.width - dia * 2 - this.cutOffset);
    }
  }

  updateAxis(bool) {
    if (bool) {
      this.xAxis = d3.axisBottom(this.xScale).ticks(5).tickFormat(bytes2String);
    } else {
      this.xAxis = d3.axisBottom(this.xScale).ticks(5).tickFormat(d3.format(".2s"));
    }
  }

  updateColors(filterState) {
    this.svg.selectAll(".bar-" + "in").attr("fill", (d) => {
      if (this.dataType === "src") {
        if (this.mode === "ip") {
          if (filterState.includeFilter.srcIP.includes(d.data.value)) {
            return this.colorsSelected.in;
          } else {
            return this.colors.in;
          }
        }
        if (this.mode === "port") {
          if (filterState.includeFilter.srcPort.includes(d.data.value)) {
            return this.colorsSelected.in;
          } else {
            return this.colors.in;
          }
        }
      }
      if (this.dataType === "dest") {
        if (this.mode === "ip") {
          if (filterState.includeFilter.destIP.includes(d.data.value)) {
            return this.colorsSelected.in;
          } else {
            return this.colors.in;
          }
        }
        if (this.mode === "port") {
          if (filterState.includeFilter.destPort.includes(d.data.value)) {
            return this.colorsSelected.in;
          } else {
            return this.colors.in;
          }
        }
      }
    });
    this.svg.selectAll(".bar-" + "out").attr("fill", (d) => {
      if (this.dataType === "src") {
        if (this.mode === "ip") {
          if (filterState.includeFilter.srcIP.includes(d.data.value)) {
            return this.colorsSelected.out;
          } else {
            return this.colors.out;
          }
        }
        if (this.mode === "port") {
          if (filterState.includeFilter.srcPort.includes(d.data.value)) {
            return this.colorsSelected.out;
          } else {
            return this.colors.out;
          }
        }
      }
      if (this.dataType === "dest") {
        if (this.mode === "ip") {
          if (filterState.includeFilter.destIP.includes(d.data.value)) {
            return this.colorsSelected.out;
          } else {
            return this.colors.out;
          }
        }
        if (this.mode === "port") {
          if (filterState.includeFilter.destPort.includes(d.data.value)) {
            return this.colorsSelected.out;
          } else {
            return this.colors.out;
          }
        }
      }
    });
    this.svg.selectAll(".bar-" + "intern").attr("fill", (d) => {
      if (this.dataType === "src") {
        if (this.mode === "ip") {
          if (filterState.includeFilter.srcIP.includes(d.data.value)) {
            return this.colorsSelected.intern;
          } else {
            return this.colors.intern;
          }
        }
        if (this.mode === "port") {
          if (filterState.includeFilter.srcPort.includes(d.data.value)) {
            return this.colorsSelected.intern;
          } else {
            return this.colors.intern;
          }
        }
      }
      if (this.dataType === "dest") {
        if (this.mode === "ip") {
          if (filterState.includeFilter.destIP.includes(d.data.value)) {
            return this.colorsSelected.intern;
          } else {
            return this.colors.intern;
          }
        }
        if (this.mode === "port") {
          if (filterState.includeFilter.destPort.includes(d.data.value)) {
            return this.colorsSelected.intern;
          } else {
            return this.colors.intern;
          }
        }
      }
    });
  }

  updateDnsMap(dnsMap) {
    this.dnsMap = dnsMap;
    if (this.noData) return;

    let dia = this.yDom.bandwidth() - 1;
    if (dia > 18) dia = 18;

    this.svg
      .selectAll(".label")
      .each(function (d) {
        let node = d3.select(this);
        if (dnsMap[d.value]) node.text(dnsMap[d.value]);
        else node.text(d.value);
      })
      .call(this.wrap.bind(this), this.width - dia * 2 - this.cutOffset);
  }

  handleSelectPlusMinus(type, d) {
    let field;
    let act;
    let lst;
    let invLst;
    let filter =
      type === FilterType.include ? this.filterState.includeFilter : this.filterState.excludeFilter;
    let inverse =
      type === FilterType.exclude ? this.filterState.includeFilter : this.filterState.excludeFilter;
    if (this.mode === "ip") {
      if (this.dataType === "src") {
        field = FilterField.srcIP;
        lst = filter.srcIP;
        invLst = inverse.srcIP;
      }
      if (this.dataType === "dest") {
        field = FilterField.destIP;
        lst = filter.destIP;
        invLst = inverse.destIP;
      }
    } else {
      if (this.dataType === "src") {
        field = FilterField.srcPort;
        lst = filter.srcPort;
        invLst = inverse.srcPort;
      }
      if (this.dataType === "dest") {
        field = FilterField.destPort;
        lst = filter.destPort;
        invLst = inverse.destPort;
      }
    }
    if (lst.includes(d.value)) {
      act = FilterAction.remove;
    } else {
      act = FilterAction.add;
    }

    this.parentProps.updateFilter(type, act, field, d.value);

    let invType = type === FilterType.exclude ? FilterType.include : FilterType.exclude;
    if (invLst.includes(d.value)) {
      this.parentProps.updateFilter(invType, FilterAction.remove, field, d.value);
    }
  }

  handleSelectMinus(d) {
    this.handleSelectPlusMinus(FilterType.exclude, d);
  }

  handleSelectPlus(d) {
    this.handleSelectPlusMinus(FilterType.include, d);
  }

  formatData(data) {
    if (!data || data.length === 0) return [];

    // data.sort((a,b)=> ((b.in+b.out) - (a.in+a.out)));
    let formattedData;

    if ("ip" in data[0]) {
      this.mode = "ip";
      formattedData = data.map((x) => ({ value: x.ip, in: x.in, out: x.out, intern: x.intern }));
    }
    if ("port" in data[0]) {
      this.mode = "port";
      formattedData = data.map((x) => ({ value: x.port, in: x.in, out: x.out, intern: x.intern }));
    }
    if (formattedData.length > 25) formattedData.length = 25;

    return formattedData;
  }

  wrap(textNodes, width) {
    if (this.mode === "ip") {
      let dnsMap = this.dnsMap;
      textNodes.each(function (d) {
        let textNode = d3.select(this);
        let text = d.value;
        if (dnsMap && dnsMap[text]) text = dnsMap[text];

        let chars = text.split("");
        let char;
        let line = [];
        while ((char = chars.pop())) {
          line.unshift(char);
          textNode.text(line.join(""));
          if (textNode.node().getComputedTextLength() > width) {
            line.shift();
            line.shift();
            line.shift();
            textNode.text("..." + line.join(""));
            break;
          }
        }
      });
    }
  }

  render() {}
}
