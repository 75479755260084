import React, { Fragment, useEffect, useRef } from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import { ApplicationState } from "@store/index";
import { EventLogMessage, EventLogState } from "@store/eventLog/types";
import WarningIcon from "@material-ui/icons/Warning";
import { Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { returnType } from "@utils/TypeUtils";
import { Typography, Paper, Grow, Card, CardContent } from "@material-ui/core";

const variantIcon: any = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles = (theme: Theme) => ({
  success: {
    background: green[600]
  },
  error: {
    background: theme.palette.error.dark + "40"
  },
  info: {
    background: theme.palette.secondary.main
  },
  warning: {
    background: amber[700]
  }
});

//EventLog shows all the Events from the EventStore in Cards
function EventLogContentWrapper(props: WithStyles<typeof styles>) {
  const eventLog: EventLogState = useSelector((state: ApplicationState) => state.eventLog);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const { classes } = props;

  const scrollToBottom = () => {
    if (messagesEndRef && messagesEndRef.current)
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [eventLog.messages]);
  useEffect(scrollToBottom, [eventLog.openEventLog]);

  return (
    <Grow timeout={400} in={eventLog.openEventLog} mountOnEnter unmountOnExit>
      <Paper
        elevation={20}
        style={{
          position: "fixed",
          top: "50%",
          bottom: 41,
          left: "75%",
          width: "25%",
          display: "block",
          overflow: "auto",
          padding: 15
        }}
      >
        {eventLog.messages.map((message: EventLogMessage) => {
          return (
            <Card
              key={message.time}
              className={classes[message.variant]}
              elevation={20}
              variant="outlined"
              style={{ marginBottom: 10 }}
            >
              <CardContent>
                <Typography align="left" display="block" variant="body2" color="textSecondary">
                  {message.time}
                </Typography>
                <Typography align="left" display="block">
                  {message.message}
                </Typography>
              </CardContent>
            </Card>
          );
        })}
        <div ref={messagesEndRef} />
      </Paper>
    </Grow>
  );
}

export default withStyles(styles)(EventLogContentWrapper);
