interface Func<T> {
  (...args: any): T;
}

/**
 * Returns a function's return type
 */
export function returnType<T>(func: Func<T>): T {
  return (null as unknown) as T;
}
